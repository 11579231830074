// src/pages/stockout/index.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography, Grid, Stack } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Spinner, CustomizedSnackbars } from "../../components";
import { TableList } from "../../components";
import StockOutSearch from "./stockOutComponent/StockOutSearch";
import { useStockOutData } from "./hooks/useStockOutData";
import { tableHeadStockOut } from "../../utils/Constants";

function StockOut() {
  const navigate = useNavigate();
  const {
    stockOutList,
    isLoading,
    totalRecord,
    branchCodeSelected,
    setBranchCodeSelected,
    selectedDateFrom,
    setSelectedDateFrom,
    selectedDateTo,
    setSelectedDateTo,
    page,
    rowsPerPage,
    orderBy,
    order,
    setPage,
    setRowsPerPage,
    getStockOutList,
    handleRequestSort,
  } = useStockOutData();

  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");

  const handleAddStockOut = () => {
    navigate("/stockout/add");
  };

  const handleDetailStockOut = (item) => {
    navigate(`/stockout/detail/${item.id}`, { state: { stockOut: item } });
  };

  return (
    <>
      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />

      <Box
        sx={{
          width: "100%",
          backgroundColor: "white",
          borderRadius: 1,
          border: "1px solid",
          borderColor: "divider",
        }}
      >
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            alignItems: "center",
            p: 3,
            borderBottom: "1px solid",
            borderColor: "divider",
          }}
        >
          <Typography variant="h6" sx={{ mb: { xs: 2, sm: 0 } }}>
            Stock Out List
          </Typography>

          <Button
            variant="outlined"
            startIcon={<AddCircleIcon />}
            onClick={handleAddStockOut}
          >
            Add Stock Out
          </Button>
        </Box>

        {/* Search Section */}
        <StockOutSearch
          branchCodeSelected={branchCodeSelected}
          setBranchCodeSelected={setBranchCodeSelected}
          selectedDateFrom={selectedDateFrom}
          setSelectedDateFrom={setSelectedDateFrom}
          selectedDateTo={selectedDateTo}
          setSelectedDateTo={setSelectedDateTo}
          onSearch={() => {
            setPage(0);
            getStockOutList();
          }}
        />

        {/* Table List */}
        <TableList
          tableStructure="stockout-list"
          tableName={"Stock Out List"}
          orderBy={orderBy}
          order={order}
          isLoading={isLoading}
          isShowPagination={true}
          tableHeadList={tableHeadStockOut}
          data={stockOutList}
          rowsPerPage={rowsPerPage}
          page={page}
          totalRecord={totalRecord}
          handleChangePage={(event, newPage) => setPage(newPage)}
          handleChangeRowsPerPage={(event) => {
            setRowsPerPage(+event.target.value);
            setPage(0);
          }}
          onPreviewDetail={handleDetailStockOut}
          handleRequestSort={handleRequestSort}
        />
      </Box>
    </>
  );
}

export default StockOut;
