// src/pages/products/components/PricingForm.js
import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import { FormInput } from "../../../components";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { formatNumber } from "../utils/formatters";
import NumberFormat from "../../../helpers/functions/numberFormat";

const PricingForm = ({
  detailSellingPrice,
  isLoadingProducts,
  handleSellingPriceChange,
  handleGrabPriceChange,
  handleBottleRequirementChange,
  tableHeadSellingPriceDetail,
}) => {
  if (isLoadingProducts) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <TableContainer>
      <Table aria-label="pricing table">
        <TableHead>
          <TableRow>
            {tableHeadSellingPriceDetail.map((item) => (
              <TableCell
                key={item.name}
                sx={{
                  fontWeight: "bold",
                  backgroundColor: "#060270",
                  color: "white",
                  width: item.size,
                }}
              >
                {item.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {detailSellingPrice.length === 0 ? (
            <TableRow>
              <TableCell
                colSpan={tableHeadSellingPriceDetail.length}
                align="center"
                sx={{ py: 4 }}
              >
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <AddCircleIcon color="secondary" fontSize="large" />
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body1"
                      sx={{ color: "text.secondary" }}
                    >
                      No products found. Please select a product to view its
                      pricing details.
                    </Typography>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          ) : (
            detailSellingPrice.map((item, index) => {
              // Filter out bottle inventory items if needed
              if (item.secondaryInventoryId === "INVENTORY_BOTOL") {
                return null;
              }

              return (
                <TableRow key={item.productCode || index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <Typography variant="body1">{item.variantName}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">{item.productSku}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      {`Rp. ${NumberFormat(0, item.basePrice)}`}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <FormInput
                      size="small"
                      label="Selling Price"
                      name="sellingPrice"
                      type="text"
                      inputPrice={true}
                      value={formatNumber(item.sellingPrice)}
                      onChange={(event) =>
                        handleSellingPriceChange(event, index)
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">Rp.</InputAdornment>
                        ),
                      }}
                    />
                  </TableCell>
                  {/* <TableCell>
                    <FormInput
                      size="small"
                      label="Online Price"
                      name="grabPrice"
                      type="text"
                      inputPrice={true}
                      value={formatNumber(item.grabPrice)}
                      onChange={(event) => handleGrabPriceChange(event, index)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">Rp.</InputAdornment>
                        ),
                      }}
                    />
                  </TableCell> */}
                  <TableCell>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={item.isBottleMandatory}
                            onChange={() =>
                              handleBottleRequirementChange(index)
                            }
                            inputProps={{
                              "aria-label": "controlled",
                            }}
                          />
                        }
                        label="Required Bottle?"
                      />
                    </FormGroup>
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PricingForm;
