/**
 * Validasi form supplier
 * @param {Object} formData - Data form supplier
 * @returns {Object} Objek berisi pesan error jika validasi gagal
 */
export const validateSupplierForm = (formData) => {
  const {
    supplierName,
    supplierContacName,
    supplierPhone,
    supplierEmail,
    supplierAddress,
  } = formData;

  const errors = {};

  // Validasi nama supplier
  if (!supplierName || supplierName.trim() === "") {
    errors.supplierName = "Supplier Name is required";
  }

  // Validasi kontak supplier
  if (!supplierContacName || supplierContacName.trim() === "") {
    errors.supplierContacName = "Supplier Contact is required";
  }

  // Validasi nomor telepon supplier
  if (!supplierPhone || supplierPhone.trim() === "") {
    errors.supplierPhone = "Supplier Phone is required";
  } else if (!/^[0-9+\-\s()]*$/.test(supplierPhone)) {
    errors.supplierPhone = "Invalid phone number format";
  }

  // Validasi email supplier
  if (!supplierEmail || supplierEmail.trim() === "") {
    errors.supplierEmail = "Supplier Email is required";
  } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(supplierEmail)) {
    errors.supplierEmail = "Invalid email format";
  }

  // Validasi alamat supplier
  if (!supplierAddress || supplierAddress.trim() === "") {
    errors.supplierAddress = "Supplier Address is required";
  }

  return errors;
};

/**
 * Validasi sebelum menghapus supplier
 * @param {Object} supplier - Supplier yang akan dihapus
 * @returns {Object} Status valid dan pesan error jika ada
 */
export const validateSupplierDeletion = (supplier) => {
  // Contoh validasi: Cek apakah supplier masih memiliki order aktif
  if (supplier.activeOrders && supplier.activeOrders > 0) {
    return {
      valid: false,
      message: `This supplier has ${supplier.activeOrders} active orders and cannot be deleted.`,
    };
  }

  return { valid: true };
};

export default {
  validateSupplierForm,
  validateSupplierDeletion,
};
