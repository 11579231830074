import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Grid,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Breadcrumbs,
  Link,
  Card,
  CardContent,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Spinner } from "../../../../components";
import CustomizedSnackbars from "../../../../components/base/Snackbar";
import api from "../../../../helpers/api";
import moment from "moment";

// Helper function untuk format angka
const NumberFormat = (defaultValue, value) => {
  const parseValue = value ? value : defaultValue;
  return parseValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

function BalanceDetail() {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const transactionFromState = location.state?.transaction;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isExtraSmall = useMediaQuery("(max-width:480px)");

  const [isLoading, setIsLoading] = useState(true);
  const [isSpinner, setIsSpinner] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");
  const [detailTransaction, setDetailTransaction] = useState({});

  // Fetch detail data on component mount
  useEffect(() => {
    if (id) {
      onGetDetailTransaction(id);
    }
  }, [id]);

  // Fetch transaction detail data
  const onGetDetailTransaction = async (transactionId) => {
    setIsLoading(true);
    try {
      // Mendapatkan detail transaksi dari API
      const res = await api.getDetailClosing(transactionId);

      if (res.statusCode === 200) {
        console.log("Detail transaction data:", res.data);
        setDetailTransaction(res.data);
        setIsLoading(false);
      } else {
        onError(res.message);
      }
    } catch (error) {
      console.error("Error fetching detail:", error);
      onError(error.message || "Failed to fetch detail data");
    }
  };

  // Handle errors
  const onError = async (message) => {
    setIsLoading(false);
    setIsOpen(true);
    setAlertType("error");
    setMessage(message);
  };

  // Navigate back to the balance list
  const handleBack = () => {
    navigate("/balance");
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "70vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Spinner isShowSpinner={isSpinner} />
      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />

      <Paper
        sx={{
          p: { xs: 2, sm: 3 },
          mb: 3,
          borderRadius: 1,
          boxShadow: 1,
          overflow: "hidden", // Prevent content overflow
          width: "100%",
        }}
      >
        {/* Header */}
        <Box
          sx={{
            mb: 3,
            display: "flex",
            flexDirection: isExtraSmall ? "column" : "row",
            justifyContent: "space-between",
            alignItems: isExtraSmall ? "flex-start" : "center",
            gap: isExtraSmall ? 2 : 0,
          }}
        >
          <Box sx={{ width: isExtraSmall ? "100%" : "auto" }}>
            <Breadcrumbs
              separator="›"
              aria-label="breadcrumb"
              sx={{
                fontSize: { xs: "0.75rem", sm: "0.875rem" },
              }}
            >
              <Link
                color="inherit"
                onClick={() => navigate("/balance")}
                sx={{ cursor: "pointer", textDecoration: "none" }}
              >
                Balance
              </Link>
              <Typography color="text.primary" sx={{ fontSize: "inherit" }}>
                Detail Balance
              </Typography>
            </Breadcrumbs>

            <Typography
              variant={isMobile ? "subtitle1" : "h6"}
              sx={{ mt: 1 }}
              noWrap={!isExtraSmall}
            >
              Balance Detail #{id}
            </Typography>
          </Box>
        </Box>

        {/* Detail Content */}
        {detailTransaction ? (
          <>
            {/* Balance Information */}
            <Card sx={{ mb: 4, width: "100%" }}>
              <CardContent sx={{ p: { xs: 2, sm: 3 } }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography
                      variant={isMobile ? "subtitle1" : "h6"}
                      gutterBottom
                    >
                      Balance Information
                    </Typography>

                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="body2" color="text.secondary">
                          Transaction ID
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          variant="body2"
                          fontWeight="medium"
                          sx={{
                            wordBreak: "break-word",
                            overflowWrap: "break-word",
                          }}
                        >
                          {detailTransaction?.clossingId || id}
                        </Typography>
                      </Grid>

                      <Grid item xs={6} sm={4}>
                        <Typography variant="body2" color="text.secondary">
                          Date
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography variant="body2" fontWeight="medium">
                          {detailTransaction?.transactionDate
                            ? moment(detailTransaction.transactionDate).format(
                                "DD MMM YYYY, HH:mm"
                              )
                            : "N/A"}
                        </Typography>
                      </Grid>

                      <Grid item xs={6} sm={4}>
                        <Typography variant="body2" color="text.secondary">
                          Grand Total
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          variant="body2"
                          fontWeight="medium"
                          color="primary.main"
                        >
                          Rp.{" "}
                          {NumberFormat(
                            0,
                            detailTransaction?.tottalExpanseSelling || 0
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography
                      variant={isMobile ? "subtitle1" : "h6"}
                      gutterBottom
                    >
                      Outlet Information
                    </Typography>

                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={4}>
                        <Typography variant="body2" color="text.secondary">
                          Outlet Name
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography
                          variant="body2"
                          fontWeight="medium"
                          sx={{
                            wordBreak: "break-word",
                            overflowWrap: "break-word",
                          }}
                        >
                          {detailTransaction?.branchName || "N/A"}
                        </Typography>
                      </Grid>

                      <Grid item xs={6} sm={4}>
                        <Typography variant="body2" color="text.secondary">
                          Cash Transaction
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography variant="body2" fontWeight="medium">
                          Rp. {NumberFormat(0, detailTransaction?.cash || 0)}
                        </Typography>
                      </Grid>

                      <Grid item xs={6} sm={4}>
                        <Typography variant="body2" color="text.secondary">
                          Non Cash Transaction
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={8}>
                        <Typography variant="body2" fontWeight="medium">
                          Rp. {NumberFormat(0, detailTransaction?.edc || 0)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            {/* Transaction Details */}
            <Typography variant={isMobile ? "subtitle1" : "h6"} sx={{ mb: 2 }}>
              Transaction Details
            </Typography>

            <Card sx={{ mb: 4, width: "100%" }}>
              <CardContent sx={{ p: { xs: 1, sm: 2, md: 3 } }}>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={3}>
                    <Typography variant="body2" color="text.secondary">
                      Cash Transaction
                    </Typography>
                    <Typography
                      variant={isMobile ? "subtitle1" : "h6"}
                      color="primary"
                      sx={{
                        wordBreak: "break-word",
                        fontSize: { xs: "0.95rem", sm: "inherit" },
                      }}
                    >
                      Rp. {NumberFormat(0, detailTransaction?.cash || 0)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Typography variant="body2" color="text.secondary">
                      Non Cash Transaction
                    </Typography>
                    <Typography
                      variant={isMobile ? "subtitle1" : "h6"}
                      color="primary"
                      sx={{
                        wordBreak: "break-word",
                        fontSize: { xs: "0.95rem", sm: "inherit" },
                      }}
                    >
                      Rp. {NumberFormat(0, detailTransaction?.edc || 0)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Typography variant="body2" color="text.secondary">
                      Grabfood
                    </Typography>
                    <Typography
                      variant={isMobile ? "subtitle1" : "h6"}
                      color="primary"
                      sx={{
                        wordBreak: "break-word",
                        fontSize: { xs: "0.95rem", sm: "inherit" },
                      }}
                    >
                      Rp.{" "}
                      {NumberFormat(
                        0,
                        detailTransaction?.grabPaymentTottal || 0
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Typography variant="body2" color="text.secondary">
                      Expenses
                    </Typography>
                    <Typography
                      variant={isMobile ? "subtitle1" : "h6"}
                      color="primary"
                      sx={{
                        wordBreak: "break-word",
                        fontSize: { xs: "0.95rem", sm: "inherit" },
                      }}
                    >
                      Rp.{" "}
                      {NumberFormat(0, detailTransaction?.exspanseTottal || 0)}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            {/* Product List Table */}
            {detailTransaction?.data && detailTransaction.data.length > 0 && (
              <>
                <Typography
                  variant={isMobile ? "subtitle1" : "h6"}
                  sx={{ mb: 2 }}
                >
                  Product List
                </Typography>

                <TableContainer sx={{ mb: 3 }}>
                  <Table
                    aria-label="balance detail product table"
                    size={isMobile ? "small" : "medium"}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">No</TableCell>
                        <TableCell>Product Code</TableCell>
                        <TableCell>Product Variant</TableCell>
                        <TableCell align="center">Quantity</TableCell>
                        <TableCell align="right">Price</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {detailTransaction.data.map((itemData, index) => (
                        <TableRow key={itemData.id || index}>
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell>{itemData.productCode || "N/A"}</TableCell>
                          <TableCell>{itemData.variantName || "N/A"}</TableCell>
                          <TableCell align="center">
                            {`${NumberFormat(0, itemData.quantity || 0)} pcs`}
                          </TableCell>
                          <TableCell align="right">
                            {`Rp. ${NumberFormat(0, itemData.totalTrx || 0)}`}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                {/* Total Section */}
                <Box
                  sx={{
                    mt: 3,
                    mb: 3,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Card
                    sx={{
                      width: { xs: "100%", sm: "auto" },
                      minWidth: { sm: 300, md: 350 },
                    }}
                  >
                    <CardContent>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <Typography variant="subtitle2" fontWeight="bold">
                            Total Items:
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: "right" }}>
                          <Typography variant="subtitle2" fontWeight="bold">
                            {NumberFormat(
                              0,
                              detailTransaction.data.length || 0
                            )}{" "}
                            Items
                          </Typography>
                        </Grid>

                        <Grid item xs={6}>
                          <Typography variant="subtitle2" fontWeight="bold">
                            Total Quantity:
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: "right" }}>
                          <Typography variant="subtitle2" fontWeight="bold">
                            {NumberFormat(
                              0,
                              detailTransaction.data.reduce(
                                (total, item) => total + (item.quantity || 0),
                                0
                              )
                            )}{" "}
                            pcs
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Divider sx={{ my: 1 }} />
                        </Grid>

                        <Grid item xs={6}>
                          <Typography variant="subtitle2" fontWeight="bold">
                            Grand Total:
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: "right" }}>
                          <Typography variant="subtitle2" fontWeight="bold">
                            Rp.{" "}
                            {NumberFormat(
                              0,
                              detailTransaction.data.reduce(
                                (total, item) => total + (item.totalTrx || 0),
                                0
                              )
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Box>
              </>
            )}

            {/* Action Buttons */}
            <Box sx={{ mt: 4 }}>
              <Button
                variant="outlined"
                startIcon={<ArrowBackIcon />}
                onClick={handleBack}
                size={isMobile ? "small" : "medium"}
              >
                Back to Balance
              </Button>
            </Box>
          </>
        ) : (
          <Typography variant="body1" color="text.secondary">
            Balance details not available
          </Typography>
        )}
      </Paper>
    </>
  );
}

export default BalanceDetail;
