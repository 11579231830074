/**
 * Validasi form branch/outlet
 * @param {Object} formData - Data form branch
 * @returns {Object} Objek berisi pesan error jika validasi gagal
 */
export const validateBranchForm = (formData) => {
  const { branchName, picName, status, branchPhone, address } = formData;

  const errors = {};

  // Validasi nama branch/outlet
  if (!branchName || branchName.trim() === "") {
    errors.branchName = "Branch Name is required";
  }

  // Validasi nama PIC
  if (!picName || picName.trim() === "") {
    errors.picName = "PIC is required";
  }

  // Validasi status
  if (!status || Object.keys(status).length === 0) {
    errors.status = "Status is required";
  }

  // Validasi nomor telepon
  if (!branchPhone || branchPhone.trim() === "") {
    errors.branchPhone = "Phone is required";
  } else if (!/^[0-9+\-\s()]*$/.test(branchPhone)) {
    errors.branchPhone = "Invalid phone number format";
  }

  // Validasi alamat
  if (!address || address.trim() === "") {
    errors.address = "Address is required";
  }

  return errors;
};

export default {
  validateBranchForm,
};
