import { useState, useEffect } from "react";
import api from "../../../helpers/api";

/**
 * Hook untuk mengelola data supplier dan pencarian
 * @param {string} initialSearchTerm - Kata kunci pencarian awal
 * @returns {Object} Data supplier dan metode terkait
 */
export const useSuppliers = (initialSearchTerm = "") => {
  const [supplierList, setSupplierList] = useState([]);
  const [supplierSelected, setSupplierSelected] = useState(null);
  const [searchSupplierName, setSearchSupplierName] =
    useState(initialSearchTerm);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  /**
   * Mengambil data supplier berdasarkan kata kunci pencarian
   */
  useEffect(() => {
    const fetchSuppliers = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const res = await api.getSupplier(searchSupplierName);

        if (res.statusCode === 200) {
          // Add "All Suppliers" option for filter
          if (initialSearchTerm === "") {
            const allSupplier = {
              id: "",
              supplierName: "All Suppliers",
              supplierCode: "",
              address: "",
              phone: "",
              statusCode: "",
              statusName: "",
              createdDate: "",
              updatedDate: "",
            };
            const updatedSupplierList = [...res.data.data];
            setSupplierList(updatedSupplierList);
          } else {
            setSupplierList(res.data.data);
          }
        } else {
          setSupplierList([]);
          setError(res.message);
        }
      } catch (error) {
        setSupplierList([]);
        setError(error.message || "Failed to fetch suppliers");
      } finally {
        setIsLoading(false);
      }
    };

    fetchSuppliers();
  }, [searchSupplierName, initialSearchTerm]);

  /**
   * Memilih supplier
   * @param {Object} supplier - Data supplier yang dipilih
   */
  const selectSupplier = (supplier) => {
    setSupplierSelected(supplier);
  };

  /**
   * Reset pilihan supplier
   */
  const resetSupplierSelection = () => {
    setSupplierSelected(null);
    setSearchSupplierName("");
  };

  return {
    supplierList,
    supplierSelected,
    searchSupplierName,
    isLoading,
    error,

    // Setters
    setSupplierSelected,
    setSearchSupplierName,

    // Methods
    selectSupplier,
    resetSupplierSelection,
  };
};

export default useSuppliers;
