import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { TableList, Spinner, FormInput, PopupDelete } from "../../components";
import CustomizedSnackbars from "../../components/base/Snackbar";
import { tableHeadCategory } from "../../utils/Constants";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Stack } from "@mui/material";

// Import API directly
import api from "../../helpers/api";

// Import custom hook
import { useCategoryData } from "./hooks/useCategoryData";

function Categories() {
  const navigate = useNavigate();

  // Use custom hook for category data management
  const {
    isLoading,
    isSpinner,
    setIsSpinner,
    categoryList,
    searchCategoryName,
    setSearchCategoryName,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    totalRecord,
    orderBy,
    order,
    handleRequestSort,
    setIsReload,
  } = useCategoryData();

  // Local state
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [categorySelected, setCategorySelected] = useState({});

  // Handler untuk navigasi ke halaman tambah kategori
  const handleAddCategory = () => {
    navigate("/categories/add");
  };

  // Handler untuk navigasi ke halaman edit kategori
  const handleEditCategory = (category) => {
    navigate(`/categories/edit/${category.id}`, { state: { category } });
  };

  // Handler untuk konfirmasi hapus kategori
  const handleDeleteCategory = (category) => {
    setCategorySelected(category);
    setIsDeleteOpen(true);
  };

  // Handler untuk hapus kategori
  const handleDeleteConfirm = async () => {
    setIsSpinner(true);
    try {
      // Use direct API call instead of using the hook function
      const response = await api.deleteCategory(categorySelected);

      if (response && response.statusCode === 200) {
        showSuccess("Category deleted successfully");
        // Reload the data
        setIsReload(true);
      } else {
        showError(response?.message || "Failed to delete category");
      }
    } catch (error) {
      showError(error?.message || "An error occurred");
    } finally {
      setIsSpinner(false);
      setIsDeleteOpen(false);
    }
  };

  // Utility functions
  const showSuccess = (msg) => {
    setIsOpen(true);
    setAlertType("success");
    setMessage(msg);
  };

  const showError = (msg) => {
    setIsOpen(true);
    setAlertType("error");
    setMessage(msg);
  };

  return (
    <>
      <Spinner isShowSpinner={isSpinner} />
      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />

      <Box
        sx={{
          width: "100%",
          backgroundColor: "white",
          borderRadius: 1,
          border: "1px solid",
          borderColor: "divider",
        }}
      >
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            alignItems: "center",
            p: 3,
            borderBottom: "1px solid",
            borderColor: "divider",
          }}
        >
          <Typography variant="h6" sx={{ mb: { xs: 2, sm: 0 } }}>
            Category List
          </Typography>

          <Button
            variant="outlined"
            startIcon={<AddCircleIcon />}
            onClick={handleAddCategory}
          >
            Add New Category
          </Button>
        </Box>

        {/* Search Section */}
        <Box sx={{ p: 3, borderBottom: "1px solid", borderColor: "divider" }}>
          <Grid
            container
            spacing={2}
            sx={{
              alignItems: "center",
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{
                px: 3,
                mb: 1,
                alignItems: "center",
              }}
            >
              <Grid item xs={12} sm={3}>
                <Stack spacing={1}>
                  <Typography variant="caption" sx={{ fontWeight: 600 }}>
                    CATEGORY NAME
                  </Typography>
                  <FormInput
                    size="small"
                    label="Search Category Name"
                    name="searchCategoryName"
                    value={searchCategoryName}
                    onChange={(event) =>
                      setSearchCategoryName(event.target.value)
                    }
                  />
                </Stack>
              </Grid>

              <Grid
                item
                xs={12}
                sm={3}
                sx={{
                  display: "flex",
                  alignItems: "end",
                  height: "100%",
                }}
              >
                <Button
                  variant="contained"
                  startIcon={<SearchIcon />}
                  onClick={() => {
                    setPage(0);
                    setIsReload(true);
                  }}
                  sx={{
                    width: "150px",
                    mt: { xs: 1, sm: 1, md: 3, lg: 3 },
                  }}
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        {/* Table List */}
        <TableList
          tableStructure="category-list"
          tableName={"Category List"}
          orderBy={orderBy}
          order={order}
          isLoading={isLoading}
          isShowPagination={true}
          tableHeadList={tableHeadCategory}
          data={categoryList}
          rowsPerPage={rowsPerPage}
          page={page}
          totalRecord={totalRecord}
          handleChangePage={(event, newPage) => setPage(newPage)}
          handleChangeRowsPerPage={(event) => {
            setRowsPerPage(+event.target.value);
            setPage(0);
          }}
          onEditData={handleEditCategory}
          handleRequestSort={handleRequestSort}
          onDeleteData={handleDeleteCategory}
        />
      </Box>

      <PopupDelete
        itemName={categorySelected.categoryName}
        visible={isDeleteOpen}
        onClose={() => setIsDeleteOpen(false)}
        onCancel={() => setIsDeleteOpen(false)}
        onDelete={handleDeleteConfirm}
      />
    </>
  );
}

export default Categories;
