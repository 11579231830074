// src/pages/purchases/components/ProductTable.js
import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid,
  IconButton,
  Tooltip,
  Box,
  InputAdornment,
} from "@mui/material";
import { BsFillTrash3Fill } from "react-icons/bs";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { FormInput } from "../../../components";
import ProductSelector from "./ProductSelector";
import { tableHeadPO } from "../../../utils/Constants";
import { formatNumber } from "../utils/formatters";
import NumberFormat from "../../../helpers/functions/numberFormat";

const ProductTable = ({
  productOrders,
  setProductOrders,
  onRemoveProduct,
  branchCode = "",
  errors = {},
}) => {
  const [isRemoveProduct, setIsRemoveProduct] = useState(false);
  const [productNameSelected, setProductNameSelected] = useState([]);
  const [variantNameSelected, setVariantNameSelected] = useState([]);
  const [resetForm, setResetForm] = useState(false);

  // Reset handler for remove animation
  useEffect(() => {
    if (isRemoveProduct) {
      const timer = setTimeout(() => {
        setIsRemoveProduct(false);
      }, 300);

      return () => clearTimeout(timer);
    }
  }, [isRemoveProduct]);

  // Reset form effect
  useEffect(() => {
    if (resetForm) {
      setResetForm(false);
    }
  }, [resetForm]);

  // Handle product selection
  const handleProductSelected = (index, product) => {
    const newProducts = [...productOrders];

    if (product) {
      newProducts[index].note = "";
      newProducts[index].brandId = product.brandId || 0;
      newProducts[index].imageUrl = product.imageUrl || "";
      newProducts[index].brandName = product.brandName || "";
      newProducts[index].productId = product.id || "";
      newProducts[index].categoryId = product.categoryId || 0;
      newProducts[index].productName = product.productName || "";
      newProducts[index].categoryName = product.categoryName || "";
      newProducts[index].updatedQuantity = product.updatedQuantity || 0;
      newProducts[index].masterProductCode = product.masterProductCode || "";
    } else {
      // Reset product fields
      newProducts[index].note = "";
      newProducts[index].brandId = 0;
      newProducts[index].imageUrl = "";
      newProducts[index].brandName = "";
      newProducts[index].productId = "";
      newProducts[index].categoryId = 0;
      newProducts[index].productName = "";
      newProducts[index].categoryName = "";
      newProducts[index].updatedQuantity = 0;
      newProducts[index].masterProductCode = "";
    }

    // Always reset variant fields when product changes
    newProducts[index].variantName = "";
    newProducts[index].productSku = "";
    newProducts[index].productCode = "";
    newProducts[index].netPrice = 0;
    newProducts[index].quantity = 0;
    newProducts[index].trxPrice = 0;
    newProducts[index].defaultTrxPrice = "";

    setProductOrders(newProducts);

    // Update UI state
    setProductNameSelected((prev) => {
      const updated = [...prev];
      updated[index] = {
        ...updated[index],
        productItemSelected: product || null,
      };
      return updated;
    });

    setVariantNameSelected((prev) => {
      const updated = [...prev];
      updated[index] = {
        ...updated[index],
        variantSelected: null,
      };
      return updated;
    });
  };

  // Handle variant selection
  const handleVariantSelected = (index, variant) => {
    // Check for duplicate variants
    const duplicateProduct = productOrders.filter(
      (item) => item.productCode === variant?.productCode
    );

    if (duplicateProduct.length === 0 || !variant) {
      const newProducts = [...productOrders];

      if (variant) {
        newProducts[index].variantName = variant.variantName || "";
        newProducts[index].productSku = variant.productSku || "";
        newProducts[index].productCode = variant.productCode || "";
        newProducts[index].defaultTrxPrice = variant.defaultTrxPrice || 0;
      } else {
        // Reset variant fields
        newProducts[index].variantName = "";
        newProducts[index].productSku = "";
        newProducts[index].productCode = "";
        newProducts[index].defaultTrxPrice = "";
      }

      // Reset calculation fields
      newProducts[index].quantity = 0;
      newProducts[index].trxPrice = 0;
      newProducts[index].netPrice = 0;

      setProductOrders(newProducts);

      // Update UI state
      setVariantNameSelected((prev) => {
        const updated = [...prev];
        updated[index] = {
          ...updated[index],
          variantSelected: variant || null,
        };
        return updated;
      });
    } else {
      // Handle duplicate variant
      alert("This product variant is already added to the list.");
      setResetForm(true);
    }
  };

  // Handle quantity change and calculate total
  const handleQuantityChange = (event, index) => {
    const { name, value } = event.target;
    const newProducts = [...productOrders];
    newProducts[index][name] = value;
    newProducts[index].updatedQuantity = value;

    // Calculate total price
    calculateTotalPrice(index);

    setProductOrders(newProducts);
  };

  // Handle price change
  const handlePriceChange = (event, index) => {
    const formattedValue = event.target.value.replace(/\D/g, "");

    const newProducts = [...productOrders];
    newProducts[index].trxPrice = formattedValue
      ? parseInt(formattedValue, 10)
      : 0;

    // Calculate total price
    calculateTotalPrice(index);

    setProductOrders(newProducts);
  };

  // Handle total change
  const handleTotalChange = (event, index) => {
    const formattedValue = event.target.value.replace(/\D/g, "");

    const newProducts = [...productOrders];
    newProducts[index].netPrice = formattedValue
      ? parseInt(formattedValue, 10)
      : 0;

    // Calculate price based on total and quantity
    calculatePriceItem(index);

    setProductOrders(newProducts);
  };

  // Calculate total price (quantity * price)
  const calculateTotalPrice = (index) => {
    const newProducts = [...productOrders];

    if (newProducts[index].quantity && newProducts[index].trxPrice) {
      newProducts[index].netPrice =
        newProducts[index].quantity * newProducts[index].trxPrice;
    } else {
      newProducts[index].netPrice = 0;
    }

    setProductOrders(newProducts);
  };

  // Calculate price (total / quantity)
  const calculatePriceItem = (index) => {
    const newProducts = [...productOrders];

    if (
      newProducts[index].netPrice &&
      newProducts[index].quantity &&
      newProducts[index].quantity > 0
    ) {
      newProducts[index].trxPrice = Math.round(
        newProducts[index].netPrice / newProducts[index].quantity
      );
    } else {
      newProducts[index].trxPrice = 0;
    }

    setProductOrders(newProducts);
  };

  // Handle product removal
  const handleRemoveProduct = (index) => {
    setIsRemoveProduct(true);
    onRemoveProduct(index);
  };

  return (
    <TableContainer>
      <Table stickyHeader aria-label="purchase order products table">
        <TableHead>
          <TableRow>
            {tableHeadPO.map((item) => (
              <TableCell
                key={item.name}
                sx={{
                  fontWeight: "bold",
                  backgroundColor: "#060270",
                  color: "white",
                  width: item.size,
                }}
              >
                {item.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {!isRemoveProduct && (
          <TableBody>
            {productOrders.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={tableHeadPO.length}
                  align="center"
                  sx={{ py: 4 }}
                >
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item>
                      <AddCircleIcon color="secondary" fontSize="large" />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{ color: "text.secondary" }}
                      >
                        No products found. Click "Add Product" to add items to
                        the purchase order.
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ) : (
              productOrders.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell colSpan={2}>
                    <ProductSelector
                      index={index}
                      productOrder={item}
                      branchCode={branchCode}
                      productNameSelected={productNameSelected[index]}
                      variantNameSelected={variantNameSelected[index]}
                      onProductSelected={handleProductSelected}
                      onVariantSelected={handleVariantSelected}
                    />
                  </TableCell>
                  <TableCell>
                    <FormInput
                      disabled={!item.productCode}
                      size="small"
                      label="Quantity"
                      name="quantity"
                      type="number"
                      error={!!errors.quantity}
                      helperText={errors.quantity}
                      value={item.quantity}
                      onChange={(event) => handleQuantityChange(event, index)}
                    />
                  </TableCell>
                  <TableCell>
                    <FormInput
                      disabled={!item.productCode}
                      size="small"
                      label="Price"
                      name="trxPrice"
                      type="text"
                      error={!!errors.trxPrice}
                      helperText={errors.trxPrice}
                      value={formatNumber(item.trxPrice)}
                      onChange={(event) => handlePriceChange(event, index)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">Rp.</InputAdornment>
                        ),
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <FormInput
                      disabled={!item.productCode}
                      size="small"
                      label="Total"
                      name="netPrice"
                      type="text"
                      inputPrice={true}
                      error={!!errors.netPrice}
                      helperText={errors.netPrice}
                      value={formatNumber(item.netPrice)}
                      onChange={(event) => handleTotalChange(event, index)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">Rp.</InputAdornment>
                        ),
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="Delete Product">
                      <IconButton
                        onClick={() => handleRemoveProduct(index)}
                        size="medium"
                        sx={{
                          width: 30,
                          height: 30,
                          borderRadius: 1,
                          backgroundColor: "#f4e6fb",
                          color: "#FE3133",
                        }}
                      >
                        <BsFillTrash3Fill />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default ProductTable;
