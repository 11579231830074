import { useState } from "react";

const useUserFormValidation = () => {
  const [errors, setErrors] = useState({});

  const validateForm = ({
    branchNameSelected,
    employeeName,
    emailInput,
    phoneInput,
  }) => {
    const newErrors = {};

    if (
      !branchNameSelected ||
      branchNameSelected === null ||
      (Array.isArray(branchNameSelected) && branchNameSelected.length === 0) ||
      (typeof branchNameSelected === "object" &&
        Object.keys(branchNameSelected).length === 0)
    ) {
      newErrors.branchNameSelected = "Branch / Outlet is required to choose";
    }

    if (!employeeName || employeeName.trim() === "") {
      newErrors.employeeName = "Employee Name is required";
    }

    if (!emailInput || emailInput.trim() === "") {
      newErrors.emailInput = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(emailInput)) {
      newErrors.emailInput = "Email is invalid";
    }

    if (!phoneInput || phoneInput.trim() === "") {
      newErrors.phoneInput = "Phone is required";
    } else if (!/^\d+$/.test(phoneInput)) {
      newErrors.phoneInput = "Phone must contain only digits";
    }

    setErrors(newErrors);
    return { isValid: Object.keys(newErrors).length === 0, errors: newErrors };
  };

  const validateAuthForm = ({ usernameAuth, passwordInput, emailAuth }) => {
    const newErrors = {};

    if (!usernameAuth || usernameAuth.trim() === "") {
      newErrors.usernameAuth = "Username is required";
    }

    if (!passwordInput || passwordInput.trim() === "") {
      newErrors.passwordInput = "Password is required";
    } else if (passwordInput.length < 6) {
      newErrors.passwordInput = "Password must be at least 6 characters";
    }

    if (
      emailAuth &&
      emailAuth.trim() !== "" &&
      !/\S+@\S+\.\S+/.test(emailAuth)
    ) {
      newErrors.emailAuth = "Email is invalid";
    }

    setErrors(newErrors);
    return { isValid: Object.keys(newErrors).length === 0, errors: newErrors };
  };

  const validatePasswordForm = ({
    oldPasswordInput,
    passwordInput,
    confirmPasswordInput,
  }) => {
    const newErrors = {};

    if (!oldPasswordInput || oldPasswordInput.trim() === "") {
      newErrors.oldPasswordInput = "Current Password is required";
    }

    if (!passwordInput || passwordInput.trim() === "") {
      newErrors.passwordInput = "New Password is required";
    } else if (passwordInput.length < 6) {
      newErrors.passwordInput = "New Password must be at least 6 characters";
    }

    if (oldPasswordInput === passwordInput && oldPasswordInput !== "") {
      newErrors.passwordInput =
        "New Password must be different from Current Password";
    }

    // If confirmPasswordInput is provided, validate it matches passwordInput
    if (confirmPasswordInput !== undefined) {
      if (!confirmPasswordInput || confirmPasswordInput.trim() === "") {
        newErrors.confirmPasswordInput = "Confirm Password is required";
      } else if (confirmPasswordInput !== passwordInput) {
        newErrors.confirmPasswordInput = "Passwords do not match";
      }
    }

    setErrors(newErrors);
    return { isValid: Object.keys(newErrors).length === 0, errors: newErrors };
  };

  // Clear a specific error
  const clearError = (fieldName) => {
    if (errors[fieldName]) {
      const newErrors = { ...errors };
      delete newErrors[fieldName];
      setErrors(newErrors);
    }
  };

  // Clear all errors
  const clearAllErrors = () => {
    setErrors({});
  };

  return {
    errors,
    setErrors,
    validateForm,
    validateAuthForm,
    validatePasswordForm,
    clearError,
    clearAllErrors,
  };
};

export default useUserFormValidation;
