import React from "react";
import Chart from "react-apexcharts";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Box, Container, Paper, Stack } from "@mui/material";

const data = [
  {
    name: "Jan",
    Expenses: 4000,
    Income: 2000,
  },
  {
    name: "Feb",
    Expenses: 4000,
    Income: 2000,
  },
  {
    name: "Mar",
    Expenses: 4000,
    Income: 2000,
  },
  {
    name: "Jun",
    Expenses: 4000,
    Income: 2000,
  },
  {
    name: "Jul",
    Expenses: 4000,
    Income: 2000,
  },
  {
    name: "Aug",
    Expenses: 4000,
    Income: 2000,
  },
  {
    name: "Sep",
    Expenses: 4000,
    Income: 2000,
  },
  {
    name: "Oct",
    Expenses: 4000,
    Income: 2000,
  },
  {
    name: "Nov",
    Expenses: 4000,
    Income: 2000,
  },
  {
    name: "Dec",
    Expenses: 4000,
    Income: 2000,
  },
];

function TransactionChart() {
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "white",
        borderRadius: 1,
        border: "1px solid",
        borderColor: "divider",
      }}
    >
      {/* Header */}
      <Box
        sx={{
          p: 3,
          borderBottom: "1px solid",
          borderColor: "divider",
        }}
      >
        <Typography variant="h6">Transaction</Typography>
      </Box>

      {/* Chart */}
      <Box sx={{ p: 2, height: 400 }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 20,
            }}
          >
            <CartesianGrid strokeDasharray="3 3 0 0" vertical={false} />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="Income" fill="#0ea5e9" />
            <Bar dataKey="Expenses" fill="#ea580c" />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
}

export default TransactionChart;
