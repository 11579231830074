// src/pages/products/hooks/useProductData.js
import { useState, useEffect, useCallback } from "react";
import api from "../../../helpers/api";

export const useProductData = (
  initialSearch = "",
  initialPage = 0,
  initialRowsPerPage = 10
) => {
  const [productList, setProductList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isReload, setIsReload] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);
  const [searchProductName, setSearchProductName] = useState(initialSearch);
  const [page, setPage] = useState(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("desc");
  const [error, setError] = useState(null);

  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteError, setDeleteError] = useState(null);

  const getProducts = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    const currentPage = page + 1; // API menggunakan 1-based indexing

    try {
      const res = await api.getProduct(
        searchProductName,
        "",
        rowsPerPage,
        currentPage
      );

      if (res.statusCode === 200) {
        setTotalRecord(res.data.totalPage * res.data.limit);
        setProductList(res.data.data);
      } else {
        setProductList([]);
        setError(res.message);
      }
    } catch (err) {
      setProductList([]);
      setError(err.message || "Failed to fetch products");
    } finally {
      setIsLoading(false);
      setIsReload(false);
    }
  }, [searchProductName, page, rowsPerPage]);

  const deleteProduct = async (product) => {
    setIsDeleting(true);
    setDeleteSuccess(false);
    setDeleteError(null);

    try {
      const response = await api.deleteMasterProduct(product);

      if (response && response.statusCode === 200) {
        setDeleteSuccess(true);

        // Refresh data setelah penghapusan berhasil
        setIsReload(true);

        return {
          success: true,
          message: "Product deleted successfully",
        };
      } else {
        const errorMsg = response?.message || "Failed to delete product";
        setDeleteError(errorMsg);

        return {
          success: false,
          message: errorMsg,
        };
      }
    } catch (error) {
      const errorMsg =
        error?.message || "An error occurred while deleting product";
      setDeleteError(errorMsg);

      return {
        success: false,
        message: errorMsg,
      };
    } finally {
      setIsDeleting(false);
    }
  };

  // Load products when page or rows per page changes
  useEffect(() => {
    getProducts();
  }, [getProducts, page, rowsPerPage]);

  // Reload products when isReload is set to true
  useEffect(() => {
    if (isReload) {
      getProducts();
    }
  }, [isReload, getProducts]);

  // Handle sorting
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";

    // Update state
    setOrder(newOrder);
    setOrderBy(property);

    // Sort the productList
    const sortedData = [...productList].sort((a, b) => {
      const valueA = a[property] || "";
      const valueB = b[property] || "";

      // Handle different data types
      if (typeof valueA === "string") {
        return newOrder === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }

      return newOrder === "asc" ? valueA - valueB : valueB - valueA;
    });

    setProductList(sortedData);
  };

  return {
    productList,
    isLoading,
    totalRecord,
    searchProductName,
    page,
    rowsPerPage,
    orderBy,
    order,
    error,
    isDeleting,
    deleteSuccess,
    deleteError,
    setSearchProductName,
    setPage,
    setRowsPerPage,
    setIsReload,
    getProducts,
    handleRequestSort,
    deleteProduct,
  };
};

export default useProductData;
