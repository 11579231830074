import { useState, useEffect } from "react";
import api from "../../../helpers/api";
import moment from "moment";

export const useStockOpnameData = (
  initialPage = 0,
  initialRowsPerPage = 10
) => {
  // Loading states
  const [isLoading, setIsLoading] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);
  const [isReload, setIsReload] = useState(false);

  // Error state
  const [error, setError] = useState(null);

  // Table states
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("desc");

  // Pagination states
  const [page, setPage] = useState(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const [totalRecord, setTotalRecord] = useState(0);

  // Filter states
  const [branchCodeSelected, setBranchCodeSelected] = useState("");
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);

  // Data states
  const [stockOpnameList, setStockOpnameList] = useState([]);
  const [detailStockOpname, setDetailStockOpname] = useState(null);
  const [grandTotalStockOpname, setGrandTotalStockOpname] = useState(0);
  const [stockOpnameProductList, setStockOpnameProductList] = useState([]);

  /**
   * Mengambil data daftar stock opname
   */
  const fetchStockOpnameList = async () => {
    const currentPage = page + 1;
    const dateFromFormatted = selectedDateFrom
      ? moment(selectedDateFrom).format("YYYY-MM-DD")
      : "";
    const dateToFormatted = selectedDateTo
      ? moment(selectedDateTo).format("YYYY-MM-DD")
      : "";

    setIsLoading(true);
    setError(null);

    try {
      const res = await api.getStockOpnameList(
        currentPage,
        rowsPerPage,
        dateFromFormatted,
        dateToFormatted,
        branchCodeSelected
      );

      if (res.statusCode === 200) {
        setStockOpnameList(res.data.data);
        setTotalRecord(res.data.totalPage * res.data.limit);
        setIsReload(false);
      } else {
        setError(res.message);
      }
    } catch (error) {
      setError(error.message || "Failed to fetch stock opname data");
      setStockOpnameList([]);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Mengambil detail stock opname berdasarkan ID
   * @param {string|number} id - ID stock opname
   * @param {number} pageDetail - Halaman detail yang diminta
   * @param {number} rowsPerPageDetail - Jumlah baris per halaman
   */
  const fetchStockOpnameDetail = async (
    id,
    pageDetail = 0,
    rowsPerPageDetail = 10
  ) => {
    if (!id) return null;

    const currentPageDetail = pageDetail + 1;

    setIsSpinner(true);
    setError(null);

    try {
      const res = await api.getDetailStockOpname(
        id,
        currentPageDetail,
        rowsPerPageDetail
      );

      if (res.statusCode === 200) {
        const data = res.data;

        // Transformasi data jika diperlukan
        const processedDetail = {
          ...data,
          attachments: data.data.map((item) => {
            if (item.stockOpnameTypeCode === "63") {
              return {
                ...item,
                stockOpnamePrice: `-${item.stockOpnamePrice}`,
              };
            } else if (item.stockOpnameTypeCode === "62") {
              return {
                ...item,
                stockOpnamePrice: `${item.stockOpnamePrice}`,
              };
            }
            return item;
          }),
        };

        // Hitung grand total jika ada data transaksi
        if (data.transaction) {
          const convertStockOpnameMinus = `-${data.transaction.TotalMinusPrice}`;
          const calculatedGrandTotal =
            parseInt(convertStockOpnameMinus) +
            parseInt(data.transaction.TotalPlusPrice);
          setGrandTotalStockOpname(calculatedGrandTotal);
        }

        setDetailStockOpname(processedDetail);
        return processedDetail;
      } else {
        setError(res.message);
        return null;
      }
    } catch (error) {
      setError(error.message || "Failed to fetch stock opname detail");
      return null;
    } finally {
      setIsSpinner(false);
    }
  };

  /**
   * Mengambil data produk untuk stock opname berdasarkan branch
   * @param {string} branchCode - Kode cabang
   */
  const fetchStockOpnameProducts = async (branchCode) => {
    if (!branchCode) return;

    setIsSpinner(true);
    setError(null);

    try {
      const res = await api.getStockOpnameProductList(branchCode);

      if (res.statusCode === 200) {
        const stockOpnameProducts = res.data.data;

        // Transform product data for stock opname
        const processedProducts = stockOpnameProducts.map((product) => ({
          ...product,
          inventoryQuantity: product.quantity,
          stockOpnameQuantity: product.quantity,
          totalProductDiffrence: 0,
          stockOpnameType: "",
          stockOpnameTypeCode: "",
          stockOpnamePrice: 0,
        }));

        setStockOpnameProductList(processedProducts);
        return processedProducts;
      } else {
        setError(res.message);
        return [];
      }
    } catch (error) {
      setError(error.message || "Failed to fetch stock opname products");
      return [];
    } finally {
      setIsSpinner(false);
    }
  };

  /**
   * Menyimpan stock opname
   * @param {Object} stockOpnameData - Data stock opname yang akan disimpan
   */
  const saveStockOpname = async (stockOpnameData) => {
    const {
      dateStockOpname,
      branchSelected,
      totalTrxPrice,
      negativeDiffSummary,
      positiveDiffSummary,
      totalStockOpnameQuantity,
      totalStockOpnamePrice,
      totalnventoryQuantity,
      totalTrxtInvetory,
      updatedProductList,
    } = stockOpnameData;

    setIsSpinner(true);
    setError(null);

    try {
      const res = await api.onSaveStockOpname(
        dateStockOpname,
        branchSelected,
        totalTrxPrice,
        negativeDiffSummary,
        positiveDiffSummary,
        totalStockOpnameQuantity,
        totalStockOpnamePrice,
        totalnventoryQuantity,
        totalTrxtInvetory,
        updatedProductList
      );

      if (res.statusCode === 200) {
        setIsReload(true);
        return { success: true, data: res.data };
      } else {
        setError(res.message);
        return { success: false, error: res.message };
      }
    } catch (error) {
      setError(error.message || "Failed to save stock opname");
      return { success: false, error: error.message };
    } finally {
      setIsSpinner(false);
    }
  };

  /**
   * Menangani perubahan kuantitas produk stock opname
   * @param {Object} event - Event dari input
   * @param {string} productCode - Kode produk
   */
  const handleQuantityChange = (event, productCode) => {
    const productIndex = stockOpnameProductList.findIndex(
      (product) => product.productCode === productCode
    );

    if (productIndex !== -1) {
      const newProducts = [...stockOpnameProductList];
      newProducts[productIndex][event.target.name] = event.target.value;
      newProducts[productIndex].updatedQuantity = event.target.value;
      setStockOpnameProductList(newProducts);

      return newProducts;
    }

    return stockOpnameProductList;
  };

  /**
   * Menghitung perbedaan (difference) kuantitas produk
   * @param {string} productCode - Kode produk
   */
  const calculateDifference = (productCode) => {
    const productIndex = stockOpnameProductList.findIndex(
      (product) => product.productCode === productCode
    );

    if (productIndex !== -1) {
      const newProducts = [...stockOpnameProductList];
      const product = newProducts[productIndex];

      // Calculate difference between stock opname quantity and actual quantity
      const difference = product.stockOpnameQuantity - product.quantity;

      // Update product with calculated values
      newProducts[productIndex].totalProductDiffrence = difference;
      newProducts[productIndex].stockOpnamePrice =
        product.trxPrice * difference;
      newProducts[productIndex].stockOpnameType =
        difference > 0 ? "STOCK OPNAME PLUS" : "STOCK OPNAME MINUS";
      newProducts[productIndex].stockOpnameTypeCode = difference > 0 ? 62 : 63;

      setStockOpnameProductList(newProducts);
      return newProducts;
    }

    return stockOpnameProductList;
  };

  /**
   * Menghitung total harga stock opname
   */
  const calculateTotalPrice = () => {
    return stockOpnameProductList.reduce(
      (total, product) => total + (product.stockOpnamePrice || 0),
      0
    );
  };

  /**
   * Handler untuk pengurutan data
   * @param {string} property - Properti yang akan diurutkan
   */
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";

    const sortedData = [...stockOpnameList].sort((a, b) => {
      const valueA = a[property];
      const valueB = b[property];

      if (typeof valueA === "string") {
        return newOrder === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }

      return newOrder === "asc" ? valueA - valueB : valueB - valueA;
    });

    setStockOpnameList(sortedData);
    setOrder(newOrder);
    setOrderBy(property);
  };

  // Effect untuk memuat data saat halaman atau jumlah baris per halaman berubah
  useEffect(() => {
    fetchStockOpnameList();
  }, [page, rowsPerPage]);

  // Effect untuk memuat ulang data saat isReload bernilai true
  useEffect(() => {
    if (isReload) {
      fetchStockOpnameList();
    }
  }, [isReload]);

  return {
    // State
    stockOpnameList,
    detailStockOpname,
    stockOpnameProductList,
    grandTotalStockOpname,
    isLoading,
    isSpinner,
    isReload,
    error,
    page,
    rowsPerPage,
    totalRecord,
    orderBy,
    order,
    branchCodeSelected,
    selectedDateFrom,
    selectedDateTo,

    // Setters
    setStockOpnameList,
    setDetailStockOpname,
    setStockOpnameProductList,
    setGrandTotalStockOpname,
    setIsLoading,
    setIsSpinner,
    setIsReload,
    setPage,
    setRowsPerPage,
    setBranchCodeSelected,
    setSelectedDateFrom,
    setSelectedDateTo,

    // Methods
    fetchStockOpnameList,
    fetchStockOpnameDetail,
    fetchStockOpnameProducts,
    saveStockOpname,
    handleQuantityChange,
    calculateDifference,
    calculateTotalPrice,
    handleRequestSort,
  };
};

export default useStockOpnameData;
