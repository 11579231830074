/**
 * Validasi form transfer inventory
 * @param {Object} formData - Data form transfer inventory
 * @returns {Object} Objek berisi pesan error jika validasi gagal
 */
export const validateTransferForm = (formData) => {
  const { branchNameFromSelected, branchNameToSelected, transferDate } =
    formData;
  const errors = {};

  // Validasi cabang pengirim
  if (
    !branchNameFromSelected ||
    branchNameFromSelected === null ||
    (Array.isArray(branchNameFromSelected) &&
      branchNameFromSelected.length === 0) ||
    Object.keys(branchNameFromSelected).length === 0
  ) {
    errors.branchNameFromSelected =
      "Branch / Outlet sender is required to choose";
  }

  // Validasi cabang penerima
  if (
    !branchNameToSelected ||
    branchNameToSelected === null ||
    (Array.isArray(branchNameToSelected) &&
      branchNameToSelected.length === 0) ||
    Object.keys(branchNameToSelected).length === 0
  ) {
    errors.branchNameToSelected =
      "Branch / Outlet receiver is required to choose";
  }

  // Validasi tanggal
  if (!transferDate) {
    errors.transferDate = "Date must be filled";
  }

  return errors;
};

/**
 * Validasi produk yang akan ditransfer
 * @param {Array} productOrders - Daftar produk yang akan ditransfer
 * @returns {Object} Status valid dan pesan error jika ada
 */
export const validateProductOrders = (productOrders) => {
  // Validasi daftar produk tidak boleh kosong
  if (!productOrders || productOrders.length === 0) {
    return {
      valid: false,
      message: "Product Order must be added first...",
    };
  }

  // Validasi setiap produk harus diisi lengkap
  const hasEmptyFields = productOrders.some(
    (productItem) =>
      !productItem.masterProductCode ||
      !productItem.quantity ||
      !productItem.transferedQuantity
  );

  if (hasEmptyFields) {
    return {
      valid: false,
      message:
        "Please ensure all product order have Product Variant, Quantity, Delivery Quantity.",
    };
  }

  // Validasi kuantitas transfer tidak boleh melebihi kuantitas yang tersedia
  const invalidQuantities = productOrders.filter(
    (product) =>
      parseInt(product.transferedQuantity) > parseInt(product.quantity)
  );

  if (invalidQuantities.length > 0) {
    return {
      valid: false,
      message: "Transfer quantity cannot exceed available quantity.",
    };
  }

  // Validasi kuantitas transfer harus positif
  const negativeQuantities = productOrders.filter(
    (product) => parseInt(product.transferedQuantity) <= 0
  );

  if (negativeQuantities.length > 0) {
    return {
      valid: false,
      message: "Transfer quantity must be greater than zero.",
    };
  }

  return { valid: true };
};

/**
 * Validasi produk untuk mencegah duplikasi
 * @param {Array} productOrders - Daftar produk yang akan ditransfer
 * @param {Object} newProduct - Produk baru yang akan ditambahkan
 * @returns {Object} Status valid dan pesan error jika ada
 */
export const validateProductDuplication = (productOrders, newProduct) => {
  // Cek apakah produk sudah ada di daftar
  const isDuplicate = productOrders.some(
    (product) => product.productCode === newProduct.productCode
  );

  if (isDuplicate) {
    return {
      valid: false,
      message: "This product variant is already added to the list.",
    };
  }

  return { valid: true };
};

export default {
  validateTransferForm,
  validateProductOrders,
  validateProductDuplication,
};
