// src/pages/products/add/index.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  Typography,
  Breadcrumbs,
  Link,
  Paper,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { FormInput, Spinner, CustomizedSnackbars } from "../../../components";
import ProductForm from "../productComponents/ProductForm";
import { useCategories } from "../hooks/useCategories";
import api from "../../../helpers/api";
import { validateProductForm } from "../utils/validation";

function AddProduct() {
  const navigate = useNavigate();
  const { categoryList, searchCategoryName, setSearchCategoryName } =
    useCategories();

  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    productNameInput: "",
    categoryNameSelected: null,
    brandName: "",
    note: "",
    variantList: [],
  });

  const handleFormChange = (fieldName, value) => {
    if (fieldName === "variantList") {
      // Tambahkan isCanDelete: true ke setiap item dalam variantList
      const updatedVariantList = value.map((item) => ({
        ...item,
        isCanDelete: true,
      }));

      setFormData((prevState) => ({
        ...prevState,
        [fieldName]: updatedVariantList,
      }));
    } else {
      // Untuk field lain, update normal
      setFormData((prevState) => ({
        ...prevState,
        [fieldName]: value,
      }));
    }

    // Clear validation error when field is updated
    if (errors[fieldName]) {
      setErrors({
        ...errors,
        [fieldName]: null,
      });
    }
  };

  // Fungsi terpisah untuk update variant list dari Excel upload
  const handleVariantListUpdate = (newVariantList) => {
    // Gunakan functional update untuk memastikan state yang diupdate selalu yang terbaru
    setFormData((prevState) => ({
      ...prevState,
      variantList: newVariantList,
    }));
  };

  const handleSaveProduct = async () => {
    // Validate form
    const validationErrors = validateProductForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setIsLoading(true);
    try {
      const res = await api.saveProduct(
        formData.categoryNameSelected.id,
        formData.productNameInput,
        formData.categoryNameSelected.categoryName,
        formData.brandName,
        formData.note,
        formData.variantList
      );

      if (res.statusCode === 200) {
        setIsLoading(false);
        setIsOpen(true);
        setAlertType("success");
        setMessage("Product added successfully");

        // Navigate back to product list after short delay
        setTimeout(() => {
          navigate("/products");
        }, 300);
      } else {
        throw new Error(res.message);
      }
    } catch (error) {
      setIsLoading(false);
      setIsOpen(true);
      setAlertType("error");
      setMessage(error.message || "Failed to add product");
    }
  };

  return (
    <>
      <Spinner isShowSpinner={isLoading} />
      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />

      <Paper
        sx={{
          p: 3,
          mb: 3,
          borderRadius: 1,
          boxShadow: 1,
        }}
      >
        {/* Header with breadcrumbs */}
        <Box sx={{ mb: 3 }}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link
              color="inherit"
              onClick={() => navigate("/products")}
              sx={{ cursor: "pointer", textDecoration: "none" }}
            >
              Products
            </Link>
            <Typography color="text.primary">Add New Product</Typography>
          </Breadcrumbs>

          <Typography variant="h6" sx={{ mt: 2 }}>
            Add New Product
          </Typography>
        </Box>

        {/* Product Form */}
        <ProductForm
          formData={formData}
          onChange={handleFormChange}
          categoryList={categoryList}
          onVariantListUpdate={handleVariantListUpdate}
          searchCategoryName={searchCategoryName}
          setSearchCategoryName={setSearchCategoryName}
          errors={errors}
        />

        {/* Action Buttons */}
        <Box sx={{ mt: 4, display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate("/products")}
          >
            Back to Products
          </Button>

          <Button
            variant="contained"
            startIcon={<SaveIcon />}
            onClick={handleSaveProduct}
          >
            Save Product
          </Button>
        </Box>
      </Paper>
    </>
  );
}

export default AddProduct;
