import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { Spinner, CustomizedSnackbars } from "../../components";
import { TableList } from "../../components";
import { tableHeadProductReceive } from "../../utils/Constants";
import api from "../../helpers/api";

// Impor komponen dan hooks khusus Product Receipt
import ProductReceiptSearch from "./ProductReceiptComponent/ProductReceiptSearch";
import { useProductReceiptData } from "./hooks/useProductReceiptData";
import { exportToXLSX } from "../../helpers/functions/generateExcel";

function ProductReceipt() {
  const navigate = useNavigate();

  // Gunakan custom hook untuk data product receipt
  const {
    productReceiveList,
    isLoading,
    isSpinner,
    totalRecord,
    branchCodeSelected,
    setBranchCodeSelected,
    selectedDateFrom,
    setSelectedDateFrom,
    selectedDateTo,
    setSelectedDateTo,
    page,
    rowsPerPage,
    orderBy,
    order,
    setPage,
    setRowsPerPage,
    fetchProductReceiveList,
    handleRequestSort,
    transactionCode,
    setIsSpinner,
    error,
  } = useProductReceiptData();

  // Alert states
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");

  // Handler untuk navigasi ke halaman detail
  const handleDetailView = (item) => {
    navigate(`/ProductReceipt/detail/${item.id}`);
  };

  // Handler untuk mengunduh laporan
  const handleDownloadReport = async () => {
    setIsSpinner(true);

    try {
      const dateFromFormatted = selectedDateFrom
        ? new Date(selectedDateFrom).toISOString().split("T")[0]
        : "";
      const dateToFormatted = selectedDateTo
        ? new Date(selectedDateTo).toISOString().split("T")[0]
        : "";

      const res = await api.reportDownload(
        transactionCode,
        dateFromFormatted,
        dateToFormatted
      );

      if (res.statusCode === 200) {
        const fileName = "product-receive-report";
        exportToXLSX(res.data.data, fileName);
        showSuccessMessage("Report successfully downloaded");
      } else {
        showErrorMessage(res.message || "Failed to download report");
      }
    } catch (error) {
      showErrorMessage(error.message || "Failed to download report");
    } finally {
      setIsSpinner(false);
    }
  };

  // Utility functions untuk menampilkan pesan
  const showSuccessMessage = (msg) => {
    setIsOpen(true);
    setAlertType("success");
    setMessage(msg);
  };

  const showErrorMessage = (msg) => {
    setIsOpen(true);
    setAlertType("error");
    setMessage(msg);
  };

  return (
    <>
      <Spinner isShowSpinner={isSpinner} />
      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />

      <Box
        sx={{
          width: "100%",
          backgroundColor: "white",
          borderRadius: 1,
          border: "1px solid",
          borderColor: "divider",
        }}
      >
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            alignItems: "center",
            p: 3,
            borderBottom: "1px solid",
            borderColor: "divider",
          }}
        >
          <Typography variant="h6" sx={{ mb: { xs: 2, sm: 0 } }}>
            Product Receive List
          </Typography>

          <Button
            variant="outlined"
            startIcon={<DownloadForOfflineIcon />}
            onClick={handleDownloadReport}
          >
            Download Report
          </Button>
        </Box>

        {/* Search Section */}
        <ProductReceiptSearch
          branchCodeSelected={branchCodeSelected}
          setBranchCodeSelected={setBranchCodeSelected}
          selectedDateFrom={selectedDateFrom}
          setSelectedDateFrom={setSelectedDateFrom}
          selectedDateTo={selectedDateTo}
          setSelectedDateTo={setSelectedDateTo}
          onSearch={() => {
            setPage(0);
            fetchProductReceiveList();
          }}
        />

        {/* Table List */}
        <TableList
          tableStructure="product-receive-list"
          tableName={"Product Receive List"}
          orderBy={orderBy}
          order={order}
          isLoading={isLoading}
          isShowPagination={true}
          tableHeadList={tableHeadProductReceive}
          data={productReceiveList}
          rowsPerPage={rowsPerPage}
          page={page}
          totalRecord={totalRecord}
          handleChangePage={(event, newPage) => setPage(newPage)}
          handleChangeRowsPerPage={(event) => {
            setRowsPerPage(+event.target.value);
            setPage(0);
          }}
          onPreviewDetail={handleDetailView}
          handleRequestSort={handleRequestSort}
        />
      </Box>
    </>
  );
}

export default ProductReceipt;
