/**
 * Format a number with thousand separator
 * @param {number|string} num - The number to format
 * @param {string} defaultValue - Default value if num is empty
 * @returns {string} Formatted number string
 */
export const formatNumber = (num, defaultValue = "") => {
  if (num === "" || num === null || num === undefined) return defaultValue;
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

/**
 * Format currency in Indonesian Rupiah format
 * @param {number|string} amount - The amount to format
 * @param {string} defaultValue - Default value if amount is empty
 * @returns {string} Formatted currency string
 */
export const formatCurrency = (amount, defaultValue = "Rp 0") => {
  if (amount === "" || amount === null || amount === undefined)
    return defaultValue;
  return `Rp ${formatNumber(amount)}`;
};

/**
 * Format date to Indonesian format
 * @param {string|Date} date - Date to format
 * @param {string} defaultValue - Default value if date is invalid
 * @returns {string} Formatted date string
 */
export const formatDate = (date, defaultValue = "-") => {
  if (!date) return defaultValue;

  try {
    const d = new Date(date);
    if (isNaN(d.getTime())) return defaultValue;

    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();

    return `${day}/${month}/${year}`;
  } catch (error) {
    return defaultValue;
  }
};

/**
 * Format date to YYYY-MM-DD format for API requests
 * @param {string|Date} date - Date to format
 * @param {string} defaultValue - Default value if date is invalid
 * @returns {string} Formatted date string for API
 */
export const formatDateForAPI = (date, defaultValue = "") => {
  if (!date) return defaultValue;

  try {
    const d = new Date(date);
    if (isNaN(d.getTime())) return defaultValue;

    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();

    return `${year}-${month}-${day}`;
  } catch (error) {
    return defaultValue;
  }
};

/**
 * Get receipt status color based on status name
 * @param {string} statusName - Status name
 * @returns {string} Material UI color name
 */
export const getStatusColor = (statusName) => {
  switch (statusName) {
    case "Completed":
      return "success";
    case "Pending":
      return "warning";
    case "Cancelled":
      return "error";
    case "Draft":
      return "default";
    case "Processing":
      return "info";
    default:
      return "primary";
  }
};

/**
 * Format quantity with unit
 * @param {number|string} quantity - Quantity value
 * @param {string} unit - Unit of measurement (default: pcs)
 * @param {string} defaultValue - Default value if quantity is empty
 * @returns {string} Formatted quantity with unit
 */
export const formatQuantity = (quantity, unit = "pcs", defaultValue = "0") => {
  if (quantity === "" || quantity === null || quantity === undefined)
    return defaultValue + " " + unit;
  return `${formatNumber(quantity)} ${unit}`;
};

/**
 * Truncate text if it exceeds maximum length
 * @param {string} text - Text to truncate
 * @param {number} maxLength - Maximum length before truncating
 * @returns {string} Truncated text
 */
export const truncateText = (text, maxLength = 50) => {
  if (!text) return "";

  if (text.length <= maxLength) return text;

  return text.substring(0, maxLength) + "...";
};

/**
 * Format supplier or branch name for display
 * @param {string} code - Code (supplierCode or branchCode)
 * @param {string} name - Name (supplierName or branchName)
 * @returns {string} Formatted name with code
 */
export const formatNameWithCode = (code, name) => {
  if (!code && !name) return "-";
  if (!code) return name;
  if (!name) return code;

  return `${name} (${code})`;
};

export default {
  formatNumber,
  formatCurrency,
  formatDate,
  formatDateForAPI,
  getStatusColor,
  formatQuantity,
  truncateText,
  formatNameWithCode,
};
