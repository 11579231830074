/**
 * Format a number with thousand separator
 * @param {number|string} num - The number to format
 * @param {string} defaultValue - Default value if num is empty
 * @returns {string} Formatted number string
 */
export const formatNumber = (num, defaultValue = "") => {
  if (num === "" || num === null || num === undefined) return defaultValue;
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

/**
 * Format currency in Indonesian Rupiah format
 * @param {number|string} amount - The amount to format
 * @param {string} defaultValue - Default value if amount is empty
 * @returns {string} Formatted currency string
 */
export const formatCurrency = (amount, defaultValue = "Rp 0") => {
  if (amount === "" || amount === null || amount === undefined)
    return defaultValue;
  return `Rp ${formatNumber(amount)}`;
};

/**
 * Format date to Indonesian format
 * @param {string|Date} date - Date to format
 * @param {string} defaultValue - Default value if date is invalid
 * @returns {string} Formatted date string
 */
export const formatDate = (date, defaultValue = "-") => {
  if (!date) return defaultValue;

  try {
    const d = new Date(date);
    if (isNaN(d.getTime())) return defaultValue;

    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();

    return `${day}/${month}/${year}`;
  } catch (error) {
    return defaultValue;
  }
};

/**
 * Format date to YYYY-MM-DD format for API requests
 * @param {string|Date} date - Date to format
 * @param {string} defaultValue - Default value if date is invalid
 * @returns {string} Formatted date string for API
 */
export const formatDateForAPI = (date, defaultValue = "") => {
  if (!date) return defaultValue;

  try {
    const d = new Date(date);
    if (isNaN(d.getTime())) return defaultValue;

    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();

    return `${year}-${month}-${day}`;
  } catch (error) {
    return defaultValue;
  }
};

/**
 * Format stock opname type to more readable text
 * @param {string} type - Stock opname type
 * @returns {string} Formatted type
 */
export const formatStockOpnameType = (type) => {
  if (!type) return "-";

  // Convert to title case
  return type
    .replace("STOCK OPNAME ", "")
    .toLowerCase()
    .replace(/\b\w/g, (l) => l.toUpperCase());
};

/**
 * Get color for stock opname type
 * @param {string} type - Stock opname type or type code
 * @returns {string} Material UI color
 */
export const getStockOpnameTypeColor = (type) => {
  if (type === "STOCK OPNAME PLUS" || type === "62") {
    return "success";
  } else if (type === "STOCK OPNAME MINUS" || type === "63") {
    return "error";
  }
  return "default";
};

/**
 * Format difference value with sign and color cues
 * @param {number} value - Difference value
 * @returns {Object} Object with formatted value and color
 */
export const formatDifference = (value) => {
  if (value === null || value === undefined) {
    return { value: "0", color: "text.primary" };
  }

  const numValue = parseInt(value);

  if (numValue > 0) {
    return { value: `+${formatNumber(numValue)}`, color: "success.main" };
  } else if (numValue < 0) {
    return { value: formatNumber(numValue), color: "error.main" };
  }

  return { value: "0", color: "text.primary" };
};

export default {
  formatNumber,
  formatCurrency,
  formatDate,
  formatDateForAPI,
  formatStockOpnameType,
  getStockOpnameTypeColor,
  formatDifference,
};
