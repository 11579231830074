import React from "react";
import { Grid } from "@mui/material";
import { FormInput } from "../../../components";

const PasswordForm = ({
  oldPasswordInput,
  setOldPasswordInput,
  passwordInput,
  setPasswordInput,
  errors,
  setErrors,
}) => {
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} sm={12}>
        <FormInput
          label="Current Password"
          name="oldPasswordInput"
          type="password"
          size="medium"
          error={!!errors.oldPasswordInput}
          helperText={errors.oldPasswordInput}
          value={oldPasswordInput}
          onChange={(event) => {
            setOldPasswordInput(event.target.value);
            if (errors.oldPasswordInput) {
              const newErrors = { ...errors };
              delete newErrors.oldPasswordInput;
              setErrors(newErrors);
            }
          }}
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <FormInput
          label="New Password"
          name="passwordInput"
          type="password"
          size="medium"
          error={!!errors.passwordInput}
          helperText={errors.passwordInput}
          value={passwordInput}
          onChange={(event) => {
            setPasswordInput(event.target.value);
            if (errors.passwordInput) {
              const newErrors = { ...errors };
              delete newErrors.passwordInput;
              setErrors(newErrors);
            }
          }}
        />
      </Grid>
    </Grid>
  );
};

export default PasswordForm;
