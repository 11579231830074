import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Paper,
  Typography,
  Button,
  Breadcrumbs,
  Link,
  CircularProgress,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import { Spinner, CustomizedSnackbars } from "../../../../components";
import { statusList } from "../../../../utils/Constants";

// Import components, hooks and utils
import BranchForm from "../../BranchComponent/BranchForm";
import { useBranchData } from "../../hooks/useBranchData";
import { validateBranchForm } from "../../utils/validation";

function EditBranch() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const branchFromState = location.state?.branch;

  // Use custom hook for API functions
  const { getBranchById, updateBranch } = useBranchData();

  // Loading state
  const [isLoading, setIsLoading] = useState(!branchFromState);
  const [isSpinner, setIsSpinner] = useState(false);

  // Form state
  const [formData, setFormData] = useState({
    branchName: branchFromState?.branchName || "",
    picName: branchFromState?.picName || "",
    status: {},
    branchPhone: branchFromState?.branchPhone || "",
    address: branchFromState?.address || "",
  });
  const [errors, setErrors] = useState({});

  // Alert states
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");

  // Load branch data and set status
  useEffect(() => {
    if (branchFromState) {
      const status =
        statusList.find((item) => item.value === branchFromState.statusCode) ||
        {};

      setFormData({
        ...formData,
        status,
      });
    } else {
      loadBranchData();
    }
  }, []);

  // Load branch data if not available from state
  const loadBranchData = async () => {
    setIsLoading(true);
    try {
      const branch = await getBranchById(id);

      if (branch) {
        const status =
          statusList.find((item) => item.value === branch.statusCode) || {};

        setFormData({
          branchName: branch.branchName || "",
          picName: branch.picName || "",
          status,
          branchPhone: branch.branchPhone || "",
          address: branch.address || "",
        });
      } else {
        showError("Outlet not found");
        // Navigate back if branch not found
        setTimeout(() => {
          navigate("/branches");
        }, 300);
      }
    } catch (error) {
      showError(error?.message || "Failed to load outlet");
      setTimeout(() => {
        navigate("/branches");
      }, 300);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle form submission
  const handleSubmit = async () => {
    // Validate form
    const validationErrors = validateBranchForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setIsSpinner(true);

    try {
      const result = await updateBranch(id, formData);

      if (result.success) {
        showSuccess("Outlet updated successfully");

        // Navigate back to branch list after short delay
        setTimeout(() => {
          navigate("/branches");
        }, 300);
      } else {
        showError(result.error || "Failed to update outlet");
      }
    } catch (error) {
      showError(error?.message || "An error occurred");
    } finally {
      setIsSpinner(false);
    }
  };

  // Utility functions
  const showSuccess = (msg) => {
    setIsOpen(true);
    setAlertType("success");
    setMessage(msg);
  };

  const showError = (msg) => {
    setIsOpen(true);
    setAlertType("error");
    setMessage(msg);
  };

  // Loading indicator
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "70vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Spinner isShowSpinner={isSpinner} />
      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />

      <Paper
        sx={{
          p: 3,
          mb: 3,
          borderRadius: 1,
          boxShadow: 1,
        }}
      >
        {/* Header */}
        <Box
          sx={{
            mb: 3,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link
                color="inherit"
                onClick={() => navigate("/branches")}
                sx={{ cursor: "pointer", textDecoration: "none" }}
              >
                Outlets
              </Link>
              <Typography color="text.primary">Edit Outlet</Typography>
            </Breadcrumbs>

            <Typography variant="h6" sx={{ mt: 1 }}>
              Edit Outlet
            </Typography>
          </Box>
        </Box>

        {/* Form Content */}
        <BranchForm
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
        />

        {/* Action Buttons */}
        <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate("/branches")}
          >
            Back to Outlets
          </Button>

          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={handleSubmit}
          >
            Save Changes
          </Button>
        </Box>
      </Paper>
    </>
  );
}

export default EditBranch;
