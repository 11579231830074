import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Tabs,
  Tab,
  Paper,
  Breadcrumbs,
  Link,
} from "@mui/material";
import { Spinner, CustomizedSnackbars } from "../../../../components";
import UserForm from "../../UserComponent/UserForm";
import PasswordForm from "../../UserComponent/PasswordForm";
import AuthForm from "../../UserComponent/AuthForm";
import useUserData from "../../hooks/useUserData";
import useUserFormValidation from "../../utils/validation";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";

const EditUser = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();

  // Ambil userData dari location state
  const userData = location.state?.userData;

  // Check if we should open auth tab directly (from user list page)
  const openAuthTab = location.state?.openAuthTab || false;

  const {
    isLoading,
    isSpinner,
    error,
    branchList,
    fetchBranches,
    updateUser,
    changePassword,
    createAuth,
  } = useUserData();

  const {
    errors,
    setErrors,
    validateForm,
    validatePasswordForm,
    validateAuthForm,
  } = useUserFormValidation();

  // Local alert state
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");

  // Form state
  const [branchNameSelected, setBranchNameSelected] = useState([]);
  const [searchBranchName, setSearchBranchName] = useState("");
  const [selectedBranchName, setSelectedBranchName] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [phoneInput, setPhoneInput] = useState("");
  const [usernameInput, setUsernameInput] = useState("");
  const [userCode, setUserCode] = useState("");

  // Auth form state
  const [usernameAuth, setUsernameAuth] = useState("");
  const [emailAuth, setEmailAuth] = useState("");

  // Password state
  const [oldPasswordInput, setOldPasswordInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");

  // Auth state
  const [hasAuth, setHasAuth] = useState(false);

  // Tab state - if openAuthTab is true and user has no auth, start at tab 2
  const [activeTab, setActiveTab] = useState(openAuthTab && !hasAuth ? 2 : 0);

  // Show API errors if any
  useEffect(() => {
    if (error) {
      showError(error);
    }
  }, [error]);

  // Load branches
  useEffect(() => {
    fetchBranches();
  }, [searchBranchName]);

  // Load user data from navigation state
  useEffect(() => {
    if (userData) {
      console.log("Using user data from navigation state:", userData);

      // Set form fields with user data
      setEmployeeName(userData.userFullname || "");
      setEmailInput(userData.userEmail || "");
      setPhoneInput(userData.userPhoneNumber || "");
      setUsernameInput(userData.username || "");
      setUserCode(userData.userCode || "");
      setSelectedBranchName(userData.branchCode || "");

      // Set auth status based on username
      setHasAuth(!!userData.username);

      // Initialize auth form fields
      setUsernameAuth(userData.username || "");
      setEmailAuth(userData.userEmail || "");
    } else {
      showError(
        "No user data available. Please go back to user list and try again."
      );
      // Navigate back to user list after a short delay
      setTimeout(() => {
        navigateToUsersList();
      }, 2000);
    }
  }, [userData]);

  // Set selected branch once branchList is loaded
  useEffect(() => {
    if (branchList && branchList.length > 0 && selectedBranchName) {
      const branch = branchList.find(
        (b) => b.branchCode === selectedBranchName
      );
      if (branch) {
        setBranchNameSelected(branch);
      }
    }
  }, [branchList, selectedBranchName]);

  // Sync usernameAuth and emailAuth with user data when it changes
  useEffect(() => {
    if (!hasAuth && emailInput) {
      setEmailAuth(emailInput);
      setUsernameAuth(employeeName);
    }
  }, [emailInput, employeeName, hasAuth]);

  const handleEditSubmit = async () => {
    try {
      const { isValid, errors: validationErrors } = validateForm({
        branchNameSelected,
        employeeName,
        emailInput,
        phoneInput,
      });

      if (!isValid) {
        if (validationErrors) {
          setErrors(validationErrors);
        }
        return;
      }

      // Prepare user data
      const userData = {
        username: usernameInput,
        email: emailInput,
        fullName: employeeName,
        phoneNumber: phoneInput,
        branchCode: selectedBranchName,
        userCode: userCode,
      };

      const result = await updateUser(id, userData);

      if (result.success) {
        showSuccess("User updated successfully");

        // Navigate back to users list after short delay
        setTimeout(() => {
          navigate("/users");
        }, 300);
      } else {
        showError(result.error || "Failed to update user");
      }
    } catch (error) {
      showError(error?.message || "An error occurred while updating user");
    }
  };

  const handlePasswordSubmit = async () => {
    try {
      const { isValid, errors: validationErrors } = validatePasswordForm({
        oldPasswordInput,
        passwordInput,
      });

      if (!isValid) {
        if (validationErrors) {
          setErrors(validationErrors);
        }
        return;
      }

      const result = await changePassword(
        id,
        oldPasswordInput,
        passwordInput,
        userCode
      );

      if (result.success) {
        showSuccess("Password changed successfully");
        setOldPasswordInput("");
        setPasswordInput("");
        setTimeout(() => {
          navigate("/users");
        }, 300);
      } else {
        showError(result.error || "Failed to change password");
      }
    } catch (error) {
      showError(error?.message || "An error occurred while changing password");
    }
  };

  const handleCreateAuthSubmit = async () => {
    try {
      // Validate the auth form fields
      const { isValid, errors: validationErrors } = validateAuthForm({
        usernameAuth,
        passwordInput,
        emailAuth,
      });

      if (!isValid) {
        if (validationErrors) {
          setErrors(validationErrors);
        }
        return;
      }

      // Prepare auth data
      const authData = {
        username: usernameAuth,
        password: passwordInput,
        email: emailAuth,
        userCode: userCode,
        branchCode: selectedBranchName,
      };

      const result = await createAuth(authData);

      if (result.success) {
        showSuccess("Login credentials created successfully");
        setHasAuth(true);

        // Reset form and switch to edit tab
        setPasswordInput("");
        setActiveTab(0);
      } else {
        showError(result.error || "Failed to create login");
      }
    } catch (error) {
      showError(error?.message || "An error occurred while creating login");
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setErrors({}); // Clear validation errors when switching tabs
  };

  // Utility functions
  const showSuccess = (msg) => {
    setIsOpen(true);
    setAlertType("success");
    setMessage(msg);
  };

  const showError = (msg) => {
    setIsOpen(true);
    setAlertType("error");
    setMessage(msg);
  };

  // Safe navigation back to users list
  const navigateToUsersList = () => {
    try {
      navigate("/users");
    } catch (error) {
      console.error("Navigation error:", error);
      // Fallback if navigation fails
      window.location.href = "/users";
    }
  };

  return (
    <>
      <Spinner isShowSpinner={isSpinner || isLoading} />
      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />

      <Paper
        sx={{
          p: 3,
          mb: 3,
          borderRadius: 1,
          boxShadow: 1,
        }}
      >
        {/* Header */}
        <Box
          sx={{
            mb: 3,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link
                color="inherit"
                onClick={navigateToUsersList}
                sx={{ cursor: "pointer", textDecoration: "none" }}
              >
                Users
              </Link>
              <Typography color="text.primary">Manage User</Typography>
            </Breadcrumbs>

            <Typography variant="h6" sx={{ mt: 1 }}>
              Manage User {employeeName ? `- ${employeeName}` : ""}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 3 }}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            aria-label="user management tabs"
          >
            <Tab label="Edit Profile" />
            <Tab label="Change Password" />
            {!hasAuth && <Tab label="Create Login" />}
          </Tabs>
        </Box>

        {/* Edit Profile Tab */}
        {activeTab === 0 && (
          <>
            <UserForm
              mode="edit"
              branchList={branchList || []}
              branchNameSelected={branchNameSelected}
              setBranchNameSelected={setBranchNameSelected}
              searchBranchName={searchBranchName}
              setSearchBranchName={setSearchBranchName}
              selectedBranchName={selectedBranchName}
              setSelectedBranchName={setSelectedBranchName}
              employeeName={employeeName}
              setEmployeeName={setEmployeeName}
              emailInput={emailInput}
              setEmailInput={setEmailInput}
              phoneInput={phoneInput}
              setPhoneInput={setPhoneInput}
              usernameInput={usernameInput}
              setUsernameInput={setUsernameInput}
              errors={errors}
              setErrors={setErrors}
            />

            <Box
              sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}
            >
              <Button
                variant="outlined"
                startIcon={<ArrowBackIcon />}
                onClick={navigateToUsersList}
              >
                Back to Users
              </Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={handleEditSubmit}
              >
                Save Changes
              </Button>
            </Box>
          </>
        )}

        {/* Change Password Tab */}
        {activeTab === 1 && (
          <>
            <PasswordForm
              oldPasswordInput={oldPasswordInput}
              setOldPasswordInput={setOldPasswordInput}
              passwordInput={passwordInput}
              setPasswordInput={setPasswordInput}
              errors={errors}
              setErrors={setErrors}
            />

            <Box
              sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}
            >
              <Button
                variant="outlined"
                startIcon={<ArrowBackIcon />}
                onClick={navigateToUsersList}
              >
                Back to Users
              </Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={handlePasswordSubmit}
              >
                Change Password
              </Button>
            </Box>
          </>
        )}

        {/* Create Login Tab */}
        {activeTab === 2 && !hasAuth && (
          <>
            <Box sx={{ mb: 3 }}>
              <Typography variant="body2" color="text.secondary">
                Creating login credentials for:{" "}
                <strong>{employeeName || "User"}</strong>
              </Typography>
            </Box>

            <AuthForm
              usernameAuth={usernameAuth}
              setUsernameAuth={setUsernameAuth}
              emailAuth={emailAuth}
              setEmailAuth={setEmailAuth}
              passwordInput={passwordInput}
              setPasswordInput={setPasswordInput}
              errors={errors}
              setErrors={setErrors}
            />

            <Box
              sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}
            >
              <Button
                variant="outlined"
                startIcon={<ArrowBackIcon />}
                onClick={navigateToUsersList}
              >
                Back to Users
              </Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={handleCreateAuthSubmit}
              >
                Create Login
              </Button>
            </Box>
          </>
        )}
      </Paper>
    </>
  );
};

export default EditUser;
