import { useState, useEffect } from "react";
import api from "../../../helpers/api";

export const useBranches = (initialSearchTerm = "") => {
  const [branchList, setBranchList] = useState([]);
  const [branchListTable, setBranchListTable] = useState([]);
  const [searchBranchName, setSearchBranchName] = useState(initialSearchTerm);
  const [branchSelected, setBranchSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBranches = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const res = await api.getBranch(searchBranchName);
        if (res.statusCode === 200) {
          // Simpan data asli untuk pilihan branch di form
          setBranchList(res.data.data);

          // Tambahkan "All Branches" option untuk tabel filter
          const newBranch = {
            id: "",
            branchName: "All Branches",
            address: "",
            branchPhone: "",
            statusName: "",
            statusCode: "",
            branchCode: "",
            createdDate: "",
            updatedDate: "",
          };
          const updatedBranchList = [newBranch, ...res.data.data];
          setBranchListTable(updatedBranchList);
        } else {
          setBranchList([]);
          setBranchListTable([]);
          setError(res.message);
        }
      } catch (err) {
        setBranchList([]);
        setBranchListTable([]);
        setError(err.message || "Failed to fetch branches");
      } finally {
        setIsLoading(false);
      }
    };

    fetchBranches();
  }, [searchBranchName]);

  /**
   * Memilih branch untuk digunakan
   * @param {Object} branch - Data branch yang dipilih
   */
  const selectBranch = (branch) => {
    setBranchSelected(branch);
    return branch;
  };

  /**
   * Reset pilihan branch
   */
  const resetBranchSelection = () => {
    setBranchSelected(null);
    setSearchBranchName("");
  };

  return {
    branchList,
    branchListTable,
    branchSelected,
    searchBranchName,
    isLoading,
    error,

    // Setters
    setBranchSelected,
    setSearchBranchName,

    // Methods
    selectBranch,
    resetBranchSelection,
  };
};

export default useBranches;
