import { useState, useEffect } from "react";
import api from "../../../helpers/api";

export const useBranchData = (initialPage = 0, initialRowsPerPage = 10) => {
  // Loading states
  const [isLoading, setIsLoading] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);
  const [isReload, setIsReload] = useState(false);

  // Error state
  const [error, setError] = useState(null);

  // Table states
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("desc");

  // Pagination states
  const [page, setPage] = useState(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const [totalRecord, setTotalRecord] = useState(0);

  // Search state
  const [searchBranchName, setSearchBranchName] = useState("");

  // Data states
  const [branchList, setBranchList] = useState([]);

  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteError, setDeleteError] = useState(null);

  // ==================

  const fetchBranches = async () => {
    const currentPage = page + 1;
    setIsLoading(true);
    setError(null);

    try {
      const res = await api.getBranch(
        searchBranchName,
        rowsPerPage,
        currentPage
      );

      if (res.statusCode === 200) {
        setBranchList(res.data.data);
        setTotalRecord(res.data.totalPage * res.data.limit);
      } else {
        setError(res.message);
        setBranchList([]);
      }
    } catch (error) {
      setError(error.message || "Failed to fetch branches");
      setBranchList([]);
    } finally {
      setIsLoading(false);
      setIsReload(false);
    }
  };

  const getBranchById = async (id) => {
    setIsSpinner(true);
    setError(null);

    try {
      const res = await api.getBranchById(id);

      if (res.statusCode === 200) {
        return res.data;
      } else {
        setError(res.message);
        return null;
      }
    } catch (error) {
      setError(error.message || "Failed to fetch branch");
      return null;
    } finally {
      setIsSpinner(false);
    }
  };

  const addBranch = async (branchData) => {
    const { branchName, picName, status, branchPhone, address } = branchData;

    setError(null);
    setIsSpinner(true);

    try {
      const res = await api.saveBranch(
        branchName,
        picName,
        status.label,
        status.value,
        branchPhone,
        address
      );

      if (res.statusCode === 200) {
        setIsReload(true);
        return { success: true, data: res.data };
      } else {
        setError(res.message);
        return { success: false, error: res.message };
      }
    } catch (error) {
      setError(error.message || "Failed to add branch");
      return { success: false, error: error.message };
    } finally {
      setIsSpinner(false);
    }
  };

  const updateBranch = async (id, branchData) => {
    const { branchName, picName, status, branchPhone, address } = branchData;

    setError(null);
    setIsSpinner(true);

    try {
      const res = await api.editBranch(
        id,
        branchName,
        picName,
        status.label,
        status.value,
        branchPhone,
        address
      );

      if (res.statusCode === 200) {
        setIsReload(true);
        return { success: true, data: res.data };
      } else {
        setError(res.message);
        return { success: false, error: res.message };
      }
    } catch (error) {
      setError(error.message || "Failed to update branch");
      return { success: false, error: error.message };
    } finally {
      setIsSpinner(false);
    }
  };

  const deleteBranch = async (branch) => {
    setIsDeleting(true);
    setDeleteSuccess(false);
    setDeleteError(null);

    try {
      const response = await api.deleteMasterBranch(branch);

      if (response && response.statusCode === 200) {
        setDeleteSuccess(true);

        // Refresh data setelah penghapusan berhasil
        setIsReload(true);

        return {
          success: true,
          message: "Outlet deleted successfully",
        };
      } else {
        const errorMsg = response?.message || "Failed to delete outlet";
        setDeleteError(errorMsg);

        return {
          success: false,
          message: errorMsg,
        };
      }
    } catch (error) {
      const errorMsg =
        error?.message || "An error occurred while deleting outlet";
      setDeleteError(errorMsg);

      return {
        success: false,
        message: errorMsg,
      };
    } finally {
      setIsDeleting(false);
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";

    const sortedData = [...branchList].sort((a, b) => {
      // Use dynamic property access with bracket notation
      const valueA = a[property];
      const valueB = b[property];

      // Handle different types of sorting
      if (typeof valueA === "string") {
        return newOrder === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }

      return newOrder === "asc" ? valueA - valueB : valueB - valueA;
    });

    setBranchList(sortedData);
    setOrder(newOrder);
    setOrderBy(property);
  };

  // Effect to load branches when dependencies change
  useEffect(() => {
    fetchBranches();
  }, [page, rowsPerPage]);

  // Effect to reload data when isReload changes
  useEffect(() => {
    if (isReload) {
      fetchBranches();
    }
  }, [isReload]);

  return {
    // States
    isLoading,
    isSpinner,
    error,
    branchList,
    page,
    rowsPerPage,
    totalRecord,
    orderBy,
    order,
    searchBranchName,

    // Setters
    setIsLoading,
    setIsSpinner,
    setError,
    setPage,
    setRowsPerPage,
    setOrderBy,
    setOrder,
    setSearchBranchName,
    setIsReload,

    // API Methods
    fetchBranches,
    getBranchById,
    addBranch,
    updateBranch,

    // Helpers
    handleRequestSort,

    deleteBranch,
  };
};

export default useBranchData;
