import React from "react";

function Brand() {
  return <div>Brand</div>;
}

export default Brand;

// import React, { useEffect, useState } from "react";
// import Breadcrumbs from "@mui/material/Breadcrumbs";
// import Typography from "@mui/material/Typography";
// import Grid from "@mui/material/Grid";
// import Button from "@mui/material/Button";
// import { TableList, Spinner, Modal, FormInput } from "../../components";
// import CustomizedSnackbars from "../../components/base/Snackbar";
// import api from "../../helpers/api";
// import { tableHeadBrand } from "../../utils/Constants";
// import moment from "moment";
// import AddCircleIcon from "@mui/icons-material/AddCircle";
// import { SearchOutlined } from "@mui/icons-material";

// function Brands() {
//   const [isLoading, setIsLoading] = useState(false);
//   const [isSpinner, setIsSpinner] = useState(false);
//   const [isOpen, setIsOpen] = useState(false);
//   const [isReload, setIsReload] = useState(false);
//   const [isOpenModal, setIsOpenModal] = useState(false);
//   const [errors, setErrors] = useState({});
//   const [mode, setMode] = useState("");

//   const [alertType, setAlertType] = useState("");
//   const [message, setMessage] = useState("");
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(10);
//   // ===========
//   const [brandList, setBrandList] = useState([]);
//   const [searchBrandName, setSearchBrandName] = useState("");
//   const [brandNameInput, setBrandNameInput] = useState("");
//   const [brandSelected, setBrandSelected] = useState([]);
//   // ==========

//   const onGetBrand = async () => {
//     setIsLoading(true);
//     try {
//       const res = await api
//         .getBrands(searchBrandName)
//         .then(async (res) => {
//           if (res.statusCode === 200) {
//             setBrandList(res.data.data);
//             setIsLoading(false);
//             setIsReload(false);
//           } else {
//             onError(res.message);
//           }
//         })
//         .catch((error) => {
//           onError(error.message);
//         });
//     } catch (error) {
//       onError(error.message);
//     }
//   };

//   const onAddBrand = async () => {
//     setIsLoading(true);
//     try {
//       const res = await api
//         .saveBrands(brandNameInput)
//         .then(async (res) => {
//           if (res.statusCode === 200) {
//             setIsOpenModal(false);
//             onSuccessSubmit();
//           } else {
//             onError(res.message);
//           }
//         })
//         .catch((error) => {
//           onError(error.message);
//         });
//     } catch (error) {
//       onError(error.message);
//     }
//   };

//   const onEditBrand = async () => {
//     setIsLoading(true);
//     try {
//       const res = await api
//         .editBrands(brandSelected.id, brandNameInput)
//         .then(async (res) => {
//           if (res.statusCode === 200) {
//             setIsOpenModal(false);
//             onSuccessSubmit();
//           } else {
//             onError(res.message);
//           }
//         })
//         .catch((error) => {
//           onError(error.message);
//         });
//     } catch (error) {
//       onError(error.message);
//     }
//   };

//   useEffect(() => {
//     const fetch = async () => {
//       await onGetBrand();
//     };
//     fetch();
//   }, []);

//   useEffect(() => {
//     if (isReload) {
//       onGetBrand();
//     }
//   }, [isReload]);

//   const onSuccessSubmit = async () => {
//     setBrandNameInput("");
//     setErrors({});
//     setIsLoading(false);
//     setIsOpen(true);
//     setAlertType("success");
//     setMessage("Successfully...");
//     setIsReload(true);
//   };

//   const onError = async (message) => {
//     setIsLoading(false);
//     setIsOpen(true);
//     setAlertType("error");
//     setMessage(message);
//     setIsReload(false);
//   };

//   const handleChangePage = (event, newPage) => {
//     alert("next");
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     alert("page select");
//     setRowsPerPage(+event.target.value);
//     setPage(0);
//   };

//   const validateForm = () => {
//     const newErrors = {};
//     if (!brandNameInput || brandNameInput.trim() === "") {
//       newErrors.brandNameInput = "Brand Name is required";
//     }
//     return newErrors;
//   };

//   const handleSubmit = async () => {
//     const validationErrors = validateForm();
//     if (Object.keys(validationErrors).length > 0) {
//       setErrors(validationErrors);
//       return;
//     }
//     if (mode === "Add New Brand") {
//       await onAddBrand();
//     } else if (mode === "Edit Brand") {
//       await onEditBrand();
//     }
//   };

//   const handleEditItem = (itemSelected, indexSelected) => {
//     setIsOpenModal(true);
//     setMode("Edit Brand");
//     setBrandSelected(itemSelected);
//     setBrandNameInput(itemSelected.brandName);
//   };

//   // ===============
//   const breadcrumbs = [
//     <Typography key="3" color="text.primary" fontSize={12}>
//       Brands
//     </Typography>,
//   ];

//   // ==========

//   return (
//     <div className="max-w-[94%] flex flex-col m-auto">
//       <Spinner isShowSpinner={isSpinner} />
//       <div className="flex w-full h-16 mt-6 bg-white rounded-md shadow-[0_8px_30px_rgb(0,0,0,0.12)]">
//         <div className="flex flex-row w-full px-6 justify-between items-center">
//           <div className="">
//             <h3 className="text-xl font-semibold">Brands</h3>
//           </div>
//           <div className="">
//             <Breadcrumbs separator="›" aria-label="breadcrumb">
//               {breadcrumbs}
//             </Breadcrumbs>
//           </div>
//         </div>
//       </div>
//       <div className="w-full h-full py-4 bg-white rounded-md shadow-[0_8px_30px_rgb(0,0,0,0.12)] mt-4">
//         <div className="flex flex-row w-full px-6 mb-4 justify-between items-center">
//           <h3 className="text-lg font-semibold">Brand List</h3>
//           <Button
//             component="label"
//             variant="outlined"
//             startIcon={<AddCircleIcon />}
//             onClick={() => {
//               setMode("Add New Brand");
//               setIsOpenModal(true);
//             }}
//           >
//             Add New Brand
//           </Button>
//         </div>

//         {/* search */}
//         <Grid
//           container
//           spacing={1}
//           alignItems={"center"}
//           sx={{
//             marginBottom: 1,
//             marginTop: 1,
//             marginLeft: 2,
//             maxWidth: "96%",
//           }}
//         >
//           <Grid item xs={12} sm={3}>
//             <FormInput
//               size="small"
//               label="Search Brand Name"
//               name="searchBrandName"
//               value={searchBrandName}
//               onChange={(event) => setSearchBrandName(event.target.value)}
//             />
//           </Grid>

//           <Grid item xs={12} sm={3}>
//             <Button
//               component="label"
//               variant="contained"
//               tabIndex={-1}
//               startIcon={<SearchOutlined />}
//               onClick={() => {
//                 setIsReload(true);
//               }}
//               sx={{
//                 marginTop: 1,
//               }}
//             >
//               Search
//             </Button>
//           </Grid>
//         </Grid>

//         {/* table list */}
//         <TableList
//           tableStructure="brand-list"
//           tableName={"Brand List"}
//           isLoading={isLoading}
//           isShowPagination={true}
//           tableHeadList={tableHeadBrand}
//           data={brandList}
//           rowsPerPage={rowsPerPage}
//           page={page}
//           handleChangePage={handleChangePage}
//           handleChangeRowsPerPage={handleChangeRowsPerPage}
//           onEditData={handleEditItem}
//         />
//       </div>
//       <Modal
//         modalTitle={mode}
//         isVisible={isOpenModal}
//         buttonSaveTitle={"Save Brand"}
//         onPressCancel={() => {
//           setIsOpenModal(false);
//           setBrandNameInput("");
//         }}
//         onPressSave={() => handleSubmit()}
//       >
//         <FormInput
//           label="Brand Name"
//           name="brandNameInput"
//           error={!!errors.brandNameInput}
//           helperText={errors.brandNameInput}
//           value={brandNameInput}
//           onChange={(event) => setBrandNameInput(event.target.value)}
//         />
//       </Modal>

//       <CustomizedSnackbars
//         isOpen={isOpen}
//         typeInfo={alertType}
//         messageInfo={message}
//         handleCloseSnackbar={() => setIsOpen(false)}
//       />
//     </div>
//   );
// }

// export default Brands;
