import { useState, useEffect } from "react";
import api from "../../../helpers/api";

export const useCategoryData = (initialPage = 0, initialRowsPerPage = 10) => {
  // Loading states
  const [isLoading, setIsLoading] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);
  const [isReload, setIsReload] = useState(false);

  // Alert state
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");

  // Error state
  const [errors, setErrors] = useState({});

  // Table states
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("desc");

  // Pagination states
  const [page, setPage] = useState(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const [totalRecord, setTotalRecord] = useState(0);

  // Search state
  const [searchCategoryName, setSearchCategoryName] = useState("");

  // Data states
  const [categoryList, setCategoryList] = useState([]);
  const [categoryNameInput, setCategoryNameInput] = useState("");
  const [categorySelected, setCategorySelected] = useState([]);

  // Modal states
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [mode, setMode] = useState("");

  // Brand states (if needed)
  const [isBrandLoading, setIsBrandLoading] = useState(false);
  const [brandList, setBrandList] = useState([]);
  const [brandNameSelected, setBrandNameSelected] = useState([]);
  const [searchBrandName, setSearchBrandName] = useState("");

  /**
   * Fetch categories list with pagination and search
   */
  const onGetCategories = async () => {
    const currentPage = page + 1;
    setIsLoading(true);

    try {
      const res = await api.getCategories(
        searchCategoryName,
        rowsPerPage,
        currentPage
      );

      if (res.statusCode === 200) {
        setCategoryList(res.data.data);
        setTotalRecord(res.data.totalPage * res.data.limit);
        setIsLoading(false);
        setIsReload(false);
      } else {
        onError(res.message);
      }
    } catch (error) {
      onError(error.message);
    }
  };

  /**
   * Add new category
   */
  const onAddCategory = async () => {
    setIsLoading(true);

    try {
      const res = await api.saveCategory(
        categoryNameInput,
        brandNameSelected.id
      );

      if (res.statusCode === 200) {
        setIsOpenModal(false);
        onSuccessSubmit();
      } else {
        onError(res.message);
      }
    } catch (error) {
      onError(error.message);
    }
  };

  /**
   * Edit existing category
   */
  const onEditCategory = async () => {
    setIsLoading(true);

    try {
      const res = await api.editCategory(
        categorySelected.id,
        categoryNameInput,
        brandNameSelected.id
      );

      if (res.statusCode === 200) {
        setIsOpenModal(false);
        onSuccessSubmit();
      } else {
        onError(res.message);
      }
    } catch (error) {
      onError(error.message);
    }
  };

  /**
   * Delete category
   */
  const onDelete = async () => {
    setIsLoading(true);

    try {
      const res = await api.deleteCategory(categorySelected);
      setIsDeleteOpen(false);
      onSuccessSubmit();
    } catch (error) {
      onError(error.message);
    }
  };

  /**
   * Handle success response
   */
  const onSuccessSubmit = async () => {
    setErrors({});
    setCategorySelected([]);
    setCategoryNameInput("");
    setBrandNameSelected([]);
    setSearchBrandName("");
    setIsLoading(false);
    setIsDeleteOpen(false);
    setIsOpen(true);
    setAlertType("success");
    setMessage("Successfully...");
    setIsReload(true);
  };

  /**
   * Handle error response
   */
  const onError = async (message) => {
    setErrors({});
    setIsLoading(false);
    setIsDeleteOpen(false);
    setIsOpen(true);
    setAlertType("error");
    setMessage(message);
    setIsReload(false);
  };

  /**
   * Validate form before submission
   */
  const validateForm = () => {
    const newErrors = {};
    if (!categoryNameInput || categoryNameInput.trim() === "") {
      newErrors.categoryNameInput = "Category Name is required";
    }

    return newErrors;
  };

  /**
   * Handler for sorting
   */
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";

    const sortedData = [...categoryList].sort((a, b) => {
      // Use dynamic property access with bracket notation
      const valueA = a[property];
      const valueB = b[property];

      // Handle different types of sorting
      if (typeof valueA === "string") {
        return newOrder === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }

      return newOrder === "asc" ? valueA - valueB : valueB - valueA;
    });

    setCategoryList(sortedData);
    setOrder(newOrder);
    setOrderBy(property);
  };

  /**
   * Handle form submission
   */
  const handleSubmit = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    if (mode === "Add New Category") {
      await onAddCategory();
    } else if (mode === "Edit Category") {
      await onEditCategory();
    }
  };

  /**
   * Handle edit action
   */
  const handleEditItem = (itemSelected, indexSelected) => {
    setIsOpenModal(true);
    setMode("Edit Category");
    setCategorySelected(itemSelected);
    setCategoryNameInput(itemSelected.categoryName);
  };

  /**
   * Handle delete action
   */
  const handleDeleteItem = (itemSelected, indexSelected) => {
    setIsDeleteOpen(true);
    setCategorySelected(itemSelected);
  };

  // Effect to load categories when page or rowsPerPage changes
  useEffect(() => {
    const fetch = async () => {
      await onGetCategories();
    };
    fetch();
  }, [page, rowsPerPage]);

  // Effect to reload data when isReload is true
  useEffect(() => {
    if (isReload) {
      onGetCategories();
    }
  }, [isReload]);

  return {
    // States
    isLoading,
    isSpinner,
    isReload,
    isOpen,
    alertType,
    message,
    errors,
    page,
    rowsPerPage,
    totalRecord,
    orderBy,
    order,
    categoryList,
    searchCategoryName,
    categoryNameInput,
    categorySelected,
    isOpenModal,
    isDeleteOpen,
    mode,
    brandNameSelected,
    searchBrandName,

    // Setters
    setIsLoading,
    setIsSpinner,
    setIsReload,
    setIsOpen,
    setAlertType,
    setMessage,
    setErrors,
    setPage,
    setRowsPerPage,
    setOrderBy,
    setOrder,
    setCategoryList,
    setSearchCategoryName,
    setCategoryNameInput,
    setCategorySelected,
    setIsOpenModal,
    setIsDeleteOpen,
    setMode,
    setBrandNameSelected,
    setSearchBrandName,

    // Methods
    onGetCategories,
    onAddCategory,
    onEditCategory,
    onDelete,
    onSuccessSubmit,
    onError,
    validateForm,
    handleRequestSort,
    handleSubmit,
    handleEditItem,
    handleDeleteItem,
  };
};

export default useCategoryData;
