import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Paper,
  Typography,
  Grid,
  Button,
  Breadcrumbs,
  Link,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Card,
  CardContent,
  CircularProgress,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Spinner, CustomizedSnackbars } from "../../../../components";
import NumberFormat from "../../../../helpers/functions/numberFormat";
import { formatDate, formatQuantity } from "../../utils/formatters";

// Import hooks
import { useInventoryData } from "../../hooks/useInventoryData";

function InventoryDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const transactionFromState = location.state?.transaction;

  // Hooks
  const { isSpinner, fetchTransactionDetail } = useInventoryData();

  // Local state
  const [isLoading, setIsLoading] = useState(true);
  const [detailTransaction, setDetailTransaction] = useState(null);
  const [transaction, setTransaction] = useState(transactionFromState);

  // Alert state
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");

  // Load detail data
  useEffect(() => {
    const loadDetailData = async () => {
      setIsLoading(true);

      try {
        // If we already have the transaction ID from location state or from URL
        const transactionId = transactionFromState?.id || id;

        const detail = await fetchTransactionDetail(transactionId);

        if (detail) {
          setDetailTransaction(detail);
        } else {
          showError("Failed to load inventory transfer details");
        }
      } catch (error) {
        showError(error?.message || "An error occurred while loading data");
      } finally {
        setIsLoading(false);
      }
    };

    loadDetailData();
  }, [id]);

  // Utility functions
  const showError = (msg) => {
    setIsOpen(true);
    setAlertType("error");
    setMessage(msg);
  };

  // Get status color
  const getStatusColor = (statusName) => {
    switch (statusName) {
      case "Completed":
        return "success";
      case "Pending":
        return "warning";
      case "Cancelled":
        return "error";
      default:
        return "primary";
    }
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "70vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Spinner isShowSpinner={isSpinner} />
      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />

      <Paper
        sx={{
          p: 3,
          mb: 3,
          borderRadius: 1,
          boxShadow: 1,
        }}
      >
        {/* Header */}
        <Box
          sx={{
            mb: 3,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link
                color="inherit"
                onClick={() => navigate("/inventory")}
                sx={{ cursor: "pointer", textDecoration: "none" }}
              >
                Inventory
              </Link>
              <Typography color="text.primary">Transfer Detail</Typography>
            </Breadcrumbs>

            <Typography variant="h6" sx={{ mt: 1 }}>
              Transfer Stock #{transaction?.id || detailTransaction?.id || id}
            </Typography>
          </Box>

          <Button
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate("/inventory")}
          >
            Back to Inventory
          </Button>
        </Box>

        {detailTransaction ? (
          <>
            {/* Transfer Information */}
            <Card sx={{ mb: 4 }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h6" gutterBottom>
                      Transfer Information
                    </Typography>

                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <Typography variant="body2" color="text.secondary">
                          Transaction ID
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2" fontWeight="medium">
                          {transaction?.id || detailTransaction?.id || "-"}
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant="body2" color="text.secondary">
                          Date
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2" fontWeight="medium">
                          {formatDate(
                            transaction?.transactionDate ||
                              detailTransaction?.transactionDate
                          )}
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant="body2" color="text.secondary">
                          Status
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Chip
                          label={
                            transaction?.statusName ||
                            detailTransaction?.status ||
                            "Completed"
                          }
                          color={getStatusColor(
                            transaction?.statusName || detailTransaction?.status
                          )}
                          size="small"
                        />
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant="body2" color="text.secondary">
                          Total Items
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2" fontWeight="medium">
                          {NumberFormat(
                            0,
                            transaction?.item ||
                              detailTransaction?.totalItems ||
                              0
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography variant="h6" gutterBottom>
                      Branch Information
                    </Typography>

                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <Typography variant="body2" color="text.secondary">
                          From
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2" fontWeight="medium">
                          {transaction?.branchFromName ||
                            detailTransaction?.branchFromName ||
                            "-"}
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant="body2" color="text.secondary">
                          To
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2" fontWeight="medium">
                          {transaction?.branchToName ||
                            detailTransaction?.branchToName ||
                            "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            {/* Products Table */}
            <Typography variant="h6" sx={{ mb: 2 }}>
              Product Items
            </Typography>

            <TableContainer>
              <Table aria-label="inventory transfer detail table">
                <TableHead>
                  <TableRow>
                    <TableCell>No</TableCell>
                    <TableCell>Product Code</TableCell>
                    <TableCell>Product Name</TableCell>
                    <TableCell>Variant</TableCell>
                    <TableCell align="right">Transfer Quantity</TableCell>
                    <TableCell align="right">Price</TableCell>
                    <TableCell align="right">Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!detailTransaction.items ||
                  detailTransaction.items.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        No products available
                      </TableCell>
                    </TableRow>
                  ) : (
                    detailTransaction.items.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          {item.productCode || item.masterProductCode || "-"}
                        </TableCell>
                        <TableCell>{item.productName || "-"}</TableCell>
                        <TableCell>{item.variantName || "-"}</TableCell>
                        <TableCell align="right">
                          {NumberFormat(
                            0,
                            item.transferedQuantity || item.quantity || 0
                          )}
                        </TableCell>
                        <TableCell align="right">
                          Rp. {NumberFormat(0, item.basePrice || 0)}
                        </TableCell>
                        <TableCell align="right">
                          Rp.{" "}
                          {NumberFormat(
                            0,
                            (item.transferedQuantity || item.quantity || 0) *
                              (item.basePrice || 0)
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {/* Total Section */}
            <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
              <Card sx={{ minWidth: 300 }}>
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle2" fontWeight="bold">
                        Total Items:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "right" }}>
                      <Typography variant="subtitle2" fontWeight="bold">
                        {NumberFormat(0, detailTransaction.items?.length || 0)}{" "}
                        Items
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="subtitle2" fontWeight="bold">
                        Total Quantity:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "right" }}>
                      <Typography variant="subtitle2" fontWeight="bold">
                        {NumberFormat(
                          0,
                          detailTransaction.items?.reduce(
                            (total, item) =>
                              total +
                              (item.transferedQuantity || item.quantity || 0),
                            0
                          )
                        )}{" "}
                        pcs
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider sx={{ my: 1 }} />
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="subtitle2" fontWeight="bold">
                        Grand Total:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "right" }}>
                      <Typography variant="subtitle2" fontWeight="bold">
                        Rp.{" "}
                        {NumberFormat(
                          0,
                          detailTransaction.items?.reduce(
                            (total, item) =>
                              total +
                              (item.transferedQuantity || item.quantity || 0) *
                                (item.basePrice || 0),
                            0
                          )
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </>
        ) : (
          <Typography variant="body1" color="text.secondary">
            Transfer details not available
          </Typography>
        )}

        {/* Action Buttons */}
        <Box sx={{ mt: 4 }}>
          <Button
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate("/inventory")}
          >
            Back to Inventory
          </Button>
        </Box>
      </Paper>
    </>
  );
}

export default InventoryDetail;
