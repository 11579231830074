import React, { useContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { MainLayout } from "../components";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import MoveDownIcon from "@mui/icons-material/MoveDown";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import ReceiptIcon from "@mui/icons-material/Receipt";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";

import ClassOutlinedIcon from "@mui/icons-material/ClassOutlined";
import LocalShippingRoundedIcon from "@mui/icons-material/LocalShippingRounded";
import LiquorRoundedIcon from "@mui/icons-material/LiquorRounded";
import LocalConvenienceStoreRoundedIcon from "@mui/icons-material/LocalConvenienceStoreRounded";

import ShoppingBasketRoundedIcon from "@mui/icons-material/ShoppingBasketRounded";
import Groups3RoundedIcon from "@mui/icons-material/Groups3Rounded";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import TableViewIcon from "@mui/icons-material/TableView";
import {
  Dashboard,
  Products,
  Brands,
  Categories,
  AddCategory,
  EditCategory,
  Supplier,
  AddSupplier,
  EditSupplier,
  Inventory,
  Transaction,
  TransactionDetail,
  Purchases,
  Branches,
  AddBranches,
  EditBranches,
  Users,
  AddUser,
  EditUser,
  Balance,
  Income,
  Expenses,
  CurrentStock,
  DailyProvit,
  ProductProvit,
  ProductReceipt,
  StockIn,
  StockOut,
  StockOpname,
  StockCard,
  Login,
  AddProduct,
  EditProduct,
  SellingPrice,
  ProductDetail,
  AddStockIn,
  StockInDetail,
  AddStockOut,
  StockOutDetail,
  AddPurchase,
  PurchaseDetail,
  ProductReceiptDetail,
  AddStockOpname,
  StockOpnameDetail,
  AddInventoryTransfer,
  InventoryDetail,
  BalanceDetail,
} from "../pages";
import PrivateRoute from "../contexts/PrivateRoute";
import { getRole } from "../utils/auth";
import AuthContext from "../contexts/AuthProvider";

// Navigation configuration that matches MainLayout
const navigationConfig = {
  mainNavItems: [{ title: "Dashboard", path: "/" }],
  reportNavItems: [
    {
      title: "Balance",
      path: "/balance",
      icon: <TableViewIcon />,
    },
    { title: "Sales", path: "/transaction", icon: <BarChartOutlinedIcon /> },
    { title: "Daily Report", path: "/dailyProvit", icon: <TrendingUpIcon /> },
    {
      title: "Product Profit",
      path: "/productProvit",
      icon: <LocalMallIcon />,
    },
    {
      title: "Current Stock",
      path: "/currentStock",
      icon: <MoveToInboxIcon />,
    },
  ],
  inventoryNavItems: [
    { title: "Stock Card", path: "/stockCard", icon: <WarehouseIcon /> },
    {
      title: "All Products",
      path: "/products",
      icon: <LiquorRoundedIcon />,
    },
    {
      title: "Purchase Order",
      path: "/purchases",
      icon: <ShoppingBasketRoundedIcon />,
    },
    {
      title: "Product Receive",
      path: "/productReceipt",
      icon: <ReceiptIcon />,
    },
    { title: "Stock In", path: "/stockIn", icon: <MoveDownIcon /> },
    { title: "Stock Out", path: "/stockOut", icon: <MoveUpIcon /> },
    {
      title: "Transfer Stock",
      path: "/inventory",
      icon: <SwapHorizIcon />,
    },
    {
      title: "Stock Opname",
      path: "/stockOpname",
      icon: <ManageHistoryIcon />,
    },
  ],
  managementNavItems: [
    {
      title: "Categories",
      path: "/categories",
      icon: <ClassOutlinedIcon />,
    },
    {
      title: "Suppliers",
      path: "/supplier",
      icon: <LocalShippingRoundedIcon />,
    },
    {
      title: "Outlets",
      path: "/branches",
      icon: <LocalConvenienceStoreRoundedIcon />,
    },
    { title: "Users", path: "/users", icon: <Groups3RoundedIcon /> },
  ],
};

export function Routers() {
  // const userRole = getRole();
  const { authData } = useContext(AuthContext);
  const userRole = authData.role;
  return (
    <Router>
      <Routes>
        {/* Public Route */}
        <Route path="/login" element={<Login />} />

        {/* Protected Routes */}
        <Route
          path="/"
          element={
            <PrivateRoute>
              <MainLayout navigationConfig={navigationConfig} />
            </PrivateRoute>
          }
        >
          <Route
            index
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          {userRole === "admin" || userRole === "owner" ? (
            <>
              <Route
                path="/products"
                element={
                  <PrivateRoute>
                    <Products />
                  </PrivateRoute>
                }
              />

              <Route
                path="/products/add"
                element={
                  <PrivateRoute>
                    <AddProduct />
                  </PrivateRoute>
                }
              />

              <Route
                path="/products/edit/:id"
                element={
                  <PrivateRoute>
                    <EditProduct />
                  </PrivateRoute>
                }
              />

              <Route
                path="/products/selling-price"
                element={
                  <PrivateRoute>
                    <SellingPrice />
                  </PrivateRoute>
                }
              />

              <Route
                path="/products/detail/:id"
                element={
                  <PrivateRoute>
                    <ProductDetail />
                  </PrivateRoute>
                }
              />

              <Route
                path="/Brands"
                element={
                  <PrivateRoute>
                    <Brands />
                  </PrivateRoute>
                }
              />

              <Route
                path="/Categories"
                element={
                  <PrivateRoute>
                    <Categories />
                  </PrivateRoute>
                }
              />

              <Route
                path="/Categories/add"
                element={
                  <PrivateRoute>
                    <AddCategory />
                  </PrivateRoute>
                }
              />

              <Route
                path="/Categories/edit/:id"
                element={
                  <PrivateRoute>
                    <EditCategory />
                  </PrivateRoute>
                }
              />

              <Route
                path="/Supplier"
                element={
                  <PrivateRoute>
                    <Supplier />
                  </PrivateRoute>
                }
              />

              <Route
                path="/Supplier/add"
                element={
                  <PrivateRoute>
                    <AddSupplier />
                  </PrivateRoute>
                }
              />

              <Route
                path="/Supplier/edit/:id"
                element={
                  <PrivateRoute>
                    <EditSupplier />
                  </PrivateRoute>
                }
              />

              <Route
                path="/Inventory"
                element={
                  <PrivateRoute>
                    <Inventory />
                  </PrivateRoute>
                }
              />

              <Route
                path="/Inventory/add"
                element={
                  <PrivateRoute>
                    <AddInventoryTransfer />
                  </PrivateRoute>
                }
              />

              <Route
                path="/Inventory/detail/:id"
                element={
                  <PrivateRoute>
                    <InventoryDetail />
                  </PrivateRoute>
                }
              />

              <Route
                path="/Transaction"
                element={
                  <PrivateRoute>
                    <Transaction />
                  </PrivateRoute>
                }
              />

              <Route
                path="/Transaction/detail/:id"
                element={
                  <PrivateRoute>
                    <TransactionDetail />
                  </PrivateRoute>
                }
              />

              <Route
                path="/Purchases"
                element={
                  <PrivateRoute>
                    <Purchases />
                  </PrivateRoute>
                }
              />

              <Route
                path="/Purchases/add"
                element={
                  <PrivateRoute>
                    <AddPurchase />
                  </PrivateRoute>
                }
              />

              <Route
                path="/Purchases/detail/:id"
                element={
                  <PrivateRoute>
                    <PurchaseDetail />
                  </PrivateRoute>
                }
              />

              <Route
                path="/Branches"
                element={
                  <PrivateRoute>
                    <Branches />
                  </PrivateRoute>
                }
              />

              <Route
                path="/Branches/add"
                element={
                  <PrivateRoute>
                    <AddBranches />
                  </PrivateRoute>
                }
              />

              <Route
                path="/Branches/edit/:id"
                element={
                  <PrivateRoute>
                    <EditBranches />
                  </PrivateRoute>
                }
              />

              <Route
                path="/Users"
                element={
                  <PrivateRoute>
                    <Users />
                  </PrivateRoute>
                }
              />

              <Route
                path="/Users/add"
                element={
                  <PrivateRoute>
                    <AddUser />
                  </PrivateRoute>
                }
              />

              <Route
                path="/Users/edit/:id"
                element={
                  <PrivateRoute>
                    <EditUser />
                  </PrivateRoute>
                }
              />

              <Route
                path="/Balance"
                element={
                  <PrivateRoute>
                    <Balance />
                  </PrivateRoute>
                }
              />

              <Route
                path="/Balance/detail/:id"
                element={
                  <PrivateRoute>
                    <BalanceDetail />
                  </PrivateRoute>
                }
              />

              <Route
                path="/Income"
                element={
                  <PrivateRoute>
                    <Income />
                  </PrivateRoute>
                }
              />

              <Route
                path="/Expenses"
                element={
                  <PrivateRoute>
                    <Expenses />
                  </PrivateRoute>
                }
              />

              <Route
                path="/CurrentStock"
                element={
                  <PrivateRoute>
                    <CurrentStock />
                  </PrivateRoute>
                }
              />

              <Route
                path="/DailyProvit"
                element={
                  <PrivateRoute>
                    <DailyProvit />
                  </PrivateRoute>
                }
              />

              <Route
                path="/ProductProvit"
                element={
                  <PrivateRoute>
                    <ProductProvit />
                  </PrivateRoute>
                }
              />

              <Route
                path="/ProductReceipt"
                element={
                  <PrivateRoute>
                    <ProductReceipt />
                  </PrivateRoute>
                }
              />

              <Route
                path="/ProductReceipt/detail/:id"
                element={
                  <PrivateRoute>
                    <ProductReceiptDetail />
                  </PrivateRoute>
                }
              />

              <Route
                path="/StockIn"
                element={
                  <PrivateRoute>
                    <StockIn />
                  </PrivateRoute>
                }
              />

              <Route
                path="/StockIn/add"
                element={
                  <PrivateRoute>
                    <AddStockIn />
                  </PrivateRoute>
                }
              />

              <Route
                path="/StockIn/detail/:id"
                element={
                  <PrivateRoute>
                    <StockInDetail />
                  </PrivateRoute>
                }
              />

              <Route
                path="/StockOut"
                element={
                  <PrivateRoute>
                    <StockOut />
                  </PrivateRoute>
                }
              />

              <Route
                path="/StockOut/add"
                element={
                  <PrivateRoute>
                    <AddStockOut />
                  </PrivateRoute>
                }
              />

              <Route
                path="/StockOut/detail/:id"
                element={
                  <PrivateRoute>
                    <StockOutDetail />
                  </PrivateRoute>
                }
              />

              <Route
                path="/StockOpname"
                element={
                  <PrivateRoute>
                    <StockOpname />
                  </PrivateRoute>
                }
              />

              <Route
                path="/StockOpname/add"
                element={
                  <PrivateRoute>
                    <AddStockOpname />
                  </PrivateRoute>
                }
              />

              <Route
                path="/StockOpname/detail/:id"
                element={
                  <PrivateRoute>
                    <StockOpnameDetail />
                  </PrivateRoute>
                }
              />

              <Route
                path="/StockCard"
                element={
                  <PrivateRoute>
                    <StockCard />
                  </PrivateRoute>
                }
              />
            </>
          ) : null}
        </Route>
      </Routes>
    </Router>
  );
}
