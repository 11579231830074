/**
 * Format a number with thousand separator
 * @param {number|string} num - The number to format
 * @param {string} defaultValue - Default value if num is empty
 * @returns {string} Formatted number string
 */
export const formatNumber = (num, defaultValue = "") => {
  if (num === "" || num === null || num === undefined) return defaultValue;
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

/**
 * Format currency in Indonesian Rupiah format
 * @param {number|string} amount - The amount to format
 * @param {string} defaultValue - Default value if amount is empty
 * @returns {string} Formatted currency string
 */
export const formatCurrency = (amount, defaultValue = "Rp 0") => {
  if (amount === "" || amount === null || amount === undefined)
    return defaultValue;
  return `Rp ${formatNumber(amount)}`;
};

/**
 * Format date to Indonesian format
 * @param {string|Date} date - Date to format
 * @param {string} defaultValue - Default value if date is invalid
 * @returns {string} Formatted date string
 */
export const formatDate = (date, defaultValue = "-") => {
  if (!date) return defaultValue;

  try {
    const d = new Date(date);
    if (isNaN(d.getTime())) return defaultValue;

    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();

    return `${day}/${month}/${year}`;
  } catch (error) {
    return defaultValue;
  }
};

/**
 * Format date to YYYY-MM-DD format for API requests
 * @param {string|Date} date - Date to format
 * @param {string} defaultValue - Default value if date is invalid
 * @returns {string} Formatted date string for API
 */
export const formatDateForAPI = (date, defaultValue = "") => {
  if (!date) return defaultValue;

  try {
    const d = new Date(date);
    if (isNaN(d.getTime())) return defaultValue;

    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();

    return `${year}-${month}-${day}`;
  } catch (error) {
    return defaultValue;
  }
};

/**
 * Format purchase status code to readable text
 * @param {string|number} statusCode - Purchase status code from API
 * @returns {string} Readable status text
 */
export const formatPurchaseStatus = (statusCode) => {
  const statusMap = {
    10: "Draft",
    20: "Submitted",
    30: "Approved",
    40: "Processed",
    50: "Delivered",
    60: "Completed",
    70: "Cancelled",
    // Add more status codes as needed
  };

  return statusMap[statusCode] || "Unknown";
};

/**
 * Format payment status code to readable text
 * @param {string|number} statusCode - Payment status code from API
 * @returns {string} Readable status text
 */
export const formatPaymentStatus = (statusCode) => {
  const statusMap = {
    0: "Unpaid",
    1: "Partially Paid",
    2: "Paid",
    3: "Overpaid",
    // Add more status codes as needed
  };

  return statusMap[statusCode] || "Unknown";
};

/**
 * Get status color based on status code
 * @param {string|number} statusCode - Status code
 * @returns {string} Color code for the status
 */
export const getStatusColor = (statusCode) => {
  const colorMap = {
    10: "#f39c12", // Draft - Orange
    20: "#3498db", // Submitted - Blue
    30: "#2ecc71", // Approved - Green
    40: "#9b59b6", // Processed - Purple
    50: "#1abc9c", // Delivered - Teal
    60: "#27ae60", // Completed - Dark Green
    70: "#e74c3c", // Cancelled - Red
    // Payment status colors
    0: "#e74c3c", // Unpaid - Red
    1: "#f39c12", // Partially Paid - Orange
    2: "#2ecc71", // Paid - Green
    3: "#3498db", // Overpaid - Blue
  };

  return colorMap[statusCode] || "#95a5a6"; // Default: Gray
};

/**
 * Format quantity with unit
 * @param {number|string} quantity - Quantity value
 * @param {string} unit - Unit of measurement
 * @param {string} defaultValue - Default value if quantity is empty
 * @returns {string} Formatted quantity with unit
 */
export const formatQuantity = (quantity, unit = "pcs", defaultValue = "0") => {
  if (quantity === "" || quantity === null || quantity === undefined)
    return defaultValue + " " + unit;
  return `${formatNumber(quantity)} ${unit}`;
};

/**
 * Truncate text if it exceeds maximum length
 * @param {string} text - Text to truncate
 * @param {number} maxLength - Maximum length before truncating
 * @returns {string} Truncated text
 */
export const truncateText = (text, maxLength = 50) => {
  if (!text) return "";

  if (text.length <= maxLength) return text;

  return text.substring(0, maxLength) + "...";
};

/**
 * Format supplier code and name for display
 * @param {string} code - Supplier code
 * @param {string} name - Supplier name
 * @returns {string} Formatted supplier code and name
 */
export const formatSupplier = (code, name) => {
  if (!code && !name) return "-";
  if (!code) return name;
  if (!name) return code;

  return `${code} - ${name}`;
};

/**
 * Remove formatting from a formatted number (e.g., "1.000.000" -> 1000000)
 * @param {string} formattedNumber - Formatted number string
 * @returns {number} Number without formatting
 */
export const unformatNumber = (formattedNumber) => {
  if (!formattedNumber) return 0;

  // Remove non-digit characters except decimal point
  const numStr = formattedNumber.toString().replace(/[^\d.-]/g, "");
  const num = parseFloat(numStr);

  return isNaN(num) ? 0 : num;
};

export default {
  formatNumber,
  formatCurrency,
  formatDate,
  formatDateForAPI,
  formatPurchaseStatus,
  formatPaymentStatus,
  getStatusColor,
  formatQuantity,
  truncateText,
  formatSupplier,
  unformatNumber,
};
