// src/pages/stockout/hooks/useStockOutData.js
import { useState, useEffect } from "react";
import api from "../../../helpers/api";

export const useStockOutData = (initialPage = 0, initialRowsPerPage = 10) => {
  const [stockOutList, setStockOutList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isReload, setIsReload] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);
  const [branchCodeSelected, setBranchCodeSelected] = useState("");
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);
  const [page, setPage] = useState(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("desc");

  const getStockOutList = async () => {
    const currentPage = page + 1;
    setIsLoading(true);
    try {
      const res = await api.getStockOutData(
        branchCodeSelected,
        "", // searchProductName
        rowsPerPage,
        currentPage
      );

      if (res.statusCode === 200) {
        setStockOutList(res.data.data);
        setTotalRecord(res.data.totalPage * res.data.limit);
      } else {
        setStockOutList([]);
      }
    } catch (error) {
      setStockOutList([]);
      console.error("Error fetching stock out data:", error);
    } finally {
      setIsLoading(false);
      setIsReload(false);
    }
  };

  // Handle sorting
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";

    const sortedData = [...stockOutList].sort((a, b) => {
      const valueA = a[property];
      const valueB = b[property];

      if (typeof valueA === "string") {
        return newOrder === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }

      return newOrder === "asc" ? valueA - valueB : valueB - valueA;
    });

    setStockOutList(sortedData);
    setOrder(newOrder);
    setOrderBy(property);
  };

  // Load on page or rows per page change
  useEffect(() => {
    getStockOutList();
  }, [page, rowsPerPage]);

  // Reload when isReload is true
  useEffect(() => {
    if (isReload) {
      getStockOutList();
    }
  }, [isReload]);

  return {
    stockOutList,
    isLoading,
    totalRecord,
    branchCodeSelected,
    setBranchCodeSelected,
    selectedDateFrom,
    setSelectedDateFrom,
    selectedDateTo,
    setSelectedDateTo,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    orderBy,
    order,
    getStockOutList,
    setIsReload,
    handleRequestSort,
  };
};

export default useStockOutData;
