import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Paper,
  Typography,
  Grid,
  Button,
  Breadcrumbs,
  Link,
  TextField,
  FormHelperText,
  FormControl,
  Card,
  CardContent,
  Switch,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import { Spinner, CustomizedSnackbars } from "../../../../components";

// Import API directly
import api from "../../../../helpers/api";

function EditCategory() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const categoryFromState = location.state?.category;

  // Loading state
  const [isLoading, setIsLoading] = useState(!categoryFromState);
  const [isSpinner, setIsSpinner] = useState(false);

  // Form states
  const [categoryName, setCategoryName] = useState(
    categoryFromState?.categoryName || ""
  );
  const [categoryCode, setCategoryCode] = useState(
    categoryFromState?.categoryCode || ""
  );
  const [description, setDescription] = useState(
    categoryFromState?.description || ""
  );
  const [isActive, setIsActive] = useState(
    categoryFromState?.isActive !== false
  );
  const [errors, setErrors] = useState({});

  // Alert states
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");

  // Load category data if not available from state
  useEffect(() => {
    const loadCategoryData = async () => {
      if (!categoryFromState && id) {
        setIsLoading(true);
        try {
          // Fetch category by ID directly using API
          const response = await api.getCategoryById(id);

          if (response && response.statusCode === 200 && response.data) {
            const categoryData = response.data;
            setCategoryName(categoryData.categoryName || "");
            setCategoryCode(categoryData.categoryCode || "");
            setDescription(categoryData.description || "");
            setIsActive(categoryData.isActive !== false);
          } else {
            showError("Category not found");
            // Navigate back if category not found
            setTimeout(() => {
              navigate("/categories");
            }, 1000);
          }
        } catch (error) {
          showError(error?.message || "Failed to load category");
          setTimeout(() => {
            navigate("/categories");
          }, 1000);
        } finally {
          setIsLoading(false);
        }
      }
    };

    loadCategoryData();
  }, [id, navigate]);

  // Validate form function
  const validateCategoryForm = (formData) => {
    const errors = {};

    if (!formData.categoryName || formData.categoryName.trim() === "") {
      errors.categoryName = "Category Name is required";
    }

    if (!formData.categoryCode || formData.categoryCode.trim() === "") {
      errors.categoryCode = "Category Code is required";
    }

    return errors;
  };

  // Handle form submission
  const handleSubmit = async () => {
    // Validate form
    const formErrors = validateCategoryForm({
      categoryName,
      categoryCode,
    });

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    setIsSpinner(true);

    try {
      // Call API to update category
      const response = await api.editCategory(
        id,
        categoryName,
        categoryCode,
        description,
        isActive
      );

      if (response && response.statusCode === 200) {
        showSuccess("Category updated successfully");

        // Navigate back to categories list after short delay
        setTimeout(() => {
          navigate("/categories");
        }, 1000);
      } else {
        showError(response?.message || "Failed to update category");
      }
    } catch (error) {
      showError(error?.message || "An error occurred while updating category");
    } finally {
      setIsSpinner(false);
    }
  };

  // Utility functions
  const showError = (msg) => {
    setIsOpen(true);
    setAlertType("error");
    setMessage(msg);
  };

  const showSuccess = (msg) => {
    setIsOpen(true);
    setAlertType("success");
    setMessage(msg);
  };

  // Loading indicator
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "70vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Spinner isShowSpinner={isSpinner} />
      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />

      <Paper
        sx={{
          p: 3,
          mb: 3,
          borderRadius: 1,
          boxShadow: 1,
        }}
      >
        {/* Header */}
        <Box
          sx={{
            mb: 3,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link
                color="inherit"
                onClick={() => navigate("/categories")}
                sx={{ cursor: "pointer", textDecoration: "none" }}
              >
                Categories
              </Link>
              <Typography color="text.primary">Edit Category</Typography>
            </Breadcrumbs>

            <Typography variant="h6" sx={{ mt: 1 }}>
              Edit Category: {categoryName}
            </Typography>
          </Box>
        </Box>

        {/* Form Content */}
        <Card sx={{ mb: 4 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Category Information
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.categoryName}>
                  <TextField
                    label="Category Name"
                    variant="outlined"
                    value={categoryName}
                    onChange={(e) => {
                      setCategoryName(e.target.value);
                      // Clear error when input changes
                      if (errors.categoryName) {
                        setErrors((prev) => ({
                          ...prev,
                          categoryName: undefined,
                        }));
                      }
                    }}
                    fullWidth
                    required
                    error={!!errors.categoryName}
                  />
                  {errors.categoryName && (
                    <FormHelperText>{errors.categoryName}</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.categoryCode}>
                  <TextField
                    label="Category Code"
                    variant="outlined"
                    value={categoryCode}
                    onChange={(e) => {
                      setCategoryCode(e.target.value);
                      // Clear error when input changes
                      if (errors.categoryCode) {
                        setErrors((prev) => ({
                          ...prev,
                          categoryCode: undefined,
                        }));
                      }
                    }}
                    fullWidth
                    required
                    error={!!errors.categoryCode}
                  />
                  {errors.categoryCode && (
                    <FormHelperText>{errors.categoryCode}</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Description"
                  variant="outlined"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  fullWidth
                  multiline
                  rows={4}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isActive}
                      onChange={(e) => setIsActive(e.target.checked)}
                      color="primary"
                    />
                  }
                  label="Active Category"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        {/* Action Buttons */}
        <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate("/categories")}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={handleSubmit}
          >
            Update Category
          </Button>
        </Box>
      </Paper>
    </>
  );
}

export default EditCategory;
