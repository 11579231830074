// src/pages/products/edit/[id]/index.js
import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Paper,
  Typography,
  Button,
  Breadcrumbs,
  Link,
  CircularProgress,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import { Spinner, CustomizedSnackbars } from "../../../../components";
import ProductForm from "../../productComponents/ProductForm";
import { useCategories } from "../../hooks/useCategories";
import api from "../../../../helpers/api";
import { validateProductForm } from "../../utils/validation";

const EditProduct = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const productFromState = location.state?.product;

  const { categoryList, searchCategoryName, setSearchCategoryName } =
    useCategories();

  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    productNameInput: "",
    categoryNameSelected: null,
    brandName: "",
    note: "",
    variantList: [],
  });

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        // If we have product data from navigation state, use it
        if (productFromState) {
          await fetchProductVariants(productFromState);
        } else {
          // Otherwise fetch by ID
          const response = await api.getProduct("", id, 1, 1);
          if (response.statusCode === 200 && response.data.data.length > 0) {
            await fetchProductVariants(response.data.data[0]);
          } else {
            showError("Product not found");
            navigate("/products");
          }
        }
      } catch (error) {
        showError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchProductVariants = async (product) => {
      try {
        const variantsResponse = await api.getDetailProduct(
          product.masterProductCode
        );
        if (variantsResponse.statusCode === 200) {
          const selectedCategory = {
            id: product.categoryId,
            categoryName: product.categoryName,
          };

          setFormData({
            productNameInput: product.productName,
            categoryNameSelected: selectedCategory,
            brandName: product.brandName || "",
            note: product.note || "",
            variantList: variantsResponse.data.attachments || [],
          });
        }
      } catch (error) {
        showError("Failed to fetch product variants");
      }
    };

    fetchProductDetails();
  }, [id, productFromState]);

  const handleFormChange = (fieldName, value) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });

    // Clear validation error when field is updated
    if (errors[fieldName]) {
      setErrors({
        ...errors,
        [fieldName]: null,
      });
    }
  };

  const handleSaveProduct = async () => {
    // Validate form
    const validationErrors = validateProductForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setIsSaving(true);
    try {
      // Separate new variants and existing ones
      const existingVariants = formData.variantList.filter(
        (variant) => variant.productCode
      );
      const newVariants = formData.variantList.filter(
        (variant) => !variant.productCode
      );

      // First save new variants if any
      let allVariants = [...existingVariants];
      if (newVariants.length > 0) {
        const newVariantsResponse = await api.saveProductNewOnEdit(
          productFromState.masterProductCode,
          newVariants
        );

        if (newVariantsResponse.statusCode === 200) {
          allVariants = [...existingVariants, ...newVariantsResponse.data];
        } else {
          throw new Error("Failed to add new variants");
        }
      }

      // Then update the product with all variants
      const response = await api.editProduct(
        productFromState.id,
        formData.productNameInput,
        null, // brandId
        formData.brandName,
        formData.categoryNameSelected.id,
        formData.categoryNameSelected.categoryName,
        allVariants
      );

      if (response.statusCode === 200) {
        showSuccess("Product updated successfully");
        setTimeout(() => {
          navigate("/products");
        }, 300);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      showError(error.message);
    } finally {
      setIsSaving(false);
    }
  };

  const showError = (msg) => {
    setIsOpen(true);
    setAlertType("error");
    setMessage(msg);
  };

  const showSuccess = (msg) => {
    setIsOpen(true);
    setAlertType("success");
    setMessage(msg);
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "70vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Spinner isShowSpinner={isSaving} />
      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />

      <Paper
        sx={{
          p: 3,
          mb: 3,
          borderRadius: 1,
          boxShadow: 1,
        }}
      >
        {/* Header with breadcrumbs */}
        <Box sx={{ mb: 3 }}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link
              color="inherit"
              onClick={() => navigate("/products")}
              sx={{ cursor: "pointer", textDecoration: "none" }}
            >
              Products
            </Link>
            <Typography color="text.primary">Edit Product</Typography>
          </Breadcrumbs>

          <Typography variant="h6" sx={{ mt: 2 }}>
            Edit Product: {formData.productNameInput}
          </Typography>
        </Box>

        {/* Product Form */}
        <ProductForm
          formData={formData}
          onChange={handleFormChange}
          categoryList={categoryList}
          searchCategoryName={searchCategoryName}
          setSearchCategoryName={setSearchCategoryName}
          errors={errors}
          isEditMode={true}
        />

        {/* Action Buttons */}
        <Box sx={{ mt: 4, display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate("/products")}
          >
            Back to Products
          </Button>

          <Button
            variant="contained"
            startIcon={<SaveIcon />}
            onClick={handleSaveProduct}
          >
            Save Changes
          </Button>
        </Box>
      </Paper>
    </>
  );
};

export default EditProduct;
