// src/pages/purchases/add/index.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Paper,
  Typography,
  Grid,
  Button,
  Breadcrumbs,
  Link,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Spinner,
  CustomizedSnackbars,
  FormDatePicker,
  FormInput,
  PickerSearch,
} from "../../../components";
import { useBranches } from "../hooks/useBranches";
import { useSuppliers } from "../hooks/useSuppliers";
import ProductTable from "../PurchaseComponent/ProductTable";
import { validatePurchaseForm } from "../utils/validation";
import api from "../../../helpers/api";
import moment from "moment";
import NumberFormat from "../../../helpers/functions/numberFormat";

function AddPurchase() {
  const navigate = useNavigate();
  const { branchList, searchBranchName, setSearchBranchName } = useBranches();
  const { supplierList, searchSupplierName, setSearchSupplierName } =
    useSuppliers();

  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});

  // Form state
  const [branchNameSelected, setBranchNameSelected] = useState(null);
  const [supplierNameSelected, setSupplierNameSelected] = useState(null);
  const [datePurchase, setDatePurchase] = useState(null);
  const [notes, setNotes] = useState("");
  const [productOrders, setProductOrders] = useState([]);

  // Additional state needed for purchase order
  const [additional, setAdditional] = useState({
    serviceCharge: "0",
    taxCharge: "0",
    taxResto: "0",
    cashPayment: 0,
    edcPayment: 0,
    rounding: 0,
    netPayment: 0,
    discount: 0,
    additionalCost: 0,
    paymentType: "Cash",
    paymentTypeCode: 456,
    transactionType: "PURCHASE ORDER",
    transactionTypeCode: 10,
  });

  const handleAddProduct = () => {
    setProductOrders([
      ...productOrders,
      {
        note: "",
        brandId: 0,
        masterProductCode: "",
        variantName: "",
        imageUrl: "",
        netPrice: 0,
        quantity: 0,
        trxPrice: 0,
        brandName: "",
        productId: "",
        categoryId: 0,
        productSku: "",
        productCode: "",
        productName: "",
        categoryName: "",
        updatedQuantity: 0,
        defaultTrxPrice: "",
      },
    ]);
  };

  const handleRemoveProduct = (index) => {
    const newArray = [...productOrders];
    newArray.splice(index, 1);
    setProductOrders(newArray);
  };

  const calculateGrandTotal = () => {
    const total = productOrders.reduce(
      (accumulator, currentValue) => accumulator + (currentValue.netPrice || 0),
      0
    );
    return total;
  };

  const handleSavePurchase = async () => {
    // // Validate form
    const validationErrors = validatePurchaseForm({
      supplierNameSelected,
      branchNameSelected,
      datePurchase,
      productOrders,
    });

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setIsLoading(true);
    try {
      const formattedDate = moment(datePurchase).format("YYYY-MM-DD");
      const sumTotal = calculateGrandTotal();
      const response = await api.savePurchaseOrder(
        formattedDate,
        productOrders.length,
        notes,
        additional.taxCharge,
        additional.discount,
        additional.rounding,
        additional.taxResto,
        additional.edcPayment,
        sumTotal,
        sumTotal,
        additional.paymentType,
        additional.serviceCharge,
        additional.additionalCost,
        additional.paymentTypeCode,
        additional.transactionType,
        branchNameSelected.branchCode,
        branchNameSelected.branchName,
        supplierNameSelected.supplierCode,
        supplierNameSelected.supplierName,
        additional.transactionTypeCode,
        productOrders
      );

      if (response.statusCode === 200) {
        showSuccess("Purchase order saved successfully");
        // Navigate back after short delay
        setTimeout(() => {
          navigate("/purchases");
        }, 300);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      showError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const showError = (msg) => {
    setIsOpen(true);
    setAlertType("error");
    setMessage(msg);
  };

  const showSuccess = (msg) => {
    setIsOpen(true);
    setAlertType("success");
    setMessage(msg);
  };

  return (
    <>
      <Spinner isShowSpinner={isLoading} />
      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />

      <Paper
        sx={{
          p: 3,
          mb: 3,
          borderRadius: 1,
          boxShadow: 1,
        }}
      >
        {/* Header with breadcrumbs */}
        <Box sx={{ mb: 3 }}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link
              color="inherit"
              onClick={() => navigate("/purchases")}
              sx={{ cursor: "pointer", textDecoration: "none" }}
            >
              Purchases
            </Link>
            <Typography color="text.primary">Add New Purchase Order</Typography>
          </Breadcrumbs>

          <Typography variant="h6" sx={{ mt: 2 }}>
            Add New Purchase Order
          </Typography>
        </Box>

        {/* Purchase Form */}
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <PickerSearch
              value={supplierNameSelected}
              dataList={supplierList}
              onInputChange={(event, newInputValue) => {
                setSearchSupplierName(newInputValue);
              }}
              onChange={(e, v) => {
                setSupplierNameSelected(v);
                if (errors.supplierNameSelected) {
                  setErrors({ ...errors, supplierNameSelected: null });
                }
              }}
              getOptionLabel={(supplier) => supplier?.supplierName || ""}
              labelPicker="Choose Supplier"
              placeholderPicker="search supplier"
              sizePicker="large"
              isVisibleLabelPicker={true}
              error={!!errors.supplierNameSelected}
              helperText={errors.supplierNameSelected}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <PickerSearch
              value={branchNameSelected}
              dataList={branchList}
              onInputChange={(event, newInputValue) => {
                setSearchBranchName(newInputValue);
              }}
              onChange={(e, v) => {
                setBranchNameSelected(v);
                setProductOrders([]);
                if (errors.branchNameSelected) {
                  setErrors({ ...errors, branchNameSelected: null });
                }
              }}
              getOptionLabel={(branch) => branch?.branchName || ""}
              labelPicker="Choose Branch / Outlet"
              placeholderPicker="search branch / outlet"
              sizePicker="large"
              isVisibleLabelPicker={true}
              error={!!errors.branchNameSelected}
              helperText={errors.branchNameSelected}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormDatePicker
              labelFormDatePicker="Date Purchase Order"
              formSize="large"
              dateValue={datePurchase}
              onChangeDate={(val) => {
                setDatePurchase(val);
                if (errors.datePurchase) {
                  setErrors({ ...errors, datePurchase: null });
                }
              }}
              error={!!errors.datePurchase}
              helperText={errors.datePurchase}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormInput
              label="Note"
              name="notes"
              multiline
              rows={3}
              value={notes}
              onChange={(event) => setNotes(event.target.value)}
            />
          </Grid>
        </Grid>

        {/* Products Section */}
        <Box sx={{ mt: 4 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <Typography variant="h6">Products</Typography>
            <Button
              variant="outlined"
              startIcon={<AddCircleIcon />}
              onClick={handleAddProduct}
            >
              Add Product
            </Button>
          </Box>

          <ProductTable
            branchCode={branchNameSelected?.branchCode || ""}
            productOrders={productOrders}
            setProductOrders={setProductOrders}
            onRemoveProduct={handleRemoveProduct}
            errors={errors}
          />
        </Box>

        {/* Action Buttons */}
        <Box
          sx={{
            mt: 4,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
              Grand Total
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 700,
                fontSize: "1.3rem",
                color: "primary.main",
              }}
            >
              Rp. {NumberFormat(0, calculateGrandTotal())}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", gap: 2 }}>
            <Button
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate("/purchases")}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              startIcon={<SaveIcon />}
              onClick={handleSavePurchase}
            >
              Save Purchase Order
            </Button>
          </Box>
        </Box>
      </Paper>
    </>
  );
}

export default AddPurchase;
