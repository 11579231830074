import { useState, useEffect } from "react";
import api from "../../../helpers/api";

/**
 * Hook untuk mengelola data cabang/outlet dan pencarian
 * @param {string} initialSearchTerm - Kata kunci pencarian awal
 * @returns {Object} Data cabang dan metode terkait
 */
export const useBranches = (initialSearchTerm = "") => {
  const [branchList, setBranchList] = useState([]);
  const [branchSelected, setBranchSelected] = useState(null);
  const [searchBranchName, setSearchBranchName] = useState(initialSearchTerm);
  const [branchCodeSelected, setBranchCodeSelected] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  /**
   * Mengambil daftar cabang berdasarkan kata kunci pencarian
   */
  useEffect(() => {
    const fetchBranches = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const res = await api.getBranch(searchBranchName);

        if (res.statusCode === 200) {
          // Add "All Branches" option
          const newBranch = {
            id: "",
            branchName: "All Branches",
            address: "",
            branchPhone: "",
            statusName: "",
            statusCode: "",
            branchCode: "",
            createdDate: "",
            updatedDate: "",
          };
          const updatedBranchList = [...res.data.data];
          setBranchList(updatedBranchList);
        } else {
          setBranchList([]);
          setError(res.message);
        }
      } catch (error) {
        setBranchList([]);
        setError(error.message || "Failed to fetch branches");
      } finally {
        setIsLoading(false);
      }
    };

    fetchBranches();
  }, [searchBranchName]);

  /**
   * Memilih cabang dan menyimpan kode cabangnya
   * @param {Object} branch - Data cabang yang dipilih
   */
  const selectBranch = (branch) => {
    setBranchSelected(branch);
    setBranchCodeSelected(branch ? branch.branchCode : "");
  };

  /**
   * Reset pilihan cabang
   */
  const resetBranchSelection = () => {
    setBranchSelected(null);
    setBranchCodeSelected("");
    setSearchBranchName("");
  };

  return {
    branchList,
    branchSelected,
    branchCodeSelected,
    searchBranchName,
    isLoading,
    error,

    // Setters
    setBranchSelected,
    setBranchCodeSelected,
    setSearchBranchName,

    // Methods
    selectBranch,
    resetBranchSelection,
  };
};

export default useBranches;
