import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Paper,
  Typography,
  Grid,
  Button,
  Breadcrumbs,
  Link,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  CardContent,
  CircularProgress,
  Chip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { CustomizedSnackbars } from "../../../../components";
import api from "../../../../helpers/api";
import moment from "moment";
import NumberFormat from "../../../../helpers/functions/numberFormat";

function TransactionDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const transactionFromState = location.state?.transaction;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isExtraSmall = useMediaQuery("(max-width:480px)");

  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");
  const [detailTransaction, setDetailTransaction] = useState(null);

  useEffect(() => {
    const fetchTransactionDetail = async () => {
      try {
        // If we already have the transaction ID from location state
        const transactionId = transactionFromState?.id || id;

        // Direct API call like in StockInDetail
        const response = await api.getDetailTransaction(transactionId);

        if (response.statusCode === 200) {
          console.log("API Response:", response.data);
          setDetailTransaction(response.data);
        } else {
          showError(response.message || "Failed to load transaction details");
        }
      } catch (error) {
        console.error("Error fetching transaction detail:", error);
        showError(error.message || "An error occurred");
      } finally {
        setIsLoading(false);
      }
    };

    fetchTransactionDetail();
  }, [id, transactionFromState]);

  const showError = (msg) => {
    setIsOpen(true);
    setAlertType("error");
    setMessage(msg);
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "70vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />

      <Paper
        sx={{
          p: { xs: 2, sm: 3 },
          mb: 3,
          borderRadius: 1,
          boxShadow: 1,
        }}
      >
        {/* Header with breadcrumbs */}
        <Box
          sx={{
            mb: 3,
            display: "flex",
            flexDirection: isExtraSmall ? "column" : "row",
            justifyContent: "space-between",
            alignItems: isExtraSmall ? "flex-start" : "center",
            gap: isExtraSmall ? 2 : 0,
          }}
        >
          <Box sx={{ width: isExtraSmall ? "100%" : "auto" }}>
            <Breadcrumbs
              separator="›"
              aria-label="breadcrumb"
              sx={{
                fontSize: { xs: "0.75rem", sm: "0.875rem" },
              }}
            >
              <Link
                color="inherit"
                onClick={() => navigate("/transaction")}
                sx={{ cursor: "pointer", textDecoration: "none" }}
              >
                Sales
              </Link>
              <Typography color="text.primary" sx={{ fontSize: "inherit" }}>
                Detail Sales
              </Typography>
            </Breadcrumbs>

            <Typography
              variant={isMobile ? "subtitle1" : "h6"}
              sx={{ mt: 1 }}
              noWrap={!isExtraSmall}
            >
              Sales Detail #{detailTransaction?.transactionNo || id}
            </Typography>
          </Box>
        </Box>

        {detailTransaction ? (
          <>
            {/* Transaction Information */}
            <Card sx={{ mb: 4 }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h6" gutterBottom>
                      Transaction Information
                    </Typography>

                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <Typography variant="body2" color="text.secondary">
                          Transaction No
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2" fontWeight="medium">
                          {detailTransaction.transactionNo || "-"}
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant="body2" color="text.secondary">
                          Date
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2" fontWeight="medium">
                          {detailTransaction.transactionDate
                            ? moment(detailTransaction.transactionDate).format(
                                "DD MMM YYYY, HH:mm"
                              )
                            : "N/A"}
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant="body2" color="text.secondary">
                          Amount
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                          }}
                        >
                          <Typography
                            variant="body2"
                            fontWeight="medium"
                            color="primary.main"
                          >
                            Rp.{" "}
                            {NumberFormat(
                              0,
                              detailTransaction?.netPayment || 0
                            )}
                          </Typography>
                          {detailTransaction?.statusName && (
                            <Chip
                              label={detailTransaction.statusName}
                              color={
                                detailTransaction.statusName === "Done"
                                  ? "success"
                                  : "primary"
                              }
                              size="small"
                            />
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography variant="h6" gutterBottom>
                      Transaction Details
                    </Typography>

                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <Typography variant="body2" color="text.secondary">
                          Transaction From
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2" fontWeight="medium">
                          {detailTransaction.transactionFromName || "-"}
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant="body2" color="text.secondary">
                          Transaction To
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2" fontWeight="medium">
                          {detailTransaction.transactionToName || "-"}
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant="body2" color="text.secondary">
                          Transaction Type
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2" fontWeight="medium">
                          {detailTransaction.transactionType || "-"}
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant="body2" color="text.secondary">
                          Items
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2" fontWeight="medium">
                          {`${NumberFormat(
                            0,
                            detailTransaction?.item || 0
                          )} Items`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            {/* Product List Table */}
            {detailTransaction.attachments &&
              detailTransaction.attachments.length > 0 && (
                <>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Items
                  </Typography>

                  <TableContainer>
                    <Table
                      aria-label="transaction product table"
                      size={isMobile ? "small" : "medium"}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>No</TableCell>
                          <TableCell>Product Name</TableCell>
                          <TableCell>Product Variant</TableCell>
                          <TableCell>Category</TableCell>
                          <TableCell>Quantity</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {detailTransaction.attachments.map(
                          (itemData, index) => (
                            <TableRow key={itemData.id || index}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>
                                {itemData.productName || "-"}
                              </TableCell>
                              <TableCell>
                                {itemData.variantName || "-"}
                              </TableCell>
                              <TableCell>
                                {itemData.categoryName || "-"}
                              </TableCell>
                              <TableCell>
                                {`${NumberFormat(
                                  0,
                                  itemData.quantity || 0
                                )} pcs`}
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  {/* Total Section */}
                  <Box
                    sx={{ mt: 4, display: "flex", justifyContent: "flex-end" }}
                  >
                    <Card sx={{ minWidth: 300 }}>
                      <CardContent>
                        <Grid container spacing={1}>
                          <Grid item xs={6}>
                            <Typography variant="body2" fontWeight="bold">
                              Total Items:
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sx={{ textAlign: "right" }}>
                            <Typography variant="body2" fontWeight="bold">
                              {detailTransaction.attachments.length} products
                            </Typography>
                          </Grid>

                          <Grid item xs={6}>
                            <Typography variant="body2" fontWeight="bold">
                              Total Quantity:
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sx={{ textAlign: "right" }}>
                            <Typography variant="body2" fontWeight="bold">
                              {NumberFormat(
                                0,
                                detailTransaction.attachments.reduce(
                                  (total, item) => total + (item.quantity || 0),
                                  0
                                )
                              )}{" "}
                              pcs
                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <Divider sx={{ my: 1 }} />
                          </Grid>

                          <Grid item xs={6}>
                            <Typography variant="body2" fontWeight="bold">
                              Total Amount:
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sx={{ textAlign: "right" }}>
                            <Typography variant="body2" fontWeight="bold">
                              Rp.{" "}
                              {NumberFormat(
                                0,
                                detailTransaction?.netPayment || 0
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Box>
                </>
              )}

            {/* Action Buttons */}
            <Box sx={{ mt: 4 }}>
              <Button
                variant="outlined"
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate("/transaction")}
              >
                Back to Sales
              </Button>
            </Box>
          </>
        ) : (
          <Typography variant="body1" color="text.secondary">
            Transaction details not available
          </Typography>
        )}
      </Paper>
    </>
  );
}

export default TransactionDetail;
