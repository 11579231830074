import { useState, useEffect } from "react";
import api from "../../../helpers/api";

export const useSupplierData = (initialPage = 0, initialRowsPerPage = 10) => {
  // Loading states
  const [isLoading, setIsLoading] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);
  const [isReload, setIsReload] = useState(false);

  // Error state
  const [error, setError] = useState(null);

  // Table states
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("desc");

  // Pagination states
  const [page, setPage] = useState(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const [totalRecord, setTotalRecord] = useState(0);

  // Search state
  const [searchSupplierName, setSearchSupplierName] = useState("");

  // Data states
  const [supplierList, setSupplierList] = useState([]);

  /**
   * Fetch suppliers list with pagination and search
   */
  const fetchSuppliers = async () => {
    const currentPage = page + 1;
    setIsLoading(true);
    setError(null);

    try {
      const res = await api.getSupplier(
        searchSupplierName,
        rowsPerPage,
        currentPage
      );

      if (res.statusCode === 200) {
        setSupplierList(res.data.data);
        setTotalRecord(res.data.totalPage * res.data.limit);
      } else {
        setError(res.message);
        setSupplierList([]);
      }
    } catch (error) {
      setError(error.message || "Failed to fetch suppliers");
      setSupplierList([]);
    } finally {
      setIsLoading(false);
      setIsReload(false);
    }
  };

  /**
   * Get supplier by ID
   * @param {string|number} id - Supplier ID
   * @returns {Object|null} - Supplier data or null if not found
   */
  const getSupplierById = async (id) => {
    setIsSpinner(true);
    setError(null);

    try {
      const res = await api.getSupplierById(id);

      if (res.statusCode === 200) {
        return res.data;
      } else {
        setError(res.message);
        return null;
      }
    } catch (error) {
      setError(error.message || "Failed to fetch supplier");
      return null;
    } finally {
      setIsSpinner(false);
    }
  };

  /**
   * Add new supplier
   * @param {Object} supplierData - Supplier data
   * @returns {Object} - Result object { success, error, data }
   */
  const addSupplier = async (supplierData) => {
    const {
      supplierName,
      supplierContacName,
      supplierPhone,
      supplierEmail,
      supplierAddress,
    } = supplierData;

    setError(null);
    setIsSpinner(true);

    try {
      const res = await api.saveSupplier(
        supplierName,
        supplierContacName,
        supplierPhone,
        supplierEmail,
        supplierAddress
      );

      if (res.statusCode === 200) {
        setIsReload(true);
        return { success: true, data: res.data };
      } else {
        setError(res.message);
        return { success: false, error: res.message };
      }
    } catch (error) {
      setError(error.message || "Failed to add supplier");
      return { success: false, error: error.message };
    } finally {
      setIsSpinner(false);
    }
  };

  /**
   * Update supplier
   * @param {string|number} id - Supplier ID
   * @param {Object} supplierData - Updated supplier data
   * @returns {Object} - Result object { success, error, data }
   */
  const updateSupplier = async (id, supplierData) => {
    const {
      supplierName,
      supplierContacName,
      supplierPhone,
      supplierEmail,
      supplierAddress,
    } = supplierData;

    setError(null);
    setIsSpinner(true);

    try {
      const res = await api.editSupplier(
        id,
        supplierName,
        supplierContacName,
        supplierPhone,
        supplierEmail,
        supplierAddress
      );

      if (res.statusCode === 200) {
        setIsReload(true);
        return { success: true, data: res.data };
      } else {
        setError(res.message);
        return { success: false, error: res.message };
      }
    } catch (error) {
      setError(error.message || "Failed to update supplier");
      return { success: false, error: error.message };
    } finally {
      setIsSpinner(false);
    }
  };

  /**
   * Delete supplier
   * @param {Object} supplier - Supplier object to delete
   * @returns {Object} - Result object { success, error }
   */
  const deleteSupplier = async (supplier) => {
    setError(null);
    setIsLoading(true);

    try {
      const res = await api.deleteSupplier(supplier);
      setIsReload(true);
      return { success: true };
    } catch (error) {
      setError(error.message || "Failed to delete supplier");
      return { success: false, error: error.message };
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Handler for sorting
   * @param {string} property - Property to sort by
   */
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";

    const sortedData = [...supplierList].sort((a, b) => {
      // Use dynamic property access with bracket notation
      const valueA = a[property];
      const valueB = b[property];

      // Handle different types of sorting
      if (typeof valueA === "string") {
        return newOrder === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }

      return newOrder === "asc" ? valueA - valueB : valueB - valueA;
    });

    setSupplierList(sortedData);
    setOrder(newOrder);
    setOrderBy(property);
  };

  // Effect to load suppliers when dependencies change
  useEffect(() => {
    fetchSuppliers();
  }, [page, rowsPerPage]);

  // Effect to reload data when isReload changes
  useEffect(() => {
    if (isReload) {
      fetchSuppliers();
    }
  }, [isReload]);

  return {
    // States
    isLoading,
    isSpinner,
    error,
    supplierList,
    page,
    rowsPerPage,
    totalRecord,
    orderBy,
    order,
    searchSupplierName,

    // Setters
    setIsLoading,
    setIsSpinner,
    setError,
    setPage,
    setRowsPerPage,
    setOrderBy,
    setOrder,
    setSearchSupplierName,
    setIsReload,

    // API Methods
    fetchSuppliers,
    getSupplierById,
    addSupplier,
    updateSupplier,
    deleteSupplier,

    // Helpers
    handleRequestSort,
  };
};

export default useSupplierData;
