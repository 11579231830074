import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { TableList, Spinner, FormInput, PopupDelete } from "../../components";
import CustomizedSnackbars from "../../components/base/Snackbar";
import { tableHeadSupplier } from "../../utils/Constants";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Stack } from "@mui/material";

// Import custom hook
import { useSupplierData } from "./hooks/useSupplierData";

function Supplier() {
  const navigate = useNavigate();

  // Use custom hook for supplier data management
  const {
    isLoading,
    isSpinner,
    supplierList,
    searchSupplierName,
    setSearchSupplierName,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    totalRecord,
    orderBy,
    order,
    deleteSupplier,
    handleRequestSort,
    setIsReload,
  } = useSupplierData();

  // Local state
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [supplierSelected, setSupplierSelected] = useState({});

  // Handler untuk navigasi ke halaman tambah supplier
  const handleAddSupplier = () => {
    navigate("/supplier/add");
  };

  // Handler untuk navigasi ke halaman edit supplier
  const handleEditSupplier = (supplier) => {
    navigate(`/supplier/edit/${supplier.id}`, { state: { supplier } });
  };

  // Handler untuk konfirmasi hapus supplier
  const handleDeleteSupplier = (supplier) => {
    setSupplierSelected(supplier);
    setIsDeleteOpen(true);
  };

  // Handler untuk hapus supplier
  const handleDeleteConfirm = async () => {
    try {
      const result = await deleteSupplier(supplierSelected);

      if (result.success) {
        showSuccess("Supplier deleted successfully");
      } else {
        showError(result.error || "Failed to delete supplier");
      }
    } catch (error) {
      showError(error.message || "An error occurred");
    } finally {
      setIsDeleteOpen(false);
    }
  };

  // Utility functions
  const showSuccess = (msg) => {
    setIsOpen(true);
    setAlertType("success");
    setMessage(msg);
  };

  const showError = (msg) => {
    setIsOpen(true);
    setAlertType("error");
    setMessage(msg);
  };

  return (
    <>
      <Spinner isShowSpinner={isSpinner} />
      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />

      <Box
        sx={{
          width: "100%",
          backgroundColor: "white",
          borderRadius: 1,
          border: "1px solid",
          borderColor: "divider",
        }}
      >
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            alignItems: "center",
            p: 3,
            borderBottom: "1px solid",
            borderColor: "divider",
          }}
        >
          <Typography variant="h6" sx={{ mb: { xs: 2, sm: 0 } }}>
            Supplier List
          </Typography>

          <Button
            variant="outlined"
            startIcon={<AddCircleIcon />}
            onClick={handleAddSupplier}
          >
            Add New Supplier
          </Button>
        </Box>

        {/* Search Section */}
        <Box sx={{ p: 3, borderBottom: "1px solid", borderColor: "divider" }}>
          <Grid
            container
            spacing={2}
            sx={{
              alignItems: "center",
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{
                px: 3,
                mb: 1,
                alignItems: "center",
              }}
            >
              <Grid item xs={12} sm={3}>
                <Stack spacing={1}>
                  <Typography variant="caption" sx={{ fontWeight: 600 }}>
                    SUPPLIER NAME
                  </Typography>
                  <FormInput
                    size="small"
                    label="Search Supplier Name"
                    name="searchSupplierName"
                    value={searchSupplierName}
                    onChange={(event) =>
                      setSearchSupplierName(event.target.value)
                    }
                  />
                </Stack>
              </Grid>

              <Grid
                item
                xs={12}
                sm={3}
                sx={{
                  display: "flex",
                  alignItems: "end",
                  height: "100%",
                }}
              >
                <Button
                  variant="contained"
                  startIcon={<SearchIcon />}
                  onClick={() => {
                    setPage(0);
                    setIsReload(true);
                  }}
                  sx={{
                    width: "150px",
                    mt: { xs: 1, sm: 1, md: 3, lg: 3 },
                  }}
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        {/* Table List */}
        <TableList
          tableStructure="supplier-list"
          tableName={"Supplier List"}
          orderBy={orderBy}
          order={order}
          isLoading={isLoading}
          isShowPagination={true}
          tableHeadList={tableHeadSupplier}
          data={supplierList}
          rowsPerPage={rowsPerPage}
          page={page}
          totalRecord={totalRecord}
          handleChangePage={(event, newPage) => setPage(newPage)}
          handleChangeRowsPerPage={(event) => {
            setRowsPerPage(+event.target.value);
            setPage(0);
          }}
          onEditData={handleEditSupplier}
          handleRequestSort={handleRequestSort}
          onDeleteData={handleDeleteSupplier}
        />
      </Box>

      <PopupDelete
        itemName={supplierSelected.supplierName}
        visible={isDeleteOpen}
        onClose={() => setIsDeleteOpen(false)}
        onCancel={() => setIsDeleteOpen(false)}
        onDelete={handleDeleteConfirm}
      />
    </>
  );
}

export default Supplier;
