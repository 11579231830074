import { useState, useEffect, useCallback } from "react";
import api from "../../../helpers/api";

const useUserData = (initialPage = 0, initialRowsPerPage = 10) => {
  // Loading states
  const [isLoading, setIsLoading] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);
  const [isReload, setIsReload] = useState(false);

  // Error state
  const [error, setError] = useState(null);

  // Table states
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("desc");

  // Pagination states
  const [page, setPage] = useState(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const [totalRecord, setTotalRecord] = useState(0);

  // Search states
  const [searchFullname, setSearchFullname] = useState("");
  const [searchUsername, setSearchUsername] = useState("");
  const [searchBranchName, setSearchBranchName] = useState("");
  const [selectedBranchName, setSelectedBranchName] = useState("");

  // Data states
  const [userList, setUserList] = useState([]);
  const [branchList, setBranchList] = useState([]);

  // Auth state
  const [hasAuth, setHasAuth] = useState(false);

  /**
   * Fetch users list with pagination and search
   */
  const fetchUsers = async () => {
    const currentPage = page + 1;
    setIsLoading(true);
    setError(null);

    try {
      const res = await api.getUsers(
        searchFullname,
        searchUsername,
        selectedBranchName,
        rowsPerPage,
        currentPage
      );

      if (res.statusCode === 200) {
        setUserList(res.data.data);
        setTotalRecord(res.data.totalPage * res.data.limit);
      } else {
        setError(res.message);
        setUserList([]);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      setError(error.message || "Failed to fetch users");
      setUserList([]);
    } finally {
      setIsLoading(false);
      setIsReload(false);
    }
  };

  /**
   * Fetch branches for dropdown
   */
  const fetchBranches = async () => {
    setError(null);

    try {
      const res = await api.getBranch(searchBranchName);

      if (res.statusCode === 200) {
        const newBranch = {
          id: "",
          branchName: "All Branches",
          address: "",
          branchPhone: "",
          statusName: "",
          statusCode: "",
          branchCode: "",
          createdDate: "",
          updatedDate: "",
        };
        const updatedBranchList = [newBranch, ...res.data.data];
        setBranchList(updatedBranchList);
      } else {
        setError(res.message);
      }
    } catch (error) {
      console.error("Error fetching branches:", error);
      setError(error.message || "Failed to fetch branches");
    }
  };

  /**
   * Get user by ID - Improved with error handling and logging
   * @param {string|number} id - User ID
   * @returns {Promise<Object|null>} - User data or null if not found
   */
  const getUserById = async (id) => {
    setIsSpinner(true);
    setError(null);

    try {
      console.log(`Fetching user data for ID: ${id}`);

      // Ensure id is valid
      if (!id) {
        throw new Error("Invalid user ID");
      }

      const res = await api.getUserById(id);
      console.log("API response:", res);

      if (res.statusCode === 200 && res.data) {
        // Check if user has auth credentials
        setHasAuth(!!res.data.username);
        return res.data;
      } else {
        const errorMsg = res.message || "User not found";
        console.error(`API Error: ${errorMsg}`);
        setError(errorMsg);
        return null;
      }
    } catch (error) {
      console.error("Error in getUserById:", error);
      setError(error.message || "Failed to fetch user");
      return null;
    } finally {
      setIsSpinner(false);
    }
  };

  /**
   * Add new user
   * @param {Object} userData - User data
   * @returns {Promise<Object>} - Result object { success, error, data }
   */
  const addUser = async (userData) => {
    const { username, email, branchCode, fullName, phoneNumber } = userData;

    setError(null);
    setIsSpinner(true);

    try {
      console.log("Adding user with data:", userData);

      const res = await api.saveUser_v2(
        username,
        email,
        branchCode,
        fullName,
        phoneNumber
      );

      console.log("Add user response:", res);

      if (res.statusCode === 200) {
        setIsReload(true);
        return { success: true, data: res.data };
      } else {
        setError(res.message);
        return { success: false, error: res.message };
      }
    } catch (error) {
      console.error("Error adding user:", error);
      setError(error.message || "Failed to add user");
      return { success: false, error: error.message };
    } finally {
      setIsSpinner(false);
    }
  };

  /**
   * Update user
   * @param {string|number} id - User ID
   * @param {Object} userData - Updated user data
   * @returns {Promise<Object>} - Result object { success, error, data }
   */
  const updateUser = async (id, userData) => {
    const {
      username,
      password,
      email,
      fullName,
      phoneNumber,
      branchCode,
      userCode,
    } = userData;

    setError(null);
    setIsSpinner(true);

    try {
      console.log("Updating user with ID:", id, "Data:", userData);

      const res = await api.editUser(
        id,
        username,
        password || "", // Empty string if no password provided
        email,
        fullName,
        phoneNumber,
        branchCode,
        userCode
      );

      console.log("Update user response:", res);

      if (res.statusCode === 200) {
        setIsReload(true);
        return { success: true, data: res.data };
      } else {
        setError(res.message);
        return { success: false, error: res.message };
      }
    } catch (error) {
      console.error("Error updating user:", error);
      setError(error.message || "Failed to update user");
      return { success: false, error: error.message };
    } finally {
      setIsSpinner(false);
    }
  };

  /**
   * Change user password
   * @param {string|number} id - User ID
   * @param {string} oldPassword - Old password
   * @param {string} newPassword - New password
   * @param {string} userCode - User code
   * @returns {Promise<Object>} - Result object { success, error, data }
   */
  const changePassword = async (id, oldPassword, newPassword, userCode) => {
    setError(null);
    setIsSpinner(true);

    try {
      console.log("Changing password for user ID:", id);

      const res = await api.changePassword(
        id,
        oldPassword,
        newPassword,
        userCode
      );

      console.log("Change password response:", res);

      if (res.statusCode === 200) {
        return { success: true, data: res.data };
      } else {
        setError(res.message);
        return { success: false, error: res.message };
      }
    } catch (error) {
      console.error("Error changing password:", error);
      setError(error.message || "Failed to change password");
      return { success: false, error: error.message };
    } finally {
      setIsSpinner(false);
    }
  };

  /**
   * Create user authentication
   * @param {Object} authData - Auth data
   * @returns {Promise<Object>} - Result object { success, error, data }
   */
  const createAuth = async (authData) => {
    const { username, password, email, userCode, branchCode } = authData;

    setError(null);
    setIsSpinner(true);

    try {
      console.log("Creating auth with data:", authData);

      const res = await api.registerAuth(
        username,
        password,
        email,
        userCode,
        branchCode
      );

      console.log("Create auth response:", res);

      if (res.statusCode === 200) {
        setHasAuth(true);
        return { success: true, data: res.data };
      } else {
        setError(res.message);
        return { success: false, error: res.message };
      }
    } catch (error) {
      console.error("Error creating auth:", error);
      setError(error.message || "Failed to create login");
      return { success: false, error: error.message };
    } finally {
      setIsSpinner(false);
    }
  };

  /**
   * Delete user
   * @param {Object} user - User object to delete
   * @returns {Promise<void>}
   */
  const deleteUser = async (user) => {
    setError(null);
    setIsSpinner(true);

    try {
      console.log("Deleting user:", user);

      await api.deleteUser(user);
      console.log("User deleted successfully");

      setIsReload(true);
    } catch (error) {
      console.error("Error deleting user:", error);
      setError(error.message || "Failed to delete user");
      throw error; // Re-throw the error to let the caller handle it
    } finally {
      setIsSpinner(false);
    }
  };

  /**
   * Handler for sorting
   * @param {string} property - Property to sort by
   */
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";

    try {
      const sortedData = [...userList].sort((a, b) => {
        // Use dynamic property access with bracket notation
        const valueA = a[property];
        const valueB = b[property];

        // Handle different types of sorting
        if (typeof valueA === "string" && typeof valueB === "string") {
          return newOrder === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        }

        return newOrder === "asc" ? valueA - valueB : valueB - valueA;
      });

      setUserList(sortedData);
      setOrder(newOrder);
      setOrderBy(property);
    } catch (error) {
      console.error("Error sorting data:", error);
      // Don't update state if sorting fails to avoid UI glitches
    }
  };

  /**
   * Create both user and auth in one operation
   * @param {Object} userData - User data
   * @param {Object} authData - Auth data (optional)
   * @returns {Promise<Object>} - Result object { success, error, data }
   */
  const createUserWithAuth = useCallback(async (userData, authData) => {
    // First create the user
    console.log(
      "Creating user with auth. User data:",
      userData,
      "Auth data:",
      authData
    );

    const userResult = await addUser(userData);

    if (!userResult.success) {
      return userResult;
    }

    // If auth data is provided, create auth credentials
    if (authData) {
      const enhancedAuthData = {
        ...authData,
        userCode: userResult.data?.userCode || userResult.data?.user_code || "",
        branchCode: userData.branchCode,
      };

      console.log("Creating auth with enhanced data:", enhancedAuthData);

      const authResult = await createAuth(enhancedAuthData);

      if (!authResult.success) {
        return {
          success: false,
          error: `User created but login failed: ${authResult.error}`,
          data: userResult.data,
        };
      }
    }

    return {
      success: true,
      data: userResult.data,
      message: authData
        ? "User and login created successfully"
        : "User created successfully",
    };
  }, []);

  // Effect to load users when dependencies change
  useEffect(() => {
    fetchUsers();
  }, [page, rowsPerPage]);

  // Effect to reload data when isReload changes
  useEffect(() => {
    if (isReload) {
      fetchUsers();
    }
  }, [isReload]);

  return {
    // States
    isLoading,
    isSpinner,
    error,
    userList,
    branchList,
    hasAuth,
    page,
    rowsPerPage,
    totalRecord,
    orderBy,
    order,
    searchFullname,
    searchUsername,
    searchBranchName,
    selectedBranchName,

    // Setters
    setIsLoading,
    setIsSpinner,
    setError,
    setPage,
    setRowsPerPage,
    setOrderBy,
    setOrder,
    setSearchFullname,
    setSearchUsername,
    setSearchBranchName,
    setSelectedBranchName,
    setHasAuth,
    setIsReload,

    // API Methods
    fetchUsers,
    fetchBranches,
    getUserById,
    addUser,
    updateUser,
    changePassword,
    createAuth,
    deleteUser,
    createUserWithAuth,

    // Helpers
    handleRequestSort,
  };
};

export default useUserData;
