import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
  Divider,
  Paper,
  Breadcrumbs,
  Link,
} from "@mui/material";
import { Spinner, CustomizedSnackbars } from "../../../components";
import UserForm from "../UserComponent/UserForm";
import AuthForm from "../UserComponent/AuthForm";
import useUserData from "../hooks/useUserData";
import useUserFormValidation from "../utils/validation";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";

const AddUser = () => {
  const navigate = useNavigate();

  const { isSpinner, branchList, error, fetchBranches, createUserWithAuth } =
    useUserData();

  const { errors, setErrors, validateForm, validateAuthForm } =
    useUserFormValidation();

  // Local alert state
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");

  // Form state
  const [branchNameSelected, setBranchNameSelected] = useState([]);
  const [searchBranchName, setSearchBranchName] = useState("");
  const [selectedBranchName, setSelectedBranchName] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [phoneInput, setPhoneInput] = useState("");
  const [usernameInput, setUsernameInput] = useState("");

  // Auth form state
  const [createAuth, setCreateAuth] = useState(false);
  const [usernameAuth, setUsernameAuth] = useState("");
  const [emailAuth, setEmailAuth] = useState("");
  const [passwordInput, setPasswordInput] = useState("");

  useEffect(() => {
    fetchBranches();
  }, [searchBranchName]);

  useEffect(() => {
    // Sync usernameAuth with employeeName when employeeName changes
    // and no explicit username auth has been set
    if (createAuth && (!usernameAuth || usernameAuth === emailInput)) {
      setUsernameAuth(emailInput);
    }

    // Sync emailAuth with emailInput when emailInput changes
    // and no explicit email auth has been set
    if (createAuth && (!emailAuth || emailAuth === emailInput)) {
      setEmailAuth(emailInput);
    }
  }, [employeeName, emailInput, createAuth]);

  // Show API errors if any
  useEffect(() => {
    if (error) {
      showError(error);
    }
  }, [error]);

  const handleCreateUser = async () => {
    // Validate the user form fields
    const { isValid } = validateForm({
      branchNameSelected,
      employeeName,
      emailInput,
      phoneInput,
    });

    if (!isValid) return;

    // Prepare user data
    const userData = {
      username: usernameInput,
      email: emailInput,
      branchCode: selectedBranchName,
      fullName: employeeName,
      phoneNumber: phoneInput,
    };

    // Prepare auth data if needed
    let authData = null;
    if (createAuth) {
      // Validate the auth form fields
      const authValidation = validateAuthForm({
        usernameAuth,
        passwordInput,
      });

      if (!authValidation.isValid) return;

      authData = {
        username: usernameAuth,
        password: passwordInput,
        email: emailAuth,
      };
    }

    // Create user (and auth if needed)
    const result = await createUserWithAuth(userData, authData);

    if (result.success) {
      showSuccess(result.message || "User added successfully");

      // Navigate back to users list after short delay
      setTimeout(() => {
        navigate("/users");
      }, 300);
    } else {
      showError(result.error || "Failed to add user");
    }
  };

  // Utility functions
  const showSuccess = (msg) => {
    setIsOpen(true);
    setAlertType("success");
    setMessage(msg);
  };

  const showError = (msg) => {
    setIsOpen(true);
    setAlertType("error");
    setMessage(msg);
  };

  return (
    <>
      <Spinner isShowSpinner={isSpinner} />
      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />

      <Paper
        sx={{
          p: 3,
          mb: 3,
          borderRadius: 1,
          boxShadow: 1,
        }}
      >
        {/* Header */}
        <Box
          sx={{
            mb: 3,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link
                color="inherit"
                onClick={() => navigate("/users")}
                sx={{ cursor: "pointer", textDecoration: "none" }}
              >
                Users
              </Link>
              <Typography color="text.primary">Add New User</Typography>
            </Breadcrumbs>

            <Typography variant="h6" sx={{ mt: 1 }}>
              Add New User
            </Typography>
          </Box>
        </Box>

        {/* User Profile Form */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
            User Profile
          </Typography>
          <UserForm
            mode="add"
            branchList={branchList}
            branchNameSelected={branchNameSelected}
            setBranchNameSelected={setBranchNameSelected}
            searchBranchName={searchBranchName}
            setSearchBranchName={setSearchBranchName}
            selectedBranchName={selectedBranchName}
            setSelectedBranchName={setSelectedBranchName}
            employeeName={employeeName}
            setEmployeeName={setEmployeeName}
            emailInput={emailInput}
            setEmailInput={setEmailInput}
            phoneInput={phoneInput}
            setPhoneInput={setPhoneInput}
            usernameInput={usernameInput}
            setUsernameInput={setUsernameInput}
            errors={errors}
            setErrors={setErrors}
            onEmployeeNameChange={(value) => {
              // Sync with auth username if checkbox is checked and no custom username set
              if (
                createAuth &&
                (!usernameAuth || usernameAuth === emailInput)
              ) {
                setUsernameAuth(value);
              }
            }}
            onEmailChange={(value) => {
              // Sync with auth email if checkbox is checked and no custom email set
              if (createAuth && (!emailAuth || emailAuth === emailInput)) {
                setEmailAuth(value);
              }
            }}
          />
        </Box>

        <Divider sx={{ my: 3 }} />

        {/* Login Creation Option */}
        <Box sx={{ mb: 3 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={createAuth}
                onChange={(e) => setCreateAuth(e.target.checked)}
              />
            }
            label="Create login credentials for this user"
          />
        </Box>

        {/* Auth Form (conditionally displayed) */}
        {createAuth && (
          <Box sx={{ mb: 4 }}>
            <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
              Login Credentials
            </Typography>
            <AuthForm
              usernameAuth={usernameAuth}
              setUsernameAuth={setUsernameAuth}
              emailAuth={emailAuth}
              setEmailAuth={setEmailAuth}
              passwordInput={passwordInput}
              setPasswordInput={setPasswordInput}
              errors={errors}
              setErrors={setErrors}
            />
          </Box>
        )}

        <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate("/users")}
          >
            Back to Users
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={handleCreateUser}
          >
            Save User
          </Button>
        </Box>
      </Paper>
    </>
  );
};

export default AddUser;
