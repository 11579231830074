/**
 * Validasi form purchase order
 * @param {Object} formData - Data form purchase order
 * @returns {Object} Objek berisi pesan error jika validasi gagal
 */
export const validatePurchaseForm = (formData) => {
  const { supplierNameSelected, branchNameSelected, datePurchase } = formData;
  const errors = {};

  // Validasi supplier
  if (
    !supplierNameSelected ||
    supplierNameSelected === null ||
    (Array.isArray(supplierNameSelected) &&
      supplierNameSelected.length === 0) ||
    Object.keys(supplierNameSelected).length === 0
  ) {
    errors.supplierNameSelected = "Supplier is required to choose";
  }

  // Validasi branch/outlet
  if (
    !branchNameSelected ||
    branchNameSelected === null ||
    (Array.isArray(branchNameSelected) && branchNameSelected.length === 0) ||
    Object.keys(branchNameSelected).length === 0
  ) {
    errors.branchNameSelected = "Branch / Outlet is required to choose";
  }

  // Validasi tanggal
  if (datePurchase === null) {
    errors.datePurchase = "Date must be filled";
  }

  return errors;
};

/**
 * Validasi product orders
 * @param {Array} productOrders - Array product orders
 * @returns {Object} Objek berisi status valid dan pesan error jika ada
 */
export const validateProductOrders = (productOrders) => {
  // Validasi list produk tidak boleh kosong
  if (!productOrders || productOrders.length === 0) {
    return {
      valid: false,
      message: "Product Order must be added first...",
    };
  }

  // Validasi setiap produk harus diisi lengkap
  const hasEmptyFields = productOrders.some(
    (productItem) =>
      !productItem.masterProductCode ||
      !productItem.variantName ||
      !productItem.quantity ||
      !productItem.trxPrice ||
      !productItem.netPrice
  );

  if (hasEmptyFields) {
    return {
      valid: false,
      message:
        "Please ensure all product orders have Product Name, Variant Name, Quantity, Price & Total filled.",
    };
  }

  // Validasi quantity dan price harus berupa angka
  const hasInvalidNumbers = productOrders.some(
    (productItem) =>
      isNaN(parseFloat(productItem.quantity)) ||
      isNaN(parseFloat(productItem.trxPrice)) ||
      isNaN(parseFloat(productItem.netPrice)) ||
      parseFloat(productItem.quantity) <= 0 ||
      parseFloat(productItem.trxPrice) <= 0 ||
      parseFloat(productItem.netPrice) <= 0
  );

  if (hasInvalidNumbers) {
    return {
      valid: false,
      message: "Quantity and prices must be valid numbers greater than zero.",
    };
  }

  return { valid: true };
};

/**
 * Validasi input numerik
 * @param {string} value - Nilai yang akan divalidasi
 * @returns {boolean} True jika numerik, false jika tidak
 */
export const isNumeric = (value) => {
  return !isNaN(parseFloat(value)) && isFinite(value);
};

/**
 * Validasi input harga
 * @param {string} value - Nilai harga yang akan divalidasi
 * @returns {boolean} True jika valid, false jika tidak
 */
export const isPriceValid = (value) => {
  // Remove non-numeric characters and parse as float
  const numericValue = parseFloat(value.toString().replace(/[^\d.-]/g, ""));
  return !isNaN(numericValue) && numericValue >= 0;
};
