import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Paper,
  Typography,
  Grid,
  Button,
  Breadcrumbs,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  IconButton,
  Stack,
  CircularProgress,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SaveIcon from "@mui/icons-material/Save";
import { BsFillTrash3Fill } from "react-icons/bs";
import {
  PickerSearch,
  FormDatePicker,
  FormInput,
  Spinner,
  CustomizedSnackbars,
} from "../../../components";
import { tableHeadTransferProduct } from "../../../utils/Constants";
import NumberFormat from "../../../helpers/functions/numberFormat";
import moment from "moment";

// Import hooks and utils
import { useInventoryData } from "../hooks/useInventoryData";
import { useBranches } from "../hooks/useBranches";
import {
  validateTransferForm,
  validateProductOrders,
  validateProductDuplication,
} from "../utils/validation";
import { formatQuantity } from "../utils/formatters";

function AddInventoryTransfer() {
  const navigate = useNavigate();

  // Hooks
  const {
    isSpinner,
    setIsSpinner,
    inventoryList,
    fetchInventoryData,
    saveTransferInventory,
    searchProductName,
    setSearchProductName,
  } = useInventoryData();

  const { branchList, searchBranchName, setSearchBranchName } = useBranches();

  // Local state
  const [isLoading, setIsLoading] = useState(false);
  const [transferDate, setTransferDate] = useState(null);
  const [branchNameFromSelected, setBranchNameFromSelected] = useState(null);
  const [branchNameToSelected, setBranchNameToSelected] = useState(null);
  const [productOrders, setProductOrders] = useState([]);
  const [productNameSelected, setProductNameSelected] = useState([]);
  const [errors, setErrors] = useState({});
  const [isRemoveProduct, setIsRemoveProduct] = useState(false);

  // Alert state
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");

  // Effect to load inventory data when branch is selected
  useEffect(() => {
    if (branchNameFromSelected?.branchCode) {
      fetchInventoryData(branchNameFromSelected.branchCode, searchProductName);
    }
  }, [branchNameFromSelected, searchProductName]);

  // Add new product to the list
  const handleAddProduct = () => {
    setSearchProductName("");
    setProductOrders([
      ...productOrders,
      {
        basePrice: "0",
        branchCode: "",
        branchName: "",
        brandId: "",
        brandName: "",
        categoryId: "",
        categoryName: "",
        createdDate: "",
        imageUrl: "",
        minQuantity: "",
        productCode: "",
        productId: "",
        productName: "",
        productSku: "",
        quantity: "0",
        status: 0,
        transferedQuantity: "",
        trxPrice: "0",
        updatedDate: "",
        masterProductCode: "",
        variantName: "",
      },
    ]);
  };

  // Handle selecting a product from inventory
  const onHandleProductItemSelected = (currentIndex, itemSelector) => {
    // Validate product duplication
    const duplicateValidation = validateProductDuplication(
      productOrders,
      itemSelector
    );
    if (!duplicateValidation.valid) {
      showError(duplicateValidation.message);
      return;
    }

    const newProducts = [...productOrders];
    newProducts[currentIndex].id = itemSelector.id;
    newProducts[currentIndex].basePrice = itemSelector.basePrice;
    newProducts[currentIndex].branchCode = branchNameFromSelected.branchCode;
    newProducts[currentIndex].branchName = branchNameFromSelected.branchName;
    newProducts[currentIndex].brandId = itemSelector.brandId;
    newProducts[currentIndex].brandName = itemSelector.brandName;
    newProducts[currentIndex].categoryId = itemSelector.categoryId;
    newProducts[currentIndex].categoryName = itemSelector.categoryName;
    newProducts[currentIndex].createdDate = itemSelector.createdDate;
    newProducts[currentIndex].imageUrl = itemSelector.imageUrl;
    newProducts[currentIndex].minQuantity = itemSelector.minQuantity;
    newProducts[currentIndex].productCode = itemSelector.productCode;
    newProducts[currentIndex].productId = itemSelector.productId;
    newProducts[currentIndex].productName = itemSelector.productName;
    newProducts[currentIndex].productSku = itemSelector.productSku;
    newProducts[currentIndex].quantity = itemSelector.quantity;
    newProducts[currentIndex].updatedDate = itemSelector.updatedDate;
    newProducts[currentIndex].status = itemSelector.status;
    newProducts[currentIndex].masterProductCode =
      itemSelector.masterProductCode;
    newProducts[currentIndex].variantName = itemSelector.variantName;

    setProductOrders(newProducts);
    setProductNameSelected((prevData) => {
      const updatedData = [...prevData];
      updatedData[currentIndex] = {
        ...updatedData[currentIndex],
        variantSelected: itemSelector,
        searchVariant: itemSelector.variantName,
      };
      return updatedData;
    });
  };

  // Handle input change for transferred quantity
  const handleInputChange = (event, index) => {
    const newProducts = [...productOrders];
    newProducts[index][event.target.name] = event.target.value;
    setProductOrders(newProducts);
  };

  // Calculate total price based on transferred quantity
  const calculateTotalPrice = (index) => {
    const newProducts = [...productOrders];
    newProducts[index].trxPrice =
      newProducts[index].transferedQuantity * newProducts[index].basePrice;
    setProductOrders(newProducts);
  };

  // Remove product from the list
  const onHandleRemoveProductOrder = (index) => {
    const newArray = [...productOrders];
    const newArrayProductSelected = [...productNameSelected];

    newArray.splice(index, 1);
    newArrayProductSelected.splice(index, 1);

    setProductOrders(newArray);
    setProductNameSelected(newArrayProductSelected);
    setSearchProductName("");

    if (productOrders.length === 0) {
      setProductNameSelected([]);
    }

    setTimeout(() => {
      setIsRemoveProduct(false);
    }, 300);
  };

  // Save transfer inventory
  const handleSubmit = async () => {
    // Validate form
    const formErrors = validateTransferForm({
      branchNameFromSelected,
      branchNameToSelected,
      transferDate,
    });

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    // Validate products
    const productValidation = validateProductOrders(productOrders);
    if (!productValidation.valid) {
      showError(productValidation.message);
      return;
    }

    // Prepare transfer data
    const formattedDate = moment(transferDate).format("YYYY-MM-DD");
    const transferData = {
      formattedDate,
      branchNameFrom: branchNameFromSelected,
      branchNameTo: branchNameToSelected,
      productOrders,
    };

    // Save transfer inventory
    setIsSpinner(true);
    const result = await saveTransferInventory(transferData);
    setIsSpinner(false);

    if (result.success) {
      showSuccess("Inventory transfer saved successfully");

      // Navigate back to list after short delay
      setTimeout(() => {
        navigate("/inventory");
      }, 300);
    } else {
      showError(result.error || "Failed to save inventory transfer");
    }
  };

  // Utility functions
  const showError = (msg) => {
    setIsOpen(true);
    setAlertType("error");
    setMessage(msg);
  };

  const showSuccess = (msg) => {
    setIsOpen(true);
    setAlertType("success");
    setMessage(msg);
  };

  return (
    <>
      <Spinner isShowSpinner={isSpinner} />
      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />

      <Paper
        sx={{
          p: 3,
          mb: 3,
          borderRadius: 1,
          boxShadow: 1,
        }}
      >
        {/* Header */}
        <Box
          sx={{
            mb: 3,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link
                color="inherit"
                onClick={() => navigate("/inventory")}
                sx={{ cursor: "pointer", textDecoration: "none" }}
              >
                Inventory
              </Link>
              <Typography color="text.primary">Transfer Stock</Typography>
            </Breadcrumbs>

            <Typography variant="h6" sx={{ mt: 1 }}>
              New Stock Transfer
            </Typography>
          </Box>
        </Box>

        {/* Form Content */}
        <Grid container spacing={3}>
          {/* Source Branch */}
          <Grid item xs={12} sm={6}>
            <PickerSearch
              value={branchNameFromSelected}
              dataList={branchList}
              onInputChange={(event, newInputValue) => {
                setSearchBranchName(newInputValue);
              }}
              onChange={(e, v) => {
                if (v) {
                  setBranchNameFromSelected(v);
                  setSearchBranchName("");
                  setProductOrders([]);
                  setBranchNameToSelected(null);
                  // Clear error when branch is selected
                  setErrors((prev) => ({
                    ...prev,
                    branchNameFromSelected: undefined,
                  }));
                } else {
                  setProductOrders([]);
                  setBranchNameFromSelected(null);
                  setBranchNameToSelected(null);
                  setSearchBranchName("");
                }
              }}
              getOptionLabel={(branchList) => branchList.branchName || ""}
              labelPicker={"Choose Branch / Outlet Sender"}
              placeholderPicker={"search branch / outlet sender"}
              sizePicker={"large"}
              isVisibleLabelPicker={true}
              labelTitlePicker={""}
              error={!!errors.branchNameFromSelected}
              helperText={errors.branchNameFromSelected}
            />
          </Grid>

          {/* Destination Branch */}
          <Grid item xs={12} sm={6}>
            <PickerSearch
              disabled={!branchNameFromSelected}
              value={branchNameToSelected}
              dataList={branchList}
              onInputChange={(event, newInputValue) => {
                setSearchBranchName(newInputValue);
              }}
              onChange={(e, v) => {
                if (v) {
                  setBranchNameToSelected(v);
                  setSearchBranchName("");
                  // Clear error when branch is selected
                  setErrors((prev) => ({
                    ...prev,
                    branchNameToSelected: undefined,
                  }));
                } else {
                  setSearchBranchName("");
                  setBranchNameToSelected(null);
                }
              }}
              getOptionLabel={(branchList) => branchList.branchName || ""}
              getOptionDisabled={(option) =>
                option?.branchCode === branchNameFromSelected?.branchCode
              }
              labelPicker={"Choose Branch / Outlet Receiver"}
              placeholderPicker={"search branch / outlet receiver"}
              sizePicker={"large"}
              isVisibleLabelPicker={true}
              labelTitlePicker={""}
              error={!!errors.branchNameToSelected}
              helperText={errors.branchNameToSelected}
            />
          </Grid>

          {/* Transfer Date */}
          <Grid item xs={12} sm={6}>
            <FormDatePicker
              labelFormDatePicker="Transfer Date"
              formSize={"large"}
              dateValue={transferDate}
              onChangeDate={(val) => {
                setTransferDate(val);
                // Clear error when date is selected
                setErrors((prev) => ({ ...prev, transferDate: undefined }));
              }}
              error={!!errors.transferDate}
              helperText={errors.transferDate}
            />
          </Grid>
        </Grid>

        {/* Products Table */}
        <Box sx={{ mt: 4 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <Typography variant="h6">Products</Typography>

            <Button
              disabled={!branchNameFromSelected}
              variant="outlined"
              onClick={handleAddProduct}
              startIcon={<AddCircleIcon />}
            >
              Add Product
            </Button>
          </Box>

          <TableContainer>
            <Table stickyHeader aria-label="transfer products table">
              <TableHead>
                <TableRow>
                  {tableHeadTransferProduct.map((item) => (
                    <TableCell
                      key={item.name}
                      sx={{
                        fontWeight: "bold",
                        backgroundColor: "#060270",
                        color: "white",
                        width: item.size,
                      }}
                    >
                      {item.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {!isRemoveProduct && (
                <TableBody>
                  {productOrders.length === 0 ? (
                    <TableRow>
                      <TableCell
                        colSpan={tableHeadTransferProduct.length}
                        align="center"
                        sx={{ py: 4 }}
                      >
                        <Grid
                          container
                          spacing={2}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid item>
                            <AddCircleIcon color="secondary" fontSize="large" />
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="body1"
                              sx={{ color: "text.secondary" }}
                            >
                              {` No products found. You can add a new product using the
                      button above.`}
                            </Typography>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ) : (
                    productOrders.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell width={"10%"}>{index + 1}</TableCell>
                        <TableCell width={"30%"}>
                          <PickerSearch
                            value={productNameSelected[index]?.variantSelected}
                            dataList={inventoryList}
                            onInputChange={(event, newInputValue) => {
                              setSearchProductName(newInputValue);
                            }}
                            onChange={(e, v) => {
                              if (v) {
                                setProductNameSelected((prevData) => {
                                  const updatedData = [...prevData];
                                  updatedData[index] = {
                                    ...updatedData[index],
                                    variantSelected: [],
                                    searchVariant: "",
                                  };
                                  return updatedData;
                                });
                                setSearchProductName("");
                                onHandleProductItemSelected(index, v);
                              } else {
                                setSearchProductName("");
                                setProductNameSelected((prevData) => {
                                  const updatedData = [...prevData];
                                  updatedData[index] = {
                                    ...updatedData[index],
                                    variantSelected: [],
                                    searchVariant: "",
                                  };
                                  return updatedData;
                                });
                                setProductOrders((prevData) => {
                                  const updatedData = [...prevData];
                                  updatedData[index] = {
                                    ...updatedData[index],
                                    transferedQuantity: "",
                                    quantity: "0",
                                    masterProductCode: "",
                                  };
                                  return updatedData;
                                });
                              }
                            }}
                            getOptionLabel={(inventory) =>
                              `${inventory.productName} - ${inventory.variantName}` ||
                              ""
                            }
                            labelPicker={"Choose Product Variant"}
                            placeholderPicker={"search product variant"}
                            sizePicker={"small"}
                            isVisibleLabelPicker={true}
                          />
                        </TableCell>
                        <TableCell width={"20%"}>
                          {formatQuantity(item.quantity)}
                        </TableCell>
                        <TableCell width={"30%"}>
                          <FormInput
                            type="number"
                            size="small"
                            label="Transfer Quantity"
                            name={"transferedQuantity"}
                            value={item.transferedQuantity}
                            onChange={(event) => {
                              handleInputChange(event, index);
                              calculateTotalPrice(index);
                            }}
                          />
                        </TableCell>
                        <TableCell width={"10%"} align="center">
                          <Tooltip title="Delete Product">
                            <IconButton
                              onClick={() => {
                                setIsRemoveProduct(true);
                                onHandleRemoveProductOrder(index);
                              }}
                              size="medium"
                              sx={{
                                width: 30,
                                height: 30,
                                borderRadius: 1,
                                backgroundColor: "#f4e6fb",
                                color: "#FE3133",
                              }}
                            >
                              <BsFillTrash3Fill />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Box>

        {/* Action Buttons */}
        <Box sx={{ mt: 4, display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate("/inventory")}
          >
            Back to Inventory
          </Button>

          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={handleSubmit}
          >
            Save Transfer
          </Button>
        </Box>
      </Paper>
    </>
  );
}

export default AddInventoryTransfer;
