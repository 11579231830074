import React from "react";
import { Grid } from "@mui/material";
import { FormInput, PickerSearch } from "../../../components";
import useUserFormValidation from "../utils/validation";

const UserForm = ({
  mode = "add",
  branchList = [],
  branchNameSelected,
  setBranchNameSelected,
  searchBranchName,
  setSearchBranchName,
  selectedBranchName,
  setSelectedBranchName,
  employeeName,
  setEmployeeName,
  emailInput,
  setEmailInput,
  phoneInput,
  setPhoneInput,
  usernameInput,
  setUsernameInput,
  errors,
  setErrors,
  onReload,
  // Optional props for syncing with auth form
  onEmployeeNameChange,
  onEmailChange,
}) => {
  const { validateForm } = useUserFormValidation();

  const handleEmployeeNameChange = (event) => {
    const value = event.target.value;
    setEmployeeName(value);

    // If in add mode, also update username to match employee name
    if (mode === "add") {
      setUsernameInput(value);
    }

    // If external handler exists, call it (for syncing with auth form)
    if (onEmployeeNameChange) {
      onEmployeeNameChange(value);
    }

    // Clear errors when field is changed
    if (errors.employeeName) {
      const newErrors = { ...errors };
      delete newErrors.employeeName;
      setErrors(newErrors);
    }
  };

  return (
    <Grid container spacing={1} alignItems={"center"}>
      <Grid item xs={12} sm={12}>
        <PickerSearch
          value={branchNameSelected}
          dataList={branchList.filter((i) => i.branchCode !== "")}
          onInputChange={(event, newInputValue) => {
            setSearchBranchName(newInputValue);
          }}
          onChange={(e, v) => {
            if (v) {
              setBranchNameSelected(v);
              setSelectedBranchName(v.branchCode);
              setSearchBranchName("");
            } else {
              setBranchNameSelected([]);
              setSelectedBranchName("");
              setSearchBranchName("");
              if (onReload) onReload();
            }
          }}
          getOptionLabel={(branchItem) => branchItem.branchName || ""}
          labelPicker={"Choose Branch / Outlet"}
          placeholderPicker={"search branch / outlet"}
          sizePicker={"large"}
          isVisibleLabelPicker={true}
          labelTitlePicker={""}
          error={!!errors.branchNameSelected}
          helperText={errors.branchNameSelected}
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <FormInput
          label="Employee Name"
          name="employeeName"
          size="medium"
          error={!!errors.employeeName}
          helperText={errors.employeeName}
          value={employeeName}
          onChange={handleEmployeeNameChange}
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <FormInput
          label="Email"
          name="emailInput"
          size="medium"
          error={!!errors.emailInput}
          helperText={errors.emailInput}
          value={emailInput}
          onChange={(event) => {
            const value = event.target.value;
            setEmailInput(value);

            // If external handler exists, call it (for syncing with auth form)
            if (onEmailChange) {
              onEmailChange(value);
            }

            if (errors.emailInput) {
              const newErrors = { ...errors };
              delete newErrors.emailInput;
              setErrors(newErrors);
            }
          }}
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <FormInput
          label="Phone Number"
          name="phoneInput"
          size="medium"
          error={!!errors.phoneInput}
          helperText={errors.phoneInput}
          value={phoneInput}
          onChange={(event) => {
            setPhoneInput(event.target.value);
            if (errors.phoneInput) {
              const newErrors = { ...errors };
              delete newErrors.phoneInput;
              setErrors(newErrors);
            }
          }}
        />
      </Grid>
    </Grid>
  );
};

export default UserForm;
