/**
 * Format a number with thousand separator
 * @param {number|string} num - The number to format
 * @param {string} defaultValue - Default value if num is empty
 * @returns {string} Formatted number string
 */
export const formatNumber = (num, defaultValue = "") => {
  if (num === "" || num === null || num === undefined) return defaultValue;
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

/**
 * Format currency in Indonesian Rupiah format
 * @param {number|string} amount - The amount to format
 * @param {string} defaultValue - Default value if amount is empty
 * @returns {string} Formatted currency string
 */
export const formatCurrency = (amount, defaultValue = "Rp 0") => {
  if (amount === "" || amount === null || amount === undefined)
    return defaultValue;
  return `Rp ${formatNumber(amount)}`;
};

/**
 * Format date to Indonesian format
 * @param {string|Date} date - Date to format
 * @param {string} defaultValue - Default value if date is invalid
 * @returns {string} Formatted date string
 */
export const formatDate = (date, defaultValue = "-") => {
  if (!date) return defaultValue;

  try {
    const d = new Date(date);
    if (isNaN(d.getTime())) return defaultValue;

    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();

    return `${day}/${month}/${year}`;
  } catch (error) {
    return defaultValue;
  }
};

/**
 * Format date to YYYY-MM-DD format for API requests
 * @param {string|Date} date - Date to format
 * @param {string} defaultValue - Default value if date is invalid
 * @returns {string} Formatted date string for API
 */
export const formatDateForAPI = (date, defaultValue = "") => {
  if (!date) return defaultValue;

  try {
    const d = new Date(date);
    if (isNaN(d.getTime())) return defaultValue;

    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();

    return `${year}-${month}-${day}`;
  } catch (error) {
    return defaultValue;
  }
};

/**
 * Format quantity with unit
 * @param {number|string} quantity - Quantity value
 * @param {string} unit - Unit of measurement (default: pcs)
 * @param {string} defaultValue - Default value if quantity is empty
 * @returns {string} Formatted quantity with unit
 */
export const formatQuantity = (quantity, unit = "pcs", defaultValue = "0") => {
  if (quantity === "" || quantity === null || quantity === undefined)
    return defaultValue + " " + unit;
  return `${formatNumber(quantity)} ${unit}`;
};

/**
 * Format transfer status based on status code
 * @param {string|number} statusCode - Status code from API
 * @returns {string} Readable status text
 */
export const formatTransferStatus = (statusCode) => {
  const statusMap = {
    201: "Completed",
    301: "Pending",
    401: "Cancelled",
    // Add more status codes as needed
  };

  return statusMap[statusCode] || "Unknown";
};

/**
 * Get color for transfer status
 * @param {string|number} statusCode - Status code
 * @returns {string} Material UI color
 */
export const getStatusColor = (statusCode) => {
  const colorMap = {
    201: "success",
    301: "warning",
    401: "error",
    // Add more status codes as needed
  };

  return colorMap[statusCode] || "default";
};

/**
 * Format branch name with code
 * @param {string} name - Branch name
 * @param {string} code - Branch code
 * @returns {string} Formatted branch name with code
 */
export const formatBranchName = (name, code) => {
  if (!name && !code) return "-";
  if (!code) return name;
  if (!name) return code;

  return `${name} (${code})`;
};

/**
 * Format product name with variant
 * @param {string} productName - Product name
 * @param {string} variantName - Variant name
 * @returns {string} Formatted product name with variant
 */
export const formatProductName = (productName, variantName) => {
  if (!productName && !variantName) return "-";
  if (!variantName) return productName;
  if (!productName) return variantName;

  return `${productName} - ${variantName}`;
};

export default {
  formatNumber,
  formatCurrency,
  formatDate,
  formatDateForAPI,
  formatQuantity,
  formatTransferStatus,
  getStatusColor,
  formatBranchName,
  formatProductName,
};
