import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import {
  TransactionChart,
  DashboardCard,
  TableList,
  Spinner,
} from "../../components";
import CustomizedSnackbars from "../../components/base/Snackbar";
import moment from "moment";
import api from "../../helpers/api";
import { tableHeadDashboard } from "../../utils/Constants";

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);

  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  // ======
  const [productProvitList, setProductProvitList] = useState(null);
  // ==============
  const [branchList, setBranchList] = useState([]);
  const [searchBranchName, setSearchBranchName] = useState("");
  const [branchCodeSelected, setBranchCodeSelected] = useState("");

  // State for date filters
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);
  const [transactionCode, setTransactionType] = useState("");

  // =====================

  const onGetProductSelling = async () => {
    setIsLoading(true);
    const currentPage = page + 1;
    const dateFromSelected =
      selectedDateFrom === null
        ? ""
        : moment(selectedDateFrom).format("YYYY-MM-DD");
    const dateToSelected =
      selectedDateTo === null
        ? ""
        : moment(selectedDateTo).format("YYYY-MM-DD");
    try {
      const res = await api.getProductProvit(
        searchBranchName,
        dateFromSelected,
        dateToSelected,
        rowsPerPage,
        currentPage
      );
      if (res.statusCode === 200) {
        setTotalRecord(res.data.totalData * res.data.limit);
        setProductProvitList(res.data.data);
      } else {
        setProductProvitList([]);
        onError(res.message);
      }
    } catch (error) {
      setProductProvitList([]);
      onError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // ==============

  useEffect(() => {
    const fetch = async () => {
      await onGetProductSelling();
    };
    fetch();
  }, [page, rowsPerPage]);

  const onError = async (message) => {
    setProductProvitList([]);
    setIsLoading(false);
    setIsOpen(true);
    setAlertType("error");
    setMessage(message);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Spinner isShowSpinner={isSpinner} />
      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />
      {/* Dashboard Card */}
      <Grid
        container
        spacing={1}
        alignItems={"center"}
        sx={{
          marginTop: 2,
          marginBottom: 3,
        }}
      >
        <Grid item xs={12} sm={3}>
          <DashboardCard
            isLoadingShimmer={isLoading}
            titleCard="Total Balance"
            dataCard="Rp. 3.460.000"
            fromColor={"from-indigo-400"}
            toColor={"to-cyan-400"}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <DashboardCard
            isLoadingShimmer={isLoading}
            titleCard={"Total Income"}
            dataCard={"Rp. 3.460.000"}
            fromColor={"from-blue-800"}
            toColor={"to-indigo-900"}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <DashboardCard
            isLoadingShimmer={isLoading}
            titleCard={"Total Expenses"}
            dataCard={"Rp. 460.000"}
            fromColor={"from-emerald-500"}
            toColor={"to-emerald-900"}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <DashboardCard
            isLoadingShimmer={isLoading}
            titleCard={"Total User"}
            dataCard={"277"}
            fromColor={"from-amber-500"}
            toColor={"to-pink-500"}
          />
        </Grid>
      </Grid>

      {/* Dashboard Statistic */}
      <Grid
        container
        spacing={1}
        alignItems={"center"}
        sx={{
          marginBottom: 3,
        }}
      >
        <Grid item xs={12} sm={12}>
          <TransactionChart />
        </Grid>

        {/* <Grid item xs={12} sm={4}>
          <PieChartComponent />
        </Grid> */}
      </Grid>

      <Box
        sx={{
          width: "100%",
          backgroundColor: "white",
          borderRadius: 1,
          border: "1px solid",
          borderColor: "divider",
        }}
      >
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            alignItems: "center",
            p: 3,
            borderBottom: "1px solid",
            borderColor: "divider",
          }}
        >
          <Typography variant="h6" sx={{ mb: { xs: 2, sm: 0 } }}>
            Best Selling Product
          </Typography>
        </Box>

        {/* table */}
        <TableList
          tableStructure={"dashboard-products"}
          tableName={"Best Seller Products"}
          isShowPagination={true}
          isLoading={isLoading}
          tableHeadList={tableHeadDashboard}
          data={productProvitList}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Box>
    </>
  );
};

export default Dashboard;
