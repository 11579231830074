import { useState, useEffect } from "react";
import api from "../../../helpers/api";
import moment from "moment";

export const useProductReceiptData = (
  initialPage = 0,
  initialRowsPerPage = 10
) => {
  // Loading states
  const [isLoading, setIsLoading] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);
  const [isReload, setIsReload] = useState(false);

  // Error state
  const [error, setError] = useState(null);

  // Table states
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("desc");

  // Pagination states
  const [page, setPage] = useState(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const [totalRecord, setTotalRecord] = useState(0);

  // Filter states
  const [branchCodeSelected, setBranchCodeSelected] = useState("");
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);
  const [transactionCode, setTransactionCode] = useState(10); // Kode transaksi default

  // Data states
  const [productReceiveList, setProductReceiveList] = useState([]);
  const [detailTransaction, setDetailTransaction] = useState(null);

  /**
   * Mengambil data daftar product receipt
   */
  const fetchProductReceiveList = async () => {
    const currentPage = page + 1;
    const dateFromFormatted = selectedDateFrom
      ? moment(selectedDateFrom).format("YYYY-MM-DD")
      : "";
    const dateToFormatted = selectedDateTo
      ? moment(selectedDateTo).format("YYYY-MM-DD")
      : "";

    setIsLoading(true);
    setError(null);

    try {
      const res = await api.getPurchaseDataList(
        currentPage,
        rowsPerPage,
        "", // searchPurchaseNo
        dateFromFormatted,
        dateToFormatted,
        branchCodeSelected
      );

      if (res.statusCode === 200) {
        setProductReceiveList(res.data.data);
        setTotalRecord(res.data.totalPage * res.data.limit);
        setIsReload(false);
      } else {
        setError(res.message);
      }
    } catch (error) {
      setError(error.message || "Failed to fetch product receive data");
      setProductReceiveList([]);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Mengambil detail transaksi berdasarkan ID
   * @param {string|number} id - ID transaksi
   * @returns {Promise<Object|null>} Data detail transaksi atau null jika gagal
   */
  const fetchTransactionDetail = async (id) => {
    if (!id) return null;

    setIsSpinner(true);
    setError(null);

    try {
      const res = await api.getDetailProductReceive(id);

      if (res.statusCode === 200) {
        setDetailTransaction(res.data);
        return res.data;
      } else {
        setError(res.message);
        return null;
      }
    } catch (error) {
      setError(error.message || "Failed to fetch transaction detail");
      return null;
    } finally {
      setIsSpinner(false);
    }
  };

  /**
   * Menyelesaikan proses purchase order
   * @param {Object} data - Data produk yang diperbarui
   * @returns {Promise<Object>} Status sukses dan data respons
   */
  const finishPurchaseOrder = async (products) => {
    setIsSpinner(true);
    setError(null);

    try {
      const res = await api.finishPurchaseOrder(detailTransaction, products);

      if (res.statusCode === 200) {
        setIsReload(true);
        return { success: true, data: res.data };
      } else {
        setError(res.message);
        return { success: false, error: res.message };
      }
    } catch (error) {
      setError(error.message || "Failed to finish purchase order");
      return { success: false, error: error.message };
    } finally {
      setIsSpinner(false);
    }
  };

  /**
   * Membatalkan purchase order
   * @returns {Promise<Object>} Status sukses dan data respons
   */
  const cancelPurchaseOrder = async () => {
    if (!detailTransaction) {
      return { success: false, error: "No transaction selected" };
    }

    setIsSpinner(true);
    setError(null);

    try {
      const res = await api.cancelPurchases(detailTransaction);

      if (res.statusCode === 200) {
        setIsReload(true);
        return { success: true, data: res.data };
      } else {
        setError(res.message);
        return { success: false, error: res.message };
      }
    } catch (error) {
      setError(error.message || "Failed to cancel purchase order");
      return { success: false, error: error.message };
    } finally {
      setIsSpinner(false);
    }
  };

  /**
   * Handler untuk pengurutan data
   * @param {string} property - Properti yang akan diurutkan
   */
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";

    const sortedData = [...productReceiveList].sort((a, b) => {
      const valueA = a[property];
      const valueB = b[property];

      if (typeof valueA === "string") {
        return newOrder === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }

      return newOrder === "asc" ? valueA - valueB : valueB - valueA;
    });

    setProductReceiveList(sortedData);
    setOrder(newOrder);
    setOrderBy(property);
  };

  // Effect untuk memuat data saat halaman atau jumlah baris per halaman berubah
  useEffect(() => {
    fetchProductReceiveList();
  }, [page, rowsPerPage]);

  // Effect untuk memuat ulang data saat isReload bernilai true
  useEffect(() => {
    if (isReload) {
      fetchProductReceiveList();
    }
  }, [isReload]);

  return {
    // State
    productReceiveList,
    detailTransaction,
    isLoading,
    isSpinner,
    isReload,
    error,
    page,
    rowsPerPage,
    totalRecord,
    orderBy,
    order,
    branchCodeSelected,
    selectedDateFrom,
    selectedDateTo,
    transactionCode,

    // Setters
    setProductReceiveList,
    setDetailTransaction,
    setIsLoading,
    setIsSpinner,
    setIsReload,
    setPage,
    setRowsPerPage,
    setBranchCodeSelected,
    setSelectedDateFrom,
    setSelectedDateTo,
    setTransactionCode,

    // Methods
    fetchProductReceiveList,
    fetchTransactionDetail,
    finishPurchaseOrder,
    cancelPurchaseOrder,
    handleRequestSort,
  };
};

export default useProductReceiptData;
