import React from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material";
import { FormInput } from "../../../components";

function SupplierForm({ formData, setFormData, errors, setErrors }) {
  // Handler for input change
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update form data
    setFormData({
      ...formData,
      [name]: value,
    });

    // Clear error when input changes
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: undefined,
      });
    }
  };

  return (
    <Card sx={{ mb: 4 }}>
      <CardContent>
        <Typography variant="subtitle1" gutterBottom fontWeight="medium">
          Supplier Information
        </Typography>

        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <FormInput
              label="Supplier Name"
              name="supplierName"
              size="medium"
              value={formData.supplierName}
              onChange={handleChange}
              error={!!errors.supplierName}
              helperText={errors.supplierName}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormInput
              label="Contact Person"
              name="supplierContacName"
              size="medium"
              value={formData.supplierContacName}
              onChange={handleChange}
              error={!!errors.supplierContacName}
              helperText={errors.supplierContacName}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormInput
              label="Phone Number"
              name="supplierPhone"
              size="medium"
              value={formData.supplierPhone}
              onChange={handleChange}
              error={!!errors.supplierPhone}
              helperText={errors.supplierPhone}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormInput
              label="Email"
              name="supplierEmail"
              size="medium"
              value={formData.supplierEmail}
              onChange={handleChange}
              error={!!errors.supplierEmail}
              helperText={errors.supplierEmail}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <FormInput
              label="Address"
              name="supplierAddress"
              value={formData.supplierAddress}
              onChange={handleChange}
              error={!!errors.supplierAddress}
              helperText={errors.supplierAddress}
              multiline
              rows={4}
              required
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default SupplierForm;
