import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Paper,
  Typography,
  Button,
  Breadcrumbs,
  Link,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import { Spinner, CustomizedSnackbars } from "../../../components";

// Import components, hooks and utils
import BranchForm from "../BranchComponent/BranchForm";
import { useBranchData } from "../hooks/useBranchData";
import { validateBranchForm } from "../utils/validation";

function AddBranch() {
  const navigate = useNavigate();

  // Use custom hook for API functions
  const { addBranch } = useBranchData();

  // Form state
  const [formData, setFormData] = useState({
    branchName: "",
    picName: "",
    status: {},
    branchPhone: "",
    address: "",
  });
  const [errors, setErrors] = useState({});

  // Loading and alert states
  const [isSpinner, setIsSpinner] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");

  // Handle form submission
  const handleSubmit = async () => {
    // Validate form
    const validationErrors = validateBranchForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setIsSpinner(true);

    try {
      const result = await addBranch(formData);

      if (result.success) {
        showSuccess("Outlet added successfully");

        // Navigate back to branch list after short delay
        setTimeout(() => {
          navigate("/branches");
        }, 300);
      } else {
        showError(result.error || "Failed to add outlet");
      }
    } catch (error) {
      showError(error.message || "An error occurred");
    } finally {
      setIsSpinner(false);
    }
  };

  // Utility functions
  const showSuccess = (msg) => {
    setIsOpen(true);
    setAlertType("success");
    setMessage(msg);
  };

  const showError = (msg) => {
    setIsOpen(true);
    setAlertType("error");
    setMessage(msg);
  };

  return (
    <>
      <Spinner isShowSpinner={isSpinner} />
      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />

      <Paper
        sx={{
          p: 3,
          mb: 3,
          borderRadius: 1,
          boxShadow: 1,
        }}
      >
        {/* Header */}
        <Box
          sx={{
            mb: 3,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link
                color="inherit"
                onClick={() => navigate("/branches")}
                sx={{ cursor: "pointer", textDecoration: "none" }}
              >
                Outlets
              </Link>
              <Typography color="text.primary">Add New Outlet</Typography>
            </Breadcrumbs>

            <Typography variant="h6" sx={{ mt: 1 }}>
              Add New Outlet
            </Typography>
          </Box>
        </Box>

        {/* Form Content */}
        <BranchForm
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
        />

        {/* Action Buttons */}
        <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate("/branches")}
          >
            Back to Outlets
          </Button>

          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={handleSubmit}
          >
            Save Outlet
          </Button>
        </Box>
      </Paper>
    </>
  );
}

export default AddBranch;
