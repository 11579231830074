// src/pages/products/hooks/useCategories.js
import { useState, useEffect } from "react";
import api from "../../../helpers/api";

export const useCategories = (initialSearchTerm = "") => {
  const [categoryList, setCategoryList] = useState([]);
  const [searchCategoryName, setSearchCategoryName] =
    useState(initialSearchTerm);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await api.getCategories(searchCategoryName);

        if (response.statusCode === 200) {
          setCategoryList(response.data.data);
        } else {
          setCategoryList([]);
          setError(response.message);
        }
      } catch (err) {
        setCategoryList([]);
        setError(err.message || "Failed to fetch categories");
      } finally {
        setIsLoading(false);
      }
    };

    fetchCategories();
  }, [searchCategoryName]);

  return {
    categoryList,
    searchCategoryName,
    setSearchCategoryName,
    isLoading,
    error,
  };
};

export default useCategories;
