// src/pages/products/selling-price/index.js
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Paper,
  Typography,
  Grid,
  Button,
  Breadcrumbs,
  Link,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import { Spinner, CustomizedSnackbars } from "../../../components";
import PricingForm from "../productComponents/PricingForm";
import api from "../../../helpers/api";
import { validatePricingForm } from "../utils/validation";
import { PickerSearch } from "../../../components";
import { tableHeadSellingPriceDetail } from "../../../utils/Constants";

function SellingPrice() {
  const navigate = useNavigate();
  const location = useLocation();
  const preselectedProductId = location.state?.productId;

  // State variables
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});

  const [branchList, setBranchList] = useState([]);
  const [searchBranchName, setSearchBranchName] = useState("");
  const [branchNameSelected, setBranchNameSelected] = useState(null);

  const [productList, setProductList] = useState([]);
  const [searchProductName, setSearchProductName] = useState("");
  const [productSelected, setProductSelected] = useState(null);

  const [detailSellingPrice, setDetailSellingPrice] = useState([]);

  // Fetch branch list
  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const res = await api.getBranch(searchBranchName);
        if (res.statusCode === 200) {
          setBranchList(res.data.data);
        } else {
          showError(res.message);
        }
      } catch (error) {
        showError(error.message);
      }
    };

    fetchBranches();
  }, [searchBranchName]);

  // Fetch products when branch is selected
  useEffect(() => {
    if (!branchNameSelected) return;

    const fetchProducts = async () => {
      setIsLoadingProducts(true);
      try {
        const res = await api.getProduct(searchProductName, "", 100, 1);
        if (res.statusCode === 200) {
          setProductList(res.data.data);

          // If we have a preselected product ID, select it
          if (preselectedProductId) {
            const product = res.data.data.find(
              (p) => p.masterProductCode === preselectedProductId
            );
            if (product) {
              setProductSelected(product);
              fetchVariantSellingPrice(product);
            }
          }
        } else {
          setProductList([]);
          showError(res.message);
        }
      } catch (error) {
        setProductList([]);
        showError(error.message);
      } finally {
        setIsLoadingProducts(false);
      }
    };

    fetchProducts();
  }, [branchNameSelected, searchProductName, preselectedProductId]);

  // Fetch variant selling price when product is selected
  const fetchVariantSellingPrice = async (product) => {
    setIsLoadingProducts(true);
    try {
      const res = await api.onGetVariantSelling(
        branchNameSelected.branchCode,
        product.masterProductCode
      );

      if (res.statusCode === 200) {
        const formattedData = res.data.map((item) => ({
          ...item,
          isBottleMandatory: item.isRequiredBottle,
          sellingPrice: parseInt(item.trxPrice),
          // grabPrice: parseInt(item.onlineTrxPrice),
        }));
        // alert(JSON.stringify(formattedData));
        setDetailSellingPrice(formattedData);
      } else {
        setDetailSellingPrice([]);
        showError(res.message);
      }
    } catch (error) {
      setDetailSellingPrice([]);
      showError(error.message);
    } finally {
      setIsLoadingProducts(false);
    }
  };

  // Handle selling price input change
  const handleSellingPriceChange = (event, index) => {
    const rawValue = event.target.value.replace(/[^\d]/g, "");
    const numericValue = rawValue ? parseInt(rawValue, 10) : "";

    const newPrices = [...detailSellingPrice];
    newPrices[index].sellingPrice = numericValue;
    setDetailSellingPrice(newPrices);
  };

  // Handle grab price input change
  const handleGrabPriceChange = (event, index) => {
    const rawValue = event.target.value.replace(/[^\d]/g, "");
    const numericValue = rawValue ? parseInt(rawValue, 10) : "";

    const newPrices = [...detailSellingPrice];
    newPrices[index].grabPrice = numericValue;
    setDetailSellingPrice(newPrices);
  };

  // Handle bottle requirement toggle
  const handleBottleRequirementChange = (index) => {
    const newPrices = [...detailSellingPrice];
    newPrices[index].isBottleMandatory = !newPrices[index].isBottleMandatory;
    setDetailSellingPrice(newPrices);
  };

  // Save selling prices
  const handleSave = async () => {
    // Validate form data
    const formData = {
      branchSelected: branchNameSelected,
      productSelected: productSelected,
      variants: detailSellingPrice,
    };

    const validationErrors = validatePricingForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setIsLoading(true);
    try {
      const priceData = detailSellingPrice.map((item) => ({
        productCode: item.productCode,
        trxPrice: item.sellingPrice,
        // onlineTrxPrice: item.grabPrice,
        withUpdateBasePrice: false,
        isRequiredBottle:
          item.isBottleMandatory !== "" ? item.isBottleMandatory : false,
      }));

      // alert(JSON.stringify(priceData));

      const res = await api.editSellingPrice(
        branchNameSelected.branchCode,
        productSelected.masterProductCode,
        priceData
      );

      if (res.statusCode === 200) {
        showSuccess("Selling prices updated successfully");
        setTimeout(() => {
          navigate("/products");
        }, 300);
      } else {
        throw new Error(res.message);
      }
    } catch (error) {
      showError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Error and success handling
  const showError = (msg) => {
    setIsOpen(true);
    setAlertType("error");
    setMessage(msg);
  };

  const showSuccess = (msg) => {
    setIsOpen(true);
    setAlertType("success");
    setMessage(msg);
  };

  return (
    <>
      <Spinner isShowSpinner={isLoading} />
      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />

      <Paper
        sx={{
          p: 3,
          mb: 3,
          borderRadius: 1,
          boxShadow: 1,
        }}
      >
        {/* Header with breadcrumbs */}
        <Box sx={{ mb: 3 }}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link
              color="inherit"
              onClick={() => navigate("/products")}
              sx={{ cursor: "pointer", textDecoration: "none" }}
            >
              Products
            </Link>
            <Typography color="text.primary">Setting Selling Price</Typography>
          </Breadcrumbs>

          <Typography variant="h6" sx={{ mt: 2 }}>
            Setting Selling Price
          </Typography>
        </Box>

        {/* Branch and Product selection */}
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <PickerSearch
              value={branchNameSelected}
              dataList={branchList}
              onInputChange={(event, newInputValue) => {
                setSearchBranchName(newInputValue);
              }}
              onChange={(e, v) => {
                setBranchNameSelected(v);
                setProductSelected(null);
                setDetailSellingPrice([]);
              }}
              getOptionLabel={(branch) => branch?.branchName || ""}
              labelPicker="Choose Branch / Outlet"
              placeholderPicker="search branch / outlet"
              sizePicker="large"
              isVisibleLabelPicker={true}
              error={!!errors.branchSelected}
              helperText={errors.branchSelected}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <PickerSearch
              disabled={!branchNameSelected}
              value={productSelected}
              dataList={productList}
              onInputChange={(event, newInputValue) => {
                setSearchProductName(newInputValue);
              }}
              onChange={(e, v) => {
                setProductSelected(v);
                if (v) fetchVariantSellingPrice(v);
              }}
              getOptionLabel={(product) => product?.productName || ""}
              labelPicker="Choose Product"
              placeholderPicker="search product"
              sizePicker="large"
              isVisibleLabelPicker={true}
              error={!!errors.productSelected}
              helperText={errors.productSelected}
            />
          </Grid>
        </Grid>

        {/* Price Details Table */}
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Product Pricing Details
          </Typography>

          <PricingForm
            detailSellingPrice={detailSellingPrice}
            isLoadingProducts={isLoadingProducts}
            handleSellingPriceChange={handleSellingPriceChange}
            // handleGrabPriceChange={handleGrabPriceChange}
            handleBottleRequirementChange={handleBottleRequirementChange}
            tableHeadSellingPriceDetail={tableHeadSellingPriceDetail}
          />
        </Box>

        {/* Action Buttons */}
        <Box sx={{ mt: 4, display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate("/products")}
          >
            Back to Products
          </Button>

          <Button
            variant="contained"
            startIcon={<SaveIcon />}
            onClick={handleSave}
            disabled={
              !productSelected ||
              !branchNameSelected ||
              detailSellingPrice.length === 0
            }
          >
            Save Selling Price
          </Button>
        </Box>
      </Paper>
    </>
  );
}

export default SellingPrice;
