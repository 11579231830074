// src/pages/purchases/detail/[id]/index.js
import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Paper,
  Typography,
  Grid,
  Button,
  Breadcrumbs,
  Link,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Card,
  CardContent,
  CircularProgress,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DownloadIcon from "@mui/icons-material/Download";
import { Spinner, CustomizedSnackbars } from "../../../../components";
import api from "../../../../helpers/api";
import { formatDate } from "../../utils/formatters";
import NumberFormat from "../../../../helpers/functions/numberFormat";

function PurchaseDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const purchaseFromState = location.state?.purchase;

  const [isLoading, setIsLoading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");

  const [purchaseDetail, setPurchaseDetail] = useState(null);

  useEffect(() => {
    const fetchPurchaseDetail = async () => {
      try {
        // If we already have the purchaseId from location state
        const purchaseId = purchaseFromState?.id || id;

        const response = await api.getDetailPurchase(purchaseId);
        if (response.statusCode === 200) {
          setPurchaseDetail(response.data);
        } else {
          showError(response.message);
        }
      } catch (error) {
        showError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPurchaseDetail();
  }, [id, purchaseFromState]);

  const handleDownloadPdf = async () => {
    if (!purchaseDetail?.id) return;

    setIsDownloading(true);
    try {
      const response = await api.downloadPurchasePdf(purchaseDetail.id);
      // Handle PDF download (implementation depends on your API)
      if (response.statusCode === 200) {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `purchase-order-${purchaseDetail.id}.pdf`
        );
        document.body.appendChild(link);
        link.click();

        // Clean up
        window.URL.revokeObjectURL(url);
        link.remove();
      } else {
        throw new Error(response.message || "Failed to download PDF");
      }
    } catch (error) {
      showError(error.message);
    } finally {
      setIsDownloading(false);
    }
  };

  const showError = (msg) => {
    setIsOpen(true);
    setAlertType("error");
    setMessage(msg);
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "70vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />

      <Paper
        sx={{
          p: 3,
          mb: 3,
          borderRadius: 1,
          boxShadow: 1,
        }}
      >
        {/* Header with breadcrumbs */}
        <Box
          sx={{
            mb: 3,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link
                color="inherit"
                onClick={() => navigate("/purchases")}
                sx={{ cursor: "pointer", textDecoration: "none" }}
              >
                Purchases
              </Link>
              <Typography color="text.primary">
                Purchase Order Detail
              </Typography>
            </Breadcrumbs>

            <Typography variant="h6" sx={{ mt: 1 }}>
              Purchase Order #{purchaseDetail?.purchaseNo || id}
            </Typography>
          </Box>

          <Button
            variant="outlined"
            startIcon={<DownloadIcon />}
            onClick={handleDownloadPdf}
            disabled={isDownloading}
          >
            {isDownloading ? "Downloading..." : "Download PDF"}
          </Button>
        </Box>

        {purchaseDetail ? (
          <>
            {/* Purchase Summary */}
            <Card sx={{ mb: 4 }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h6" gutterBottom>
                      Purchase Information
                    </Typography>

                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <Typography variant="body2" color="text.secondary">
                          Purchase Number
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2" fontWeight="medium">
                          {purchaseDetail.purchaseNo || "-"}
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant="body2" color="text.secondary">
                          Date
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2" fontWeight="medium">
                          {formatDate(purchaseDetail.transactionDate)}
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant="body2" color="text.secondary">
                          Branch
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2" fontWeight="medium">
                          {purchaseDetail.transactionToName || "-"}
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant="body2" color="text.secondary">
                          Supplier
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2" fontWeight="medium">
                          {purchaseDetail.transactionFromName || "-"}
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant="body2" color="text.secondary">
                          Status
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Chip
                          label={purchaseDetail.statusName || "Pending"}
                          color={
                            purchaseDetail.statusCode === "201"
                              ? "success"
                              : "primary"
                          }
                          size="small"
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography variant="h6" gutterBottom>
                      Summary
                    </Typography>

                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <Typography variant="body2" color="text.secondary">
                          Total Items
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2" fontWeight="medium">
                          {purchaseDetail.item || "0"}
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant="body2" color="text.secondary">
                          Total Amount
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2" fontWeight="medium">
                          Rp. {NumberFormat(0, purchaseDetail.netPayment)}
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant="body2" color="text.secondary">
                          Payment Type
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2" fontWeight="medium">
                          {purchaseDetail.paymentType || "-"}
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant="body2" color="text.secondary">
                          Note
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2" fontWeight="medium">
                          {purchaseDetail.note || "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            {/* Products Table */}
            <Typography variant="h6" sx={{ mb: 2 }}>
              Products
            </Typography>

            <TableContainer>
              <Table aria-label="products table">
                <TableHead>
                  <TableRow>
                    <TableCell>No</TableCell>
                    <TableCell>Product</TableCell>
                    <TableCell>Variant</TableCell>
                    <TableCell>SKU</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {purchaseDetail.attachments?.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        No products available
                      </TableCell>
                    </TableRow>
                  ) : (
                    purchaseDetail.attachments?.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{item.productName}</TableCell>
                        <TableCell>{item.variantName}</TableCell>
                        <TableCell>{item.productSku}</TableCell>
                        <TableCell>{NumberFormat(0, item.quantity)}</TableCell>
                        <TableCell>
                          Rp. {NumberFormat(0, item.trxPrice)}
                        </TableCell>
                        <TableCell>
                          Rp. {NumberFormat(0, item.netPrice)}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {/* Total Section */}
            <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
              <Card sx={{ minWidth: 300 }}>
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Typography variant="body2">Subtotal:</Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "right" }}>
                      <Typography variant="body2">
                        Rp. {NumberFormat(0, purchaseDetail.netPayment)}
                      </Typography>
                    </Grid>

                    {purchaseDetail.discount > 0 && (
                      <>
                        <Grid item xs={6}>
                          <Typography variant="body2">Discount:</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: "right" }}>
                          <Typography variant="body2">
                            Rp. {NumberFormat(0, purchaseDetail.discount)}
                          </Typography>
                        </Grid>
                      </>
                    )}

                    {purchaseDetail.taxCharge > 0 && (
                      <>
                        <Grid item xs={6}>
                          <Typography variant="body2">Tax:</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: "right" }}>
                          <Typography variant="body2">
                            Rp. {NumberFormat(0, purchaseDetail.taxCharge)}
                          </Typography>
                        </Grid>
                      </>
                    )}

                    <Grid item xs={12}>
                      <Divider sx={{ my: 1 }} />
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="subtitle2" fontWeight="bold">
                        Grand Total:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "right" }}>
                      <Typography variant="subtitle2" fontWeight="bold">
                        Rp. {NumberFormat(0, purchaseDetail.netPayment)}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </>
        ) : (
          <Typography variant="body1" color="text.secondary">
            Purchase order details not available
          </Typography>
        )}

        {/* Action Buttons */}
        <Box sx={{ mt: 4 }}>
          <Button
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate("/purchases")}
          >
            Back to Purchases
          </Button>
        </Box>
      </Paper>
    </>
  );
}

export default PurchaseDetail;
