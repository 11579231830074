// FormInput.js
import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { styled } from "@mui/material/styles";

// Custom styled TextField
const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: theme.shape.borderRadius,
  },
}));

const FormInput = ({
  label,
  name,
  error,
  helperText,
  type = "text",
  inputPrice,
  disabled = false,
  size = "small",
  currencySymbol = "Rp.",
  fullWidth = true,
  variant = "outlined",
  autoFocus = false,
  required = false,
  multiline = false,
  rows,
  maxRows,
  onChange,
  onBlur,
  value,
  ...props
}) => (
  <StyledTextField
    margin="normal"
    required={required}
    fullWidth={fullWidth}
    id={name}
    label={label}
    name={name}
    disabled={disabled}
    type={type}
    size={size}
    variant={variant}
    autoComplete={
      name === "email"
        ? "email"
        : name === "password"
        ? "current-password"
        : undefined
    }
    autoFocus={autoFocus || name === "username"}
    error={error}
    helperText={helperText}
    multiline={multiline}
    rows={rows}
    maxRows={maxRows}
    onChange={onChange}
    onBlur={onBlur}
    value={value}
    InputProps={{
      startAdornment: inputPrice && (
        <InputAdornment position="start">{currencySymbol}</InputAdornment>
      ),
    }}
    {...props}
  />
);

export default FormInput;
