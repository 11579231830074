// src/pages/stockin/utils/formatters.js
/**
 * Format a number with thousand separator
 * @param {number|string} num - The number to format
 * @param {string} defaultValue - Default value if num is empty
 * @returns {string} Formatted number string
 */
export const formatNumber = (num, defaultValue = "") => {
  if (num === "" || num === null || num === undefined) return defaultValue;
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

/**
 * Format currency in Indonesian Rupiah format
 * @param {number|string} amount - The amount to format
 * @param {string} defaultValue - Default value if amount is empty
 * @returns {string} Formatted currency string
 */
export const formatCurrency = (amount, defaultValue = "Rp 0") => {
  if (amount === "" || amount === null || amount === undefined)
    return defaultValue;
  return `Rp ${formatNumber(amount)}`;
};

/**
 * Format date to Indonesian format
 * @param {string|Date} date - Date to format
 * @param {string} defaultValue - Default value if date is invalid
 * @returns {string} Formatted date string
 */
export const formatDate = (date, defaultValue = "-") => {
  if (!date) return defaultValue;

  try {
    const d = new Date(date);
    if (isNaN(d.getTime())) return defaultValue;

    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();

    return `${day}/${month}/${year}`;
  } catch (error) {
    return defaultValue;
  }
};

/**
 * Format status code to readable text
 * @param {string} statusCode - Status code from API
 * @returns {string} Readable status text
 */
export const formatStatus = (statusCode) => {
  const statusMap = {
    201: "Active",
    301: "Inactive",
    // Add more status codes as needed
  };

  return statusMap[statusCode] || "Unknown";
};

/**
 * Truncate text if it exceeds maximum length
 * @param {string} text - Text to truncate
 * @param {number} maxLength - Maximum length before truncating
 * @returns {string} Truncated text
 */
export const truncateText = (text, maxLength = 50) => {
  if (!text) return "";

  if (text.length <= maxLength) return text;

  return text.substring(0, maxLength) + "...";
};

export default {
  formatNumber,
  formatCurrency,
  formatDate,
  formatStatus,
  truncateText,
};
