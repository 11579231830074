import React from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material";
import { FormInput, PickerSearch } from "../../../components";
import { statusList } from "../../../utils/Constants";

function BranchForm({ formData, setFormData, errors, setErrors }) {
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: undefined,
      });
    }
  };

  return (
    <Card sx={{ mb: 4 }}>
      <CardContent>
        <Typography variant="subtitle1" gutterBottom fontWeight="medium">
          Outlet Information
        </Typography>

        <Grid container spacing={3} sx={{ mt: 1 }}>
          <Grid item xs={12} md={6}>
            <FormInput
              label="Outlet Name"
              name="branchName"
              size="medium"
              value={formData.branchName}
              onChange={handleChange}
              error={!!errors.branchName}
              helperText={errors.branchName}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormInput
              label="PIC Name"
              name="picName"
              size="medium"
              value={formData.picName}
              onChange={handleChange}
              error={!!errors.picName}
              helperText={errors.picName}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <PickerSearch
              value={formData.status}
              dataList={statusList}
              onChange={(e, v) => {
                setFormData({
                  ...formData,
                  status: v || {},
                });

                // Clear error when status changes
                if (errors.status) {
                  setErrors({
                    ...errors,
                    status: undefined,
                  });
                }
              }}
              getOptionLabel={(statusItem) => statusItem.label || ""}
              labelPicker={"Choose Status"}
              placeholderPicker={"choose status"}
              sizePicker={"large"}
              isVisibleLabelPicker={true}
              labelTitlePicker={""}
              error={!!errors.status}
              helperText={errors.status}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormInput
              label="Phone"
              name="branchPhone"
              size="medium"
              value={formData.branchPhone}
              onChange={handleChange}
              error={!!errors.branchPhone}
              helperText={errors.branchPhone}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <FormInput
              label="Address"
              name="address"
              size="medium"
              value={formData.address}
              onChange={handleChange}
              error={!!errors.address}
              helperText={errors.address}
              multiline
              rows={4}
              required
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default BranchForm;
