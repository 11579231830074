import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {
  TableList,
  PickerSearch,
  FormDatePicker,
  Spinner,
} from "../../components";
import CustomizedSnackbars from "../../components/base/Snackbar";
import api from "../../helpers/api";
import { tableHeadProductProvit } from "../../utils/Constants";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import SearchIcon from "@mui/icons-material/Search";
import { exportToXLSX } from "../../helpers/functions/generateExcel";
import moment from "moment";
import { Box, Container, Paper, Stack } from "@mui/material";

function ProductProvit() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  // ==================
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("desc");

  // State for product profit data
  const [productProvitList, setProductProvitList] = useState(null);

  // State for branch data and filters
  const [branchList, setBranchList] = useState([]);
  const [searchBranchName, setSearchBranchName] = useState("");
  const [branchCodeSelected, setBranchCodeSelected] = useState("");

  // State for date filters
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);
  const [transactionCode, setTransactionType] = useState("");

  // Fetch product profit data
  const onGetProductProvit = async () => {
    setIsLoading(true);
    const currentPage = page + 1;
    const dateFromSelected =
      selectedDateFrom === null
        ? ""
        : moment(selectedDateFrom).format("YYYY-MM-DD");
    const dateToSelected =
      selectedDateTo === null
        ? ""
        : moment(selectedDateTo).format("YYYY-MM-DD");
    try {
      const res = await api.getProductProvit(
        searchBranchName,
        dateFromSelected,
        dateToSelected,
        rowsPerPage,
        currentPage
      );
      if (res.statusCode === 200) {
        setTotalRecord(res.data.totalPage * res.data.limit);
        setProductProvitList(res.data.data);
      } else {
        setProductProvitList([]);
        onError(res.message);
      }
    } catch (error) {
      setProductProvitList([]);
      onError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch branch data
  const onGetBranches = async () => {
    try {
      const res = await api.getBranch(searchBranchName);
      if (res.statusCode === 200) {
        const newBranch = {
          id: "",
          branchName: "All Branches",
          address: "",
          branchPhone: "",
          statusName: "",
          statusCode: "",
          branchCode: "",
          createdDate: "",
          updatedDate: "",
        };
        setBranchList([newBranch, ...res.data.data]);
      } else {
        onError(res.message);
      }
    } catch (error) {
      onError(error.message);
    }
  };

  // Download report
  const onHandleReport = async () => {
    setIsSpinner(true);
    const dateFromSelected =
      selectedDateFrom === null
        ? ""
        : moment(selectedDateFrom).format("YYYY-MM-DD");
    const dateToSelected =
      selectedDateTo === null
        ? ""
        : moment(selectedDateTo).format("YYYY-MM-DD");
    try {
      const res = await api.reportDownload(
        transactionCode,
        dateFromSelected,
        dateToSelected
      );
      if (res.statusCode === 200) {
        exportToXLSX(res.data.data, "product-profit-report");
        onSuccessSubmit();
      } else {
        onError(res.message);
      }
    } catch (error) {
      onError(error.message);
    } finally {
      setIsSpinner(false);
    }
  };

  useEffect(() => {
    onGetProductProvit();
  }, [page, rowsPerPage]);

  useEffect(() => {
    onGetBranches();
  }, [searchBranchName]);

  const onError = (message) => {
    setIsOpen(true);
    setAlertType("error");
    setMessage(message);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onSuccessSubmit = () => {
    setIsOpen(true);
    setAlertType("success");
    setMessage("Successfully...");
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";

    const sortedData = [...productProvitList].sort((a, b) => {
      // Use dynamic property access with bracket notation
      const valueA = a[property];
      const valueB = b[property];

      // Handle different types of sorting
      if (typeof valueA === "string") {
        return newOrder === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }

      return newOrder === "asc" ? valueA - valueB : valueB - valueA;
    });

    setProductProvitList(sortedData);
    setOrder(newOrder);
    setOrderBy(property);
  };

  return (
    <>
      <Spinner isShowSpinner={isSpinner} />
      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />

      <Box
        sx={{
          width: "100%",
          backgroundColor: "white",
          borderRadius: 1,
          border: "1px solid",
          borderColor: "divider",
        }}
      >
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            alignItems: "center",
            p: 3,
            borderBottom: "1px solid",
            borderColor: "divider",
          }}
        >
          <Typography variant="h6" sx={{ mb: { xs: 2, sm: 0 } }}>
            Product Profit List
          </Typography>

          <Button
            variant="outlined"
            startIcon={<DownloadForOfflineIcon />}
            onClick={onHandleReport}
          >
            Download Report
          </Button>
        </Box>

        {/* Search Section */}
        <Box sx={{ p: 3, borderBottom: "1px solid", borderColor: "divider" }}>
          <Grid
            container
            spacing={2}
            sx={{
              alignItems: "center",
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{
                px: 3,
                mb: 1,
                alignItems: "center",
              }}
            >
              <Grid item xs={12} sm={3}>
                <Stack spacing={1}>
                  <Typography variant="caption" sx={{ fontWeight: 600 }}>
                    CHOOSE OUTLET
                  </Typography>
                  <PickerSearch
                    dataList={branchList}
                    onInputChange={(event, newInputValue) => {
                      setSearchBranchName(newInputValue);
                    }}
                    onChange={(e, v) => {
                      if (v) {
                        setBranchCodeSelected(v.branchCode);
                      } else {
                        setBranchCodeSelected("");
                      }
                    }}
                    getOptionLabel={(branchList) => branchList.branchName || ""}
                    labelPicker="Choose Outlet"
                    placeholderPicker="search outlet"
                    sizePicker="small"
                    isVisibleLabelPicker={false}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={3}>
                <Stack spacing={1}>
                  <Typography variant="caption" sx={{ fontWeight: 600 }}>
                    DATE FROM
                  </Typography>
                  <FormDatePicker
                    labelFormDatePicker="Date From"
                    dateValue={selectedDateFrom}
                    onChangeDate={(val) => setSelectedDateFrom(val)}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={3}>
                <Stack spacing={1}>
                  <Typography variant="caption" sx={{ fontWeight: 600 }}>
                    DATE TO
                  </Typography>
                  <FormDatePicker
                    labelFormDatePicker="Date To"
                    dateValue={selectedDateTo}
                    onChangeDate={(val) => setSelectedDateTo(val)}
                  />
                </Stack>
              </Grid>

              <Grid
                item
                xs={12}
                sm={3}
                sx={{
                  display: "flex",
                  alignItems: "end",
                  height: "100%",
                }}
              >
                <Button
                  variant="contained"
                  startIcon={<SearchIcon />}
                  onClick={() => {
                    setPage(0);
                    onGetProductProvit();
                  }}
                  sx={{
                    width: "150px",
                    mt: { xs: 1, sm: 1, md: 3, lg: 3 },
                  }}
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        {/* Table List */}
        <TableList
          tableStructure="best-seller-products"
          tableName="Product Profit"
          orderBy={orderBy}
          order={order}
          isLoading={isLoading}
          isShowPagination={true}
          tableHeadList={tableHeadProductProvit}
          data={productProvitList}
          rowsPerPage={rowsPerPage}
          page={page}
          totalRecord={totalRecord}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          // onPreviewDetail={handlePreviewDetail}
          handleRequestSort={handleRequestSort}
        />
      </Box>
    </>
  );
}

export default ProductProvit;
