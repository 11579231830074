// src/pages/stockin/utils/validation.js
/**
 * Validates stock in form data
 * @param {Object} formData - Form data to validate
 * @returns {Object} Object containing validation errors
 */
export const validateStockInForm = (formData) => {
  const errors = {};

  // Validate branch selection
  if (!formData.branchNameSelected) {
    errors.branchNameSelected = "Branch/outlet is required";
  }

  // Validate date
  if (!formData.dateStockIn) {
    errors.dateStockIn = "Date must be filled";
  }

  // Validate products
  if (formData.productOrders && formData.productOrders.length > 0) {
    // Check if any product doesn't have a product or variant selection
    const hasEmptyProducts = formData.productOrders.some(
      (product) => !product.masterProductCode || !product.variantName
    );

    if (hasEmptyProducts) {
      errors.productOrders =
        "All products must have product and variant selected";
    }

    // Check if any product doesn't have quantity
    const hasEmptyQuantities = formData.productOrders.some(
      (product) => !product.quantity || product.quantity <= 0
    );

    if (hasEmptyQuantities) {
      errors.quantityError = "All products must have a valid quantity";
    }

    // Check for duplicate products
    const productCodes = formData.productOrders.map((p) => p.productCode);
    const hasDuplicates = productCodes.some(
      (code, index) => code && productCodes.indexOf(code) !== index
    );

    if (hasDuplicates) {
      errors.duplicateProducts = "Duplicate products are not allowed";
    }
  } else if (!formData.productOrders || formData.productOrders.length === 0) {
    errors.productOrders = "At least one product must be added";
  }

  return errors;
};

/**
 * Validates product selection for stock in
 * @param {Object} product - Product data to validate
 * @returns {Object} Object containing validation errors
 */
export const validateProduct = (product) => {
  const errors = {};

  if (!product.masterProductCode) {
    errors.masterProductCode = "Product must be selected";
  }

  if (!product.variantName) {
    errors.variantName = "Variant must be selected";
  }

  if (!product.quantity || product.quantity <= 0) {
    errors.quantity = "Quantity must be greater than 0";
  }

  return errors;
};

export default {
  validateStockInForm,
  validateProduct,
};
