import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const useIdleTimer = (timeout = 900000) => {
  const navigate = useNavigate();
  const [lastActivity, setLastActivity] = useState(Date.now());

  const handleActivity = () => {
    setLastActivity(Date.now());
  };

  const checkIdle = () => {
    const now = Date.now();
    const timeSinceLastActivity = now - lastActivity;

    if (timeSinceLastActivity > timeout) {
      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("role");
      navigate("/login");

      // Opsional: tampilkan pesan
      //   alert("Sesi Anda telah berakhir karena tidak ada aktivitas");
    }
  };

  useEffect(() => {
    const events = [
      "mousedown",
      "mousemove",
      "keydown",
      "scroll",
      "touchstart",
      "click",
      "keypress",
    ];

    events.forEach((event) => {
      window.addEventListener(event, handleActivity);
    });

    const idleInterval = setInterval(checkIdle, 10000);

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, handleActivity);
      });

      clearInterval(idleInterval);
    };
  }, [lastActivity]);

  return lastActivity;
};

export default useIdleTimer;
