// src/pages/purchases/components/ProductSelector.js
import React, { useState, useEffect } from "react";
import { Grid, Box, CircularProgress } from "@mui/material";
import { PickerSearch } from "../../../components";
import api from "../../../helpers/api";

const ProductSelector = ({
  index,
  productOrder,
  productNameSelected,
  variantNameSelected,
  onProductSelected,
  onVariantSelected,
  disabled = false,
  branchCode = "",
}) => {
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const [isLoadingVariants, setIsLoadingVariants] = useState(false);
  const [productList, setProductList] = useState([]);
  const [variantList, setVariantList] = useState([]);
  const [searchProductName, setSearchProductName] = useState("");
  const [searchVariantName, setSearchVariantName] = useState("");

  // Fetch products based on search term or branch change
  useEffect(() => {
    const fetchProducts = async () => {
      // Jangan kembalikan jika branchCode ada, meskipun searchProductName kosong
      if (!branchCode) return;

      setIsLoadingProducts(true);
      try {
        // Hapus alert debug
        const response = await api.getProduct(
          searchProductName, // Bisa kosong
          branchCode,
          20,
          1
        );
        if (response.statusCode === 200) {
          setProductList(response.data.data);
        } else {
          setProductList([]);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
        setProductList([]);
      } finally {
        setIsLoadingProducts(false);
      }
    };

    fetchProducts();
  }, [searchProductName, branchCode]);

  // Fetch variants when a product is selected
  const fetchVariants = async (masterProductCode) => {
    if (!masterProductCode) return;

    setIsLoadingVariants(true);
    try {
      const response = await api.getProductVariantList(masterProductCode);
      if (response.statusCode === 200) {
        setVariantList(response.data.data || []);
      } else {
        setVariantList([]);
      }
    } catch (error) {
      console.error("Error fetching variants:", error);
      setVariantList([]);
    } finally {
      setIsLoadingVariants(false);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Box sx={{ position: "relative" }}>
          <PickerSearch
            disabled={disabled}
            value={productNameSelected?.productItemSelected}
            dataList={productList}
            onInputChange={(event, newInputValue) => {
              setSearchProductName(newInputValue);
            }}
            onChange={(e, v) => {
              if (v) {
                onProductSelected(index, v);
                fetchVariants(v.masterProductCode);
              } else {
                onProductSelected(index, null);
              }
            }}
            getOptionLabel={(product) => product?.productName || ""}
            labelPicker="Choose Product"
            placeholderPicker="search product"
            sizePicker="small"
            isVisibleLabelPicker={true}
          />
          {isLoadingProducts && (
            <CircularProgress
              size={20}
              sx={{
                position: "absolute",
                right: 30,
                top: "50%",
                marginTop: "-10px",
              }}
            />
          )}
        </Box>
      </Grid>

      <Grid item xs={12} md={6}>
        <Box sx={{ position: "relative" }}>
          <PickerSearch
            disabled={disabled || !productOrder?.masterProductCode}
            value={variantNameSelected?.variantSelected}
            dataList={variantList}
            onInputChange={(event, newInputValue) => {
              setSearchVariantName(newInputValue);
            }}
            onChange={(e, v) => {
              if (v) {
                onVariantSelected(index, v);
              } else {
                onVariantSelected(index, null);
              }
            }}
            getOptionLabel={(variant) => variant?.variantName || ""}
            labelPicker="Choose Variant"
            placeholderPicker="search variant"
            sizePicker="small"
            isVisibleLabelPicker={true}
            onOpen={() => {
              if (productOrder?.masterProductCode) {
                fetchVariants(productOrder.masterProductCode);
              }
            }}
          />
          {isLoadingVariants && (
            <CircularProgress
              size={20}
              sx={{
                position: "absolute",
                right: 30,
                top: "50%",
                marginTop: "-10px",
              }}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ProductSelector;
