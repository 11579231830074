import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {
  TableList,
  Spinner,
  FormInput,
  PickerSearch,
  PopupDelete,
} from "../../components";
import CustomizedSnackbars from "../../components/base/Snackbar";
import { tableHeadUser } from "../../utils/Constants";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Stack } from "@mui/material";

// Import custom hook
import useUserData from "./hooks/useUserData";

function Users() {
  const navigate = useNavigate();

  // Use custom hook for user data management
  const {
    isLoading,
    isSpinner,
    userList,
    branchList,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    totalRecord,
    orderBy,
    order,
    searchBranchName,
    searchUsername,
    searchFullname,
    selectedBranchName,
    setSearchBranchName,
    setSearchUsername,
    setSearchFullname,
    setSelectedBranchName,
    handleRequestSort,
    fetchUsers,
    fetchBranches,
    deleteUser,
    setIsReload,
  } = useUserData();

  // Local state for user selection and alerts
  const [userSelected, setUserSelected] = useState({});
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");

  // Load data when component mounts
  useEffect(() => {
    fetchUsers();
  }, [page, rowsPerPage]);

  useEffect(() => {
    fetchBranches();
  }, [searchBranchName]);

  // Utility functions
  const showSuccess = (msg) => {
    setIsOpen(true);
    setAlertType("success");
    setMessage(msg);
  };

  const showError = (msg) => {
    setIsOpen(true);
    setAlertType("error");
    setMessage(msg);
  };

  // Handler untuk navigasi ke halaman tambah user
  const handleAddUser = () => {
    navigate("/users/add");
  };

  // Handler untuk navigasi ke halaman edit user
  const handleEditUser = (item) => {
    if (!item || !item.id) {
      showError("Invalid user data");
      return;
    }

    // Kirim data user lengkap melalui state navigasi
    navigate(`/users/edit/${item.id}`, {
      state: { userData: item },
    });
  };

  // Handler untuk menampilkan dialog hapus
  const handleDeleteUser = (item) => {
    setUserSelected(item || {});
    setIsDeleteOpen(true);
  };

  // Handler untuk konfirmasi penghapusan user
  const confirmDeleteUser = async () => {
    try {
      if (!userSelected || !userSelected.id) {
        showError("Invalid user data");
        setIsDeleteOpen(false);
        return;
      }

      setIsDeleteOpen(false); // Close delete popup immediately

      // Call delete API
      await deleteUser(userSelected);

      // Show success message
      showSuccess("User deleted successfully");

      // Refresh the list
      setIsReload(true);
    } catch (error) {
      showError(error?.message || "Failed to delete user");
    }
  };

  // Pagination handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Search handler
  const handleSearch = () => {
    setPage(0);
    setIsReload(true);
  };

  return (
    <>
      <Spinner isShowSpinner={isSpinner} />
      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />

      <Box
        sx={{
          width: "100%",
          backgroundColor: "white",
          borderRadius: 1,
          border: "1px solid",
          borderColor: "divider",
        }}
      >
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            alignItems: "center",
            p: 3,
            borderBottom: "1px solid",
            borderColor: "divider",
          }}
        >
          <Typography variant="h6" sx={{ mb: { xs: 2, sm: 0 } }}>
            Users List
          </Typography>

          <Button
            variant="outlined"
            startIcon={<AddCircleIcon />}
            onClick={handleAddUser}
          >
            Add New User
          </Button>
        </Box>

        {/* Search Section */}
        <Box sx={{ p: 3, borderBottom: "1px solid", borderColor: "divider" }}>
          <Grid
            container
            spacing={2}
            sx={{
              alignItems: "center",
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{
                px: 3,
                mb: 1,
                alignItems: "center",
              }}
            >
              <Grid item xs={12} sm={3}>
                <Stack spacing={1}>
                  <Typography variant="caption" sx={{ fontWeight: 600 }}>
                    CHOOSE OUTLET
                  </Typography>
                  <PickerSearch
                    dataList={branchList}
                    onInputChange={(event, newInputValue) => {
                      setSearchBranchName(newInputValue);
                    }}
                    onChange={(e, v) => {
                      if (v) {
                        setSelectedBranchName(v.branchCode);
                        setSearchBranchName("");
                      } else {
                        setSelectedBranchName("");
                        setSearchBranchName("");
                        setIsReload(true);
                      }
                    }}
                    getOptionLabel={(branchList) => branchList.branchName || ""}
                    labelPicker={"Choose Outlet"}
                    placeholderPicker={"search outlet"}
                    sizePicker={"small"}
                    isVisibleLabelPicker={false}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={3}>
                <Stack spacing={1}>
                  <Typography variant="caption" sx={{ fontWeight: 600 }}>
                    USERNAME / EMAIL
                  </Typography>
                  <FormInput
                    size="small"
                    label="Search Username/Email"
                    name="searchUsername"
                    value={searchUsername}
                    onChange={(event) => setSearchUsername(event.target.value)}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={3}>
                <Stack spacing={1}>
                  <Typography variant="caption" sx={{ fontWeight: 600 }}>
                    EMPLOYEE NAME
                  </Typography>
                  <FormInput
                    size="small"
                    label="Search Employee Name"
                    name="searchFullname"
                    value={searchFullname}
                    onChange={(event) => setSearchFullname(event.target.value)}
                  />
                </Stack>
              </Grid>

              <Grid
                item
                xs={12}
                sm={3}
                sx={{
                  display: "flex",
                  alignItems: "end",
                  height: "100%",
                }}
              >
                <Button
                  variant="contained"
                  startIcon={<SearchIcon />}
                  onClick={handleSearch}
                  sx={{
                    width: "150px",
                    mt: { xs: 1, sm: 1, md: 3, lg: 3 },
                  }}
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        {/* Table List */}
        <TableList
          tableStructure="user-list"
          tableName={"User List"}
          orderBy={orderBy}
          order={order}
          isLoading={isLoading}
          isShowPagination={true}
          tableHeadList={tableHeadUser}
          data={userList}
          rowsPerPage={rowsPerPage}
          page={page}
          totalRecord={totalRecord}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          onEditData={handleEditUser}
          onDeleteData={handleDeleteUser}
          handleRequestSort={handleRequestSort}
        />
      </Box>

      <PopupDelete
        itemName={userSelected?.username || userSelected?.userFullname || ""}
        visible={isDeleteOpen}
        onClose={() => setIsDeleteOpen(false)}
        onCancel={() => setIsDeleteOpen(false)}
        onDelete={confirmDeleteUser}
      />
    </>
  );
}

export default Users;
