// src/pages/products/utils/validation.js
/**
 * Validates product form data
 * @param {Object} formData - Form data to validate
 * @returns {Object} Object containing validation errors
 */
export const validateProductForm = (formData) => {
  const errors = {};

  // Validate product name
  if (!formData.productNameInput || formData.productNameInput.trim() === "") {
    errors.productNameInput = "Product name is required";
  } else if (formData.productNameInput.length > 100) {
    errors.productNameInput = "Product name must be less than 100 characters";
  }

  // Validate category
  if (!formData.categoryNameSelected) {
    errors.categoryNameSelected = "Category is required";
  }

  // Validate variants if any
  if (formData.variantList && formData.variantList.length > 0) {
    // Check if any variant has empty required fields
    const hasEmptyVariantFields = formData.variantList.some(
      (variant) =>
        !variant.variantName || !variant.productSku || !variant.defaultTrxPrice
    );

    if (hasEmptyVariantFields) {
      errors.variantList = "All variants must have Name, SKU, and Price filled";
    }

    // Check for duplicate variant names
    const variantNames = formData.variantList.map((v) => v.variantName);
    const hasDuplicateNames = variantNames.some(
      (name, index) => variantNames.indexOf(name) !== index
    );

    if (hasDuplicateNames) {
      errors.variantList = errors.variantList || "Variant names must be unique";
    }

    // Check for duplicate SKUs
    const skus = formData.variantList.map((v) => v.productSku);
    const hasDuplicateSkus = skus.some(
      (sku, index) => skus.indexOf(sku) !== index
    );

    if (hasDuplicateSkus) {
      errors.variantList = errors.variantList || "SKUs must be unique";
    }
  }

  return errors;
};

/**
 * Validates pricing form data
 * @param {Object} formData - Form data to validate
 * @returns {Object} Object containing validation errors
 */
export const validatePricingForm = (formData) => {
  const errors = {};

  // Validate branch selection
  if (!formData.branchSelected) {
    errors.branchSelected = "Branch/outlet is required";
  }

  // Validate product selection
  if (!formData.productSelected) {
    errors.productSelected = "Product is required";
  }

  // Validate variants pricing
  if (formData.variants && formData.variants.length > 0) {
    // Check if any variant has empty price
    const hasEmptyPrices = formData.variants.some(
      (variant) => !variant.sellingPrice
    );

    if (hasEmptyPrices) {
      errors.variants = "All variants must have a selling price";
    }

    // Check for negative prices
    const hasNegativePrices = formData.variants.some(
      (variant) => variant.sellingPrice < 0
    );

    if (hasNegativePrices) {
      errors.variants = errors.variants || "Selling prices cannot be negative";
    }
  }

  return errors;
};

export default {
  validateProductForm,
  validatePricingForm,
};
