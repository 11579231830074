// src/pages/products/components/ProductForm.js
import React from "react";
import {
  Grid,
  Typography,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@mui/material";
import { FormInput, PickerSearch } from "../../../components";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { BsFillTrash3Fill } from "react-icons/bs";
import {
  CloudCircleOutlined,
  DownloadDoneSharp,
  DownloadRounded,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import * as XLSX from "xlsx";
import { tableHeadVariant } from "../../../utils/Constants";
import { formatNumber } from "../utils/formatters";
import { saveAs } from "file-saver";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ProductForm = ({
  formData,
  onChange,
  categoryList,
  searchCategoryName,
  setSearchCategoryName,
  errors,
  isEditMode = false,
  onVariantListUpdate,
}) => {
  // ============
  const handleUploadFile = (e) => {
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);

        // Gunakan fungsi onChange yang sudah ada
        onChange("variantList", json);
      };
      reader.readAsArrayBuffer(e.target.files[0]);

      // Reset input file agar user bisa upload file yang sama berulang kali
      e.target.value = null;
    }
  };

  const addVariant = () => {
    if (formData.productNameInput !== "" && formData.categoryNameSelected) {
      onChange("variantList", [
        ...formData.variantList,
        {
          productCode: "",
          variantName: "",
          productSku: "",
          defaultTrxPrice: "",
          isCanDelete: true,
        },
      ]);
    }
  };

  const downloadTemplate = () => {
    // Buat workbook dan worksheet baru
    const wb = XLSX.utils.book_new();
    const templateData = [
      {
        productCode: "",
        variantName: "Contoh Varian 1",
        productSku: "SKU001",
        defaultTrxPrice: 15000,
        onlineTrxPrice: 20000,
      },
    ];

    // Buat worksheet dari data template
    const ws = XLSX.utils.json_to_sheet(templateData);

    // Tambahkan worksheet ke workbook
    XLSX.utils.book_append_sheet(wb, ws, "Template Varian");

    // Konversi workbook ke array buffer
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    // Buat Blob dan simpan file
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "template_varian_produk.xlsx");
  };

  const handleVariantChange = (event, index) => {
    const { name, value } = event.target;
    const newVariants = [...formData.variantList];

    if (name === "defaultTrxPrice") {
      const numericValue = value.replace(/\./g, "");
      newVariants[index][name] = numericValue ? parseInt(numericValue, 10) : "";
    } else {
      newVariants[index][name] = value;
    }

    onChange("variantList", newVariants);
  };

  const removeVariant = (index) => {
    const newArray = [...formData.variantList];
    newArray.splice(index, 1);
    onChange("variantList", newArray);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormInput
            size="medium"
            label="Product Name"
            name="productNameInput"
            disabled={isEditMode && formData.productNameInput !== ""}
            error={!!errors.productNameInput}
            helperText={errors.productNameInput}
            value={formData.productNameInput}
            onChange={(event) =>
              onChange("productNameInput", event.target.value)
            }
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <PickerSearch
            value={formData.categoryNameSelected}
            dataList={categoryList}
            onInputChange={(event, newInputValue) => {
              setSearchCategoryName(newInputValue);
            }}
            onChange={(e, v) => {
              onChange("categoryNameSelected", v || null);
            }}
            getOptionLabel={(categoryList) => categoryList.categoryName || ""}
            labelPicker="Choose Category"
            placeholderPicker="search category"
            sizePicker="large"
            isVisibleLabelPicker={true}
            labelTitlePicker=""
            error={!!errors.categoryNameSelected}
            helperText={errors.categoryNameSelected}
          />
        </Grid>

        <Grid item xs={12}>
          <FormInput
            label="Note"
            name="note"
            multiline
            rows={3}
            error={!!errors.note}
            helperText={errors.note}
            value={formData.note}
            onChange={(event) => onChange("note", event.target.value)}
          />
        </Grid>
      </Grid>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">Product Variants</Typography>

        <Box sx={{ mt: 2, mb: 2 }}>
          <Button
            variant="outlined"
            onClick={addVariant}
            startIcon={<AddCircleIcon />}
            sx={{ mr: 2 }}
            disabled={
              !formData.productNameInput || !formData.categoryNameSelected
            }
          >
            Add Variant
          </Button>

          <Button
            variant="outlined"
            sx={{ mr: 2 }}
            onClick={downloadTemplate}
            startIcon={<DownloadRounded />}
          >
            Download Template Format
          </Button>

          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudCircleOutlined />}
            disabled={
              !formData.productNameInput || !formData.categoryNameSelected
            }
          >
            Import Variant Product
            <VisuallyHiddenInput type="file" onChange={handleUploadFile} />
          </Button>
        </Box>

        <TableContainer>
          <Table aria-label="variant table">
            <TableHead>
              <TableRow>
                {tableHeadVariant.map((item) => (
                  <TableCell
                    key={item.name}
                    sx={{
                      fontWeight: "bold",
                      backgroundColor: "#060270",
                      color: "white",
                    }}
                  >
                    {item.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {formData.variantList.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={tableHeadVariant.length}
                    align="center"
                    sx={{ py: 4 }}
                  >
                    <Grid
                      container
                      spacing={2}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item>
                        <AddCircleIcon color="secondary" fontSize="large" />
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="body1"
                          sx={{ color: "text.secondary" }}
                        >
                          No variant found. You can add a new variant using the
                          button above.
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              ) : (
                formData.variantList.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      <FormInput
                        label="Variant Name"
                        name="variantName"
                        size="small"
                        error={!!errors.variantName}
                        helperText={errors.variantName}
                        value={item.variantName}
                        onChange={(event) => handleVariantChange(event, index)}
                      />
                    </TableCell>
                    <TableCell>
                      <FormInput
                        label="Product SKU"
                        name="productSku"
                        size="small"
                        error={!!errors.productSku}
                        helperText={errors.productSku}
                        value={item.productSku}
                        onChange={(event) => handleVariantChange(event, index)}
                      />
                    </TableCell>
                    <TableCell>
                      <FormInput
                        label="Basic Offline Price"
                        name="defaultTrxPrice"
                        size="small"
                        type="text"
                        error={!!errors.defaultTrxPrice}
                        helperText={errors.defaultTrxPrice}
                        value={formatNumber(item.defaultTrxPrice)}
                        onChange={(event) => {
                          const formattedValue = event.target.value.replace(
                            /\D/g,
                            ""
                          );
                          event.target.value = formattedValue;
                          handleVariantChange(event, index);
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              Rp.
                            </InputAdornment>
                          ),
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <FormInput
                        label="Basic Online Price"
                        name="onlineTrxPrice"
                        size="small"
                        type="text"
                        error={!!errors.onlineTrxPrice}
                        helperText={errors.onlineTrxPrice}
                        value={formatNumber(item.onlineTrxPrice)}
                        onChange={(event) => {
                          const formattedValue = event.target.value.replace(
                            /\D/g,
                            ""
                          );
                          event.target.value = formattedValue;
                          handleVariantChange(event, index);
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              Rp.
                            </InputAdornment>
                          ),
                        }}
                      />
                    </TableCell>
                    {item.isCanDelete ? (
                      <TableCell>
                        <Tooltip title="Delete Variant">
                          <IconButton
                            onClick={() => removeVariant(index)}
                            size="medium"
                            sx={{
                              width: 30,
                              height: 30,
                              borderRadius: 1,
                              backgroundColor: "#f4e6fb",
                              color: "#FE3133",
                            }}
                          >
                            <BsFillTrash3Fill />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    ) : (
                      <TableCell />
                    )}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default ProductForm;
