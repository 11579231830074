// src/pages/stockout/add/index.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Paper,
  Typography,
  Grid,
  Button,
  Breadcrumbs,
  Link,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Spinner,
  CustomizedSnackbars,
  FormInput,
  FormDatePicker,
  PickerSearch,
} from "../../../components";
import { useBranches } from "../hooks/useBranches";
import ProductTable from "../stockOutComponent/ProductTable";
import { validateStockOutForm } from "../utils/validation";
import api from "../../../helpers/api";
import moment from "moment";

function AddStockOut() {
  const navigate = useNavigate();
  const { branchList, searchBranchName, setSearchBranchName } = useBranches();

  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});

  // Form state
  const [branchNameSelected, setBranchNameSelected] = useState(null);
  const [dateStockOut, setDateStockOut] = useState(null);
  const [note, setNote] = useState("");
  const [productOrders, setProductOrders] = useState([]);

  // Additional state needed for stock out
  const [additional, setAdditional] = useState({
    serviceCharge: "0",
    taxCharge: "0",
    taxResto: "0",
    cashPayment: 0,
    edcPayment: 0,
    rounding: 0,
    netPayment: 0,
    discount: 0,
    additionalCost: 0,
    paymentType: "Cash",
    paymentTypeCode: 456,
    transactionType: "STOCK OUT",
    transactionTypeCode: 313,
  });

  const handleAddProduct = () => {
    setProductOrders([
      ...productOrders,
      {
        note: "",
        brandId: 0,
        masterProductCode: "",
        variantName: "",
        imageUrl: "",
        netPrice: 0,
        quantity: 0,
        trxPrice: 0,
        brandName: "",
        productId: "",
        categoryId: 0,
        productSku: "",
        productCode: "",
        productName: "",
        categoryName: "",
        updatedQuantity: 0,
        defaultTrxPrice: "",
        basePrice: "",
      },
    ]);
  };

  const handleRemoveProduct = (index) => {
    const newArray = [...productOrders];
    newArray.splice(index, 1);
    setProductOrders(newArray);
  };

  const handleSaveStockOut = async () => {
    // Validate form
    const validationErrors = validateStockOutForm({
      branchNameSelected,
      dateStockOut,
      productOrders,
    });

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setIsLoading(true);
    try {
      const formattedDate = moment(dateStockOut).format("YYYY-MM-DD");
      const sumTotal = productOrders.reduce(
        (accumulator, current) => accumulator + parseInt(current.netPrice),
        0
      );

      const response = await api.saveStockOut(
        formattedDate,
        productOrders.length,
        note,
        additional.taxCharge,
        additional.discount,
        additional.rounding,
        additional.taxResto,
        additional.edcPayment,
        sumTotal,
        sumTotal,
        additional.paymentType,
        additional.serviceCharge,
        additional.additionalCost,
        additional.paymentTypeCode,
        additional.transactionType,
        branchNameSelected.branchCode,
        branchNameSelected.branchName,
        additional.transactionTypeCode,
        productOrders
      );

      if (response.statusCode === 200) {
        showSuccess("Stock out saved successfully");
        // Navigate back after short delay
        setTimeout(() => {
          navigate("/stockout");
        }, 300);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      showError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const showError = (msg) => {
    setIsOpen(true);
    setAlertType("error");
    setMessage(msg);
  };

  const showSuccess = (msg) => {
    setIsOpen(true);
    setAlertType("success");
    setMessage(msg);
  };

  return (
    <>
      <Spinner isShowSpinner={isLoading} />
      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />

      <Paper
        sx={{
          p: 3,
          mb: 3,
          borderRadius: 1,
          boxShadow: 1,
        }}
      >
        {/* Header with breadcrumbs */}
        <Box sx={{ mb: 3 }}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link
              color="inherit"
              onClick={() => navigate("/stockout")}
              sx={{ cursor: "pointer", textDecoration: "none" }}
            >
              Stock Out
            </Link>
            <Typography color="text.primary">Add New Stock Out</Typography>
          </Breadcrumbs>

          <Typography variant="h6" sx={{ mt: 2 }}>
            Add New Stock Out
          </Typography>
        </Box>

        {/* Branch Selection */}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <PickerSearch
              value={branchNameSelected}
              dataList={branchList}
              onInputChange={(event, newInputValue) => {
                setSearchBranchName(newInputValue);
              }}
              onChange={(e, v) => {
                setBranchNameSelected(v);
                if (errors.branchNameSelected) {
                  setErrors({ ...errors, branchNameSelected: null });
                }
              }}
              getOptionLabel={(branch) => branch?.branchName || ""}
              labelPicker="Choose Branch / Outlet"
              placeholderPicker="search branch / outlet"
              sizePicker="large"
              isVisibleLabelPicker={true}
              error={!!errors.branchNameSelected}
              helperText={errors.branchNameSelected}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormDatePicker
              labelFormDatePicker="Date Stock Out"
              formSize="large"
              dateValue={dateStockOut}
              onChangeDate={(val) => {
                setDateStockOut(val);
                if (errors.dateStockOut) {
                  setErrors({ ...errors, dateStockOut: null });
                }
              }}
              error={!!errors.dateStockOut}
              helperText={errors.dateStockOut}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              label="Note"
              name="note"
              multiline
              rows={3}
              value={note}
              onChange={(event) => setNote(event.target.value)}
            />
          </Grid>
        </Grid>

        {/* Product Selection */}
        <Box sx={{ mt: 4 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <Typography variant="h6">Products</Typography>
            <Button
              variant="outlined"
              startIcon={<AddCircleIcon />}
              onClick={handleAddProduct}
            >
              Add Product
            </Button>
          </Box>

          <ProductTable
            productOrders={productOrders}
            setProductOrders={setProductOrders}
            onRemoveProduct={handleRemoveProduct}
            errors={errors}
          />
        </Box>

        {/* Action Buttons */}
        <Box sx={{ mt: 4, display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate("/stockout")}
          >
            Back to Stock Out List
          </Button>

          <Button
            variant="contained"
            startIcon={<SaveIcon />}
            onClick={handleSaveStockOut}
          >
            Save Stock Out
          </Button>
        </Box>
      </Paper>
    </>
  );
}

export default AddStockOut;
