import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Paper,
  Typography,
  Button,
  Breadcrumbs,
  Link,
  CircularProgress,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import { Spinner, CustomizedSnackbars } from "../../../../components";

// Import components, hooks and utils
import SupplierForm from "../../SupplierComponent/SupplierForm";
import { useSupplierData } from "../../hooks/useSupplierData";
import { validateSupplierForm } from "../../utils/validation";

function EditSupplier() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const supplierFromState = location.state?.supplier;

  // Use custom hook for API functions
  const { getSupplierById, updateSupplier } = useSupplierData();

  // Loading state
  const [isLoading, setIsLoading] = useState(!supplierFromState);
  const [isSpinner, setIsSpinner] = useState(false);

  // Form state
  const [formData, setFormData] = useState({
    supplierName: supplierFromState?.supplierName || "",
    supplierContacName: supplierFromState?.supplierContacName || "",
    supplierPhone: supplierFromState?.supplierPhone || "",
    supplierEmail: supplierFromState?.supplierEmail || "",
    supplierAddress: supplierFromState?.supplierAddress || "",
  });
  const [errors, setErrors] = useState({});

  // Alert states
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");

  // Load supplier data if not available from state
  useEffect(() => {
    const loadSupplierData = async () => {
      if (!supplierFromState) {
        setIsLoading(true);
        try {
          const supplier = await getSupplierById(id);

          if (supplier) {
            setFormData({
              supplierName: supplier.supplierName || "",
              supplierContacName: supplier.supplierContacName || "",
              supplierPhone: supplier.supplierPhone || "",
              supplierEmail: supplier.supplierEmail || "",
              supplierAddress: supplier.supplierAddress || "",
            });
          } else {
            showError("Supplier not found");
            // Navigate back if supplier not found
            setTimeout(() => {
              navigate("/supplier");
            }, 300);
          }
        } catch (error) {
          showError(error?.message || "Failed to load supplier");
          setTimeout(() => {
            navigate("/supplier");
          }, 300);
        } finally {
          setIsLoading(false);
        }
      }
    };

    loadSupplierData();
  }, [id]);

  // Handle form submission
  const handleSubmit = async () => {
    // Validate form
    const validationErrors = validateSupplierForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setIsSpinner(true);

    try {
      const result = await updateSupplier(id, formData);

      if (result.success) {
        showSuccess("Supplier updated successfully");

        // Navigate back to suppliers list after short delay
        setTimeout(() => {
          navigate("/supplier");
        }, 300);
      } else {
        showError(result.error || "Failed to update supplier");
      }
    } catch (error) {
      showError(error?.message || "An error occurred");
    } finally {
      setIsSpinner(false);
    }
  };

  // Utility functions
  const showSuccess = (msg) => {
    setIsOpen(true);
    setAlertType("success");
    setMessage(msg);
  };

  const showError = (msg) => {
    setIsOpen(true);
    setAlertType("error");
    setMessage(msg);
  };

  // Loading indicator
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "70vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Spinner isShowSpinner={isSpinner} />
      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />

      <Paper
        sx={{
          p: 3,
          mb: 3,
          borderRadius: 1,
          boxShadow: 1,
        }}
      >
        {/* Header */}
        <Box
          sx={{
            mb: 3,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link
                color="inherit"
                onClick={() => navigate("/supplier")}
                sx={{ cursor: "pointer", textDecoration: "none" }}
              >
                Suppliers
              </Link>
              <Typography color="text.primary">Edit Supplier</Typography>
            </Breadcrumbs>

            <Typography variant="h6" sx={{ mt: 1 }}>
              Edit Supplier
            </Typography>
          </Box>
        </Box>

        {/* Form Content */}
        <SupplierForm
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
        />

        {/* Action Buttons */}
        <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate("/supplier")}
          >
            Back to Suppliers
          </Button>

          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={handleSubmit}
          >
            Save Changes
          </Button>
        </Box>
      </Paper>
    </>
  );
}

export default EditSupplier;
