import { useState, useEffect } from "react";
import api from "../../../helpers/api";
import moment from "moment";

export const usePurchasesData = (initialPage = 0, initialRowsPerPage = 10) => {
  const [purchaseList, setPurchaseList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isReload, setIsReload] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);
  const [branchCodeSelected, setBranchCodeSelected] = useState("");
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);
  const [searchPurchaseNo, setSearchPurchaseNo] = useState("");
  const [page, setPage] = useState(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("desc");

  const fetchPurchases = async () => {
    const currentPage = page + 1;
    const dateFromSelected = selectedDateFrom
      ? moment(selectedDateFrom).format("YYYY-MM-DD")
      : "";
    const dateToSelected = selectedDateTo
      ? moment(selectedDateTo).format("YYYY-MM-DD")
      : "";

    setIsLoading(true);
    try {
      const res = await api.getPurchaseDataList(
        currentPage,
        rowsPerPage,
        searchPurchaseNo,
        dateFromSelected,
        dateToSelected,
        branchCodeSelected
      );

      if (res.statusCode === 200) {
        setPurchaseList(res.data.data);
        setTotalRecord(res.data.totalPage * res.data.limit);
      } else {
        setPurchaseList([]);
        console.error("Error fetching purchases:", res.message);
      }
    } catch (error) {
      setPurchaseList([]);
      console.error("Error fetching purchases:", error);
    } finally {
      setIsLoading(false);
      setIsReload(false);
    }
  };

  const fetchPurchaseDetail = async (id) => {
    setIsLoading(true);
    try {
      const res = await api.getDetailPurchase(id);

      if (res.statusCode === 200) {
        return res.data;
      } else {
        console.error("Error fetching purchase detail:", res.message);
        return null;
      }
    } catch (error) {
      console.error("Error fetching purchase detail:", error);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  // Handle sorting
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";

    const sortedData = [...purchaseList].sort((a, b) => {
      const valueA = a[property];
      const valueB = b[property];

      if (typeof valueA === "string") {
        return newOrder === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }

      return newOrder === "asc" ? valueA - valueB : valueB - valueA;
    });

    setPurchaseList(sortedData);
    setOrder(newOrder);
    setOrderBy(property);
  };

  // Save purchase order
  const savePurchaseOrder = async (purchaseData) => {
    const {
      datePurchase,
      notes,
      productOrders,
      branchNameSelected,
      supplierNameSelected,
      additional,
    } = purchaseData;

    const sumTotal = productOrders.reduce(
      (accumulator, current) => accumulator + parseInt(current.netPrice || 0),
      0
    );

    try {
      const res = await api.savePurchaseOrder(
        datePurchase,
        productOrders.length,
        notes,
        additional.taxCharge,
        additional.discount,
        additional.rounding,
        additional.taxResto,
        additional.edcPayment,
        sumTotal,
        sumTotal,
        additional.paymentType,
        additional.serviceCharge,
        additional.additionalCost,
        additional.paymentTypeCode,
        additional.transactionType,
        branchNameSelected.branchCode,
        branchNameSelected.branchName,
        supplierNameSelected.supplierCode,
        supplierNameSelected.supplierName,
        additional.transactionTypeCode,
        productOrders
      );

      if (res.statusCode === 200) {
        setIsReload(true);
        return { success: true, data: res.data };
      } else {
        return { success: false, error: res.message };
      }
    } catch (error) {
      return { success: false, error: error.message };
    }
  };

  // Load on page or rows per page change
  useEffect(() => {
    fetchPurchases();
  }, [page, rowsPerPage]);

  // Reload when isReload is true
  useEffect(() => {
    if (isReload) {
      fetchPurchases();
    }
  }, [isReload]);

  return {
    purchaseList,
    isLoading,
    totalRecord,
    branchCodeSelected,
    setBranchCodeSelected,
    selectedDateFrom,
    setSelectedDateFrom,
    selectedDateTo,
    setSelectedDateTo,
    searchPurchaseNo,
    setSearchPurchaseNo,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    orderBy,
    order,
    fetchPurchases,
    fetchPurchaseDetail,
    savePurchaseOrder,
    setIsReload,
    handleRequestSort,
  };
};

export default usePurchasesData;
