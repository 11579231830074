// src/pages/stockin/index.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography, Grid, Stack } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Spinner, CustomizedSnackbars } from "../../components";
import { TableList } from "../../components";
import StockInSearch from "./stockInComponent/StockInSearch";
import { useStockInData } from "./hooks/useStockInData";
import { tableHeadStockIn } from "../../utils/Constants";

function StockIn() {
  const navigate = useNavigate();
  const {
    stockInList,
    isLoading,
    totalRecord,
    branchCodeSelected,
    setBranchCodeSelected,
    page,
    rowsPerPage,
    orderBy,
    order,
    setPage,
    setRowsPerPage,
    getStockInList,
    handleRequestSort,
  } = useStockInData();

  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");

  const handleAddStockIn = () => {
    navigate("/stockin/add");
  };

  const handleDetailStockIn = (item) => {
    navigate(`/stockin/detail/${item.id}`, { state: { stockIn: item } });
  };

  return (
    <>
      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />

      <Box
        sx={{
          width: "100%",
          backgroundColor: "white",
          borderRadius: 1,
          border: "1px solid",
          borderColor: "divider",
        }}
      >
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            alignItems: "center",
            p: 3,
            borderBottom: "1px solid",
            borderColor: "divider",
          }}
        >
          <Typography variant="h6" sx={{ mb: { xs: 2, sm: 0 } }}>
            Stock In List
          </Typography>

          <Button
            variant="outlined"
            startIcon={<AddCircleIcon />}
            onClick={handleAddStockIn}
          >
            Add Stock In
          </Button>
        </Box>

        {/* Search Section */}
        <StockInSearch
          branchCodeSelected={branchCodeSelected}
          setBranchCodeSelected={setBranchCodeSelected}
          onSearch={() => {
            setPage(0);
            getStockInList();
          }}
        />

        {/* Table List */}
        <TableList
          tableStructure="stockin-list"
          tableName={"Stock In List"}
          orderBy={orderBy}
          order={order}
          isLoading={isLoading}
          isShowPagination={true}
          tableHeadList={tableHeadStockIn}
          data={stockInList}
          rowsPerPage={rowsPerPage}
          page={page}
          totalRecord={totalRecord}
          handleChangePage={(event, newPage) => setPage(newPage)}
          handleChangeRowsPerPage={(event) => {
            setRowsPerPage(+event.target.value);
            setPage(0);
          }}
          onPreviewDetail={handleDetailStockIn}
          handleRequestSort={handleRequestSort}
        />
      </Box>
    </>
  );
}

export default StockIn;
