// src/pages/stockin/detail/[id]/index.js
import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Paper,
  Typography,
  Grid,
  Button,
  Breadcrumbs,
  Link,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Card,
  CardContent,
  CircularProgress,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Spinner, CustomizedSnackbars } from "../../../../components";
import api from "../../../../helpers/api";
import { formatDate, formatNumber } from "../../utils/formatters";
import NumberFormat from "../../../../helpers/functions/numberFormat";

function StockInDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const stockInFromState = location.state?.stockIn;

  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");

  const [stockInDetail, setStockInDetail] = useState(null);

  useEffect(() => {
    const fetchStockInDetail = async () => {
      try {
        // If we already have the stockInId from location state
        const stockInId = stockInFromState?.id || id;

        const response = await api.getDetailStock(stockInId);
        if (response.statusCode === 200) {
          setStockInDetail(response.data);
        } else {
          showError(response.message);
        }
      } catch (error) {
        showError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchStockInDetail();
  }, [id, stockInFromState]);

  const showError = (msg) => {
    setIsOpen(true);
    setAlertType("error");
    setMessage(msg);
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "70vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />

      <Paper
        sx={{
          p: 3,
          mb: 3,
          borderRadius: 1,
          boxShadow: 1,
        }}
      >
        {/* Header with breadcrumbs */}
        <Box sx={{ mb: 3 }}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link
              color="inherit"
              onClick={() => navigate("/stockin")}
              sx={{ cursor: "pointer", textDecoration: "none" }}
            >
              Stock In
            </Link>
            <Typography color="text.primary">Stock In Detail</Typography>
          </Breadcrumbs>
        </Box>

        {stockInDetail ? (
          <>
            {/* Stock In Summary */}
            <Card sx={{ mb: 4 }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h6" gutterBottom>
                      Stock In Information
                    </Typography>

                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <Typography variant="body2" color="text.secondary">
                          Transaction ID
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2" fontWeight="medium">
                          {stockInDetail.id || "-"}
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant="body2" color="text.secondary">
                          Date
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2" fontWeight="medium">
                          {formatDate(stockInDetail.transactionDate)}
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant="body2" color="text.secondary">
                          Branch
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2" fontWeight="medium">
                          {stockInDetail.branchName || "-"}
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant="body2" color="text.secondary">
                          Status
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Chip
                          label={stockInDetail.statusName || "Processing"}
                          color="primary"
                          size="small"
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography variant="h6" gutterBottom>
                      Summary
                    </Typography>

                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <Typography variant="body2" color="text.secondary">
                          Total Items
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2" fontWeight="medium">
                          {stockInDetail.item || "0"}
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant="body2" color="text.secondary">
                          Total Amount
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2" fontWeight="medium">
                          Rp. {NumberFormat(stockInDetail.netPayment || 0)}
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant="body2" color="text.secondary">
                          Note
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2" fontWeight="medium">
                          {stockInDetail.note || "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            {/* Products Table */}
            <Typography variant="h6" sx={{ mb: 2 }}>
              Products
            </Typography>

            <TableContainer>
              <Table aria-label="products table">
                <TableHead>
                  <TableRow>
                    <TableCell>No</TableCell>
                    <TableCell>Product</TableCell>
                    <TableCell>Variant</TableCell>
                    <TableCell>SKU</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stockInDetail.attachments?.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        No products available
                      </TableCell>
                    </TableRow>
                  ) : (
                    stockInDetail.attachments?.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{item.productName}</TableCell>
                        <TableCell>{item.variantName}</TableCell>
                        <TableCell>{item.productSku}</TableCell>
                        <TableCell>{item.quantity}</TableCell>
                        <TableCell>
                          Rp. {NumberFormat(item.trxPrice || 0)}
                        </TableCell>
                        <TableCell>
                          Rp. {NumberFormat(item.netPrice || 0)}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <Typography variant="body1" color="text.secondary">
            Stock in details not available
          </Typography>
        )}

        {/* Action Buttons */}
        <Box sx={{ mt: 4 }}>
          <Button
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate("/stockin")}
          >
            Back to Stock In List
          </Button>
        </Box>
      </Paper>
    </>
  );
}

export default StockInDetail;
