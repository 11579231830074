// src/pages/products/index.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography, Grid, Stack } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SearchIcon from "@mui/icons-material/Search";
import {
  FormInput,
  Spinner,
  CustomizedSnackbars,
  PopupDelete,
} from "../../components";
import { useProductData } from "./hooks/useProductData";
import { tableHeadProduct } from "../../utils/Constants";
import { TableList } from "../../components";

function Products() {
  const navigate = useNavigate();
  const {
    productList,
    isLoading,
    totalRecord,
    searchProductName,
    page,
    rowsPerPage,
    setSearchProductName,
    setPage,
    setRowsPerPage,
    getProducts,
    handleRequestSort,
    orderBy,
    order,
    deleteProduct,
  } = useProductData();

  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [productSelected, setProductSelected] = useState({});

  // ===========
  const handleDeleteConfirm = async () => {
    const result = await deleteProduct(productSelected);

    if (result.success) {
      showSuccess(result.message);
    } else {
      showError(result.message);
    }

    setIsDeleteOpen(false);
  };

  // ==========

  const handleAddProduct = () => {
    navigate("/products/add");
  };

  const handleEditProduct = (item) => {
    navigate(`/products/edit/${item.id}`, { state: { product: item } });
  };

  const handleViewDetail = (item) => {
    navigate(`/products/detail/${item.id}`, { state: { product: item } });
  };

  const handleSetSellingPrice = () => {
    navigate("/products/selling-price");
  };

  const handleDeleteProduct = (outlet) => {
    setProductSelected(outlet);
    setIsDeleteOpen(true);
  };

  const showError = (msg) => {
    setIsOpen(true);
    setAlertType("error");
    setMessage(msg);
  };

  const showSuccess = (msg) => {
    setIsOpen(true);
    setAlertType("success");
    setMessage(msg);
  };

  return (
    <>
      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />

      <Box
        sx={{
          width: "100%",
          backgroundColor: "white",
          borderRadius: 1,
          border: "1px solid",
          borderColor: "divider",
        }}
      >
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            alignItems: "center",
            p: 3,
            borderBottom: "1px solid",
            borderColor: "divider",
          }}
        >
          <Typography variant="h6" sx={{ mb: { xs: 2, sm: 0 } }}>
            Product List
          </Typography>

          <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              variant="outlined"
              startIcon={<AddCircleIcon />}
              onClick={handleAddProduct}
            >
              Add New Product
            </Button>

            <Button
              variant="outlined"
              startIcon={<AddCircleIcon />}
              onClick={handleSetSellingPrice}
            >
              Setting Selling Price
            </Button>
          </Box>
        </Box>

        {/* Search Section */}
        <Box sx={{ p: 3, borderBottom: "1px solid", borderColor: "divider" }}>
          <Grid
            container
            spacing={2}
            sx={{
              alignItems: "center",
            }}
          >
            <Grid item xs={12} sm={3}>
              <Stack spacing={1}>
                <Typography variant="caption" sx={{ fontWeight: 600 }}>
                  PRODUCT NAME
                </Typography>
                <FormInput
                  size="small"
                  label="Search Product"
                  name="searchProductName"
                  value={searchProductName}
                  onChange={(event) => setSearchProductName(event.target.value)}
                />
              </Stack>
            </Grid>

            <Grid
              item
              xs={12}
              sm={3}
              sx={{
                display: "flex",
                alignItems: "end",
                height: "100%",
              }}
            >
              <Button
                variant="contained"
                startIcon={<SearchIcon />}
                onClick={() => {
                  setPage(0);
                  getProducts();
                }}
                sx={{
                  width: "150px",
                  mt: { xs: 1, sm: 1, md: 3, lg: 3 },
                }}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Box>

        {/* Table List */}
        <TableList
          tableStructure="product-list"
          tableName="Product List"
          orderBy={orderBy}
          order={order}
          isShowPagination={true}
          isLoading={isLoading}
          tableHeadList={tableHeadProduct}
          data={productList}
          rowsPerPage={rowsPerPage}
          page={page}
          totalRecord={totalRecord}
          handleChangePage={(event, newPage) => setPage(newPage)}
          handleChangeRowsPerPage={(event) => {
            setRowsPerPage(+event.target.value);
            setPage(0);
          }}
          onPreviewDetail={handleViewDetail}
          onEditData={handleEditProduct}
          handleRequestSort={handleRequestSort}
          onDeleteData={handleDeleteProduct}
        />
      </Box>

      <PopupDelete
        itemName={productSelected.productName}
        visible={isDeleteOpen}
        onClose={() => setIsDeleteOpen(false)}
        onCancel={() => setIsDeleteOpen(false)}
        onDelete={handleDeleteConfirm}
      />
    </>
  );
}

export default Products;
