import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Spinner, CustomizedSnackbars } from "../../components";
import { TableList } from "../../components";
import { tableHeadTransferList } from "../../utils/Constants";

// Import komponen dan hooks
import InventorySearch from "./InventoryComponent/InventorySearch";
import { useInventoryData } from "./hooks/useInventoryData";

function Inventory() {
  const navigate = useNavigate();

  // Gunakan custom hook untuk data inventory dan transfer
  const {
    historyTransferList,
    isLoading,
    isSpinner,
    totalRecord,
    page,
    rowsPerPage,
    orderBy,
    order,
    branchCodeSelected,
    setBranchCodeSelected,
    searchInventoryLocation,
    setSearchInventoryLocation,
    selectedDateFrom,
    setSelectedDateFrom,
    selectedDateTo,
    setSelectedDateTo,
    setPage,
    setRowsPerPage,
    fetchHistoryTransfer,
    handleRequestSort,
    setIsReload,
  } = useInventoryData();

  // Alert states
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");

  // Handler untuk navigasi ke halaman tambah transfer
  const handleAddTransfer = () => {
    navigate("/inventory/add");
  };

  // Handler untuk navigasi ke halaman detail transfer
  const handleDetailTransfer = (item) => {
    navigate(`/inventory/detail/${item.id}`, { state: { transaction: item } });
  };

  // Handler untuk pencarian
  const handleSearch = () => {
    setPage(0);
    setIsReload(true);
  };

  return (
    <>
      <Spinner isShowSpinner={isSpinner} />
      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />

      <Box
        sx={{
          width: "100%",
          backgroundColor: "white",
          borderRadius: 1,
          border: "1px solid",
          borderColor: "divider",
        }}
      >
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            alignItems: "center",
            p: 3,
            borderBottom: "1px solid",
            borderColor: "divider",
          }}
        >
          <Typography variant="h6" sx={{ mb: { xs: 2, sm: 0 } }}>
            History Transfer Stock List
          </Typography>

          <Button
            variant="outlined"
            startIcon={<AddCircleIcon />}
            onClick={handleAddTransfer}
          >
            Transfer Stock
          </Button>
        </Box>

        {/* Search Section */}
        <InventorySearch
          branchCodeSelected={branchCodeSelected}
          setBranchCodeSelected={setBranchCodeSelected}
          searchInventoryLocation={searchInventoryLocation}
          setSearchInventoryLocation={setSearchInventoryLocation}
          selectedDateFrom={selectedDateFrom}
          setSelectedDateFrom={setSelectedDateFrom}
          selectedDateTo={selectedDateTo}
          setSelectedDateTo={setSelectedDateTo}
          onSearch={handleSearch}
        />

        {/* Table List */}
        <TableList
          tableStructure="transfer-list"
          tableName={"History Transfer List"}
          orderBy={orderBy}
          order={order}
          isLoading={isLoading}
          isShowPagination={true}
          tableHeadList={tableHeadTransferList}
          data={historyTransferList}
          rowsPerPage={rowsPerPage}
          page={page}
          totalRecord={totalRecord}
          handleChangePage={(event, newPage) => setPage(newPage)}
          handleChangeRowsPerPage={(event) => {
            setRowsPerPage(+event.target.value);
            setPage(0);
          }}
          onPreviewDetail={handleDetailTransfer}
          handleRequestSort={handleRequestSort}
        />
      </Box>
    </>
  );
}

export default Inventory;
