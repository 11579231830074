import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Paper,
  Typography,
  Grid,
  Button,
  Breadcrumbs,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Stack,
  CircularProgress,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import {
  PickerSearch,
  FormDatePicker,
  FormInput,
  Spinner,
  CustomizedSnackbars,
} from "../../../components";
import { tableHeadAddStockOpname } from "../../../utils/Constants";
import NumberFormat from "../../../helpers/functions/numberFormat";

// Import hooks
import { useStockOpnameData } from "../hooks/useStockOpnameData";
import { useBranches } from "../hooks/useBranches";

// Import utilities
import {
  validateStockOpnameForm,
  validateProductChanges,
  prepareProductsForSave,
  calculateNegativeDiffSummary,
  calculatePositiveDiffSummary,
  calculateSummaryData,
} from "../utils/validation";
import moment from "moment";

function AddStockOpname() {
  const navigate = useNavigate();

  // Hooks
  const {
    isSpinner,
    setIsSpinner,
    error,
    stockOpnameProductList,
    setStockOpnameProductList,
    fetchStockOpnameProducts,
    saveStockOpname,
    handleQuantityChange,
    calculateDifference,
    calculateTotalPrice,
  } = useStockOpnameData();

  const {
    branchList,
    searchBranchName,
    setSearchBranchName,
    branchSelected,
    setBranchSelected,
  } = useBranches();

  // Local state
  const [isLoading, setIsLoading] = useState(false);
  const [dateStockOpname, setDateStockOpname] = useState(null);
  const [notes, setNotes] = useState("");
  const [errors, setErrors] = useState({});
  const [pageStokOpname, setPageStockOpname] = useState(0);
  const [rowsPerPageStockOpname, setRowsPerPageStockOpname] = useState(10);

  // Alert state
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");

  // Effect to load products when branch is selected
  useEffect(() => {
    if (branchSelected?.branchCode) {
      loadStockOpnameProducts(branchSelected.branchCode);
    }
  }, [branchSelected]);

  // Load products for stock opname
  const loadStockOpnameProducts = async (branchCode) => {
    setIsLoading(true);
    try {
      await fetchStockOpnameProducts(branchCode);
    } catch (err) {
      showError(err.message || "Failed to load products");
    } finally {
      setIsLoading(false);
    }
  };

  // Handle save stock opname
  const handleSubmit = async () => {
    // Validasi form utama menggunakan utils validation
    const formErrors = validateStockOpnameForm({
      branchSelected,
      dateStockOpname,
      stockOpnameProductList,
    });

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    // Validasi perubahan produk menggunakan utils validation
    const productValidation = validateProductChanges(stockOpnameProductList);
    if (!productValidation.valid) {
      showError(productValidation.message);
      return;
    }

    // Persiapkan data untuk disimpan menggunakan utils validation
    const formattedDate = moment(dateStockOpname).format("YYYY-MM-DD");
    const updatedProductList = prepareProductsForSave(stockOpnameProductList);
    const negativeDiffSummary = calculateNegativeDiffSummary(
      stockOpnameProductList
    );
    const positiveDiffSummary = calculatePositiveDiffSummary(
      stockOpnameProductList
    );
    const summaryData = calculateSummaryData(stockOpnameProductList);

    // Prepare data for saving
    const stockOpnameData = {
      formattedDate,
      branchSelected,
      totalTrxPrice: summaryData.totalTrxPrice,
      negativeDiffSummary,
      positiveDiffSummary,
      totalStockOpnameQuantity: summaryData.totalStockOpnameQuantity,
      totalStockOpnamePrice: summaryData.totalStockOpnamePrice,
      totalnventoryQuantity: summaryData.totalnventoryQuantity,
      totalTrxtInvetory: summaryData.totalTrxtInvetory,
      updatedProductList,
      notes,
    };

    // Save stock opname
    setIsSpinner(true);
    const result = await saveStockOpname(stockOpnameData);
    setIsSpinner(false);

    if (result.success) {
      showSuccess("Stock Opname saved successfully");

      // Navigate back to list after short delay
      setTimeout(() => {
        navigate("/StockOpname");
      }, 300);
    } else {
      showError(result.error || "Failed to save Stock Opname");
    }
  };

  // Utility functions
  const showError = (msg) => {
    setIsOpen(true);
    setAlertType("error");
    setMessage(msg);
  };

  const showSuccess = (msg) => {
    setIsOpen(true);
    setAlertType("success");
    setMessage(msg);
  };

  return (
    <>
      <Spinner isShowSpinner={isSpinner} />
      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />

      <Paper
        sx={{
          p: 3,
          mb: 3,
          borderRadius: 1,
          boxShadow: 1,
        }}
      >
        {/* Header */}
        <Box
          sx={{
            mb: 3,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link
                color="inherit"
                onClick={() => navigate("/StockOpname")}
                sx={{ cursor: "pointer", textDecoration: "none" }}
              >
                Stock Opname
              </Link>
              <Typography color="text.primary">Add Stock Opname</Typography>
            </Breadcrumbs>

            <Typography variant="h6" sx={{ mt: 1 }}>
              New Stock Opname
            </Typography>
          </Box>
        </Box>

        {/* Form Content */}
        <Grid container spacing={3}>
          {/* Branch Selection */}
          <Grid item xs={12} sm={6}>
            <PickerSearch
              value={branchSelected}
              dataList={branchList}
              onInputChange={(event, newInputValue) => {
                setSearchBranchName(newInputValue);
              }}
              onChange={(e, v) => {
                if (v) {
                  setBranchSelected(v);
                  setStockOpnameProductList([]);
                  // Clear errors when branch is selected
                  setErrors((prev) => ({
                    ...prev,
                    branchSelected: undefined,
                    products: undefined,
                  }));
                } else {
                  setBranchSelected(null);
                  setStockOpnameProductList([]);
                }
              }}
              getOptionLabel={(branch) => branch.branchName || ""}
              labelPicker={"Choose Branch / Outlet"}
              placeholderPicker={"search branch / outlet"}
              sizePicker={"large"}
              isVisibleLabelPicker={true}
              labelTitlePicker={""}
              error={!!errors.branchSelected}
              helperText={errors.branchSelected}
            />
          </Grid>

          {/* Date Selection */}
          <Grid item xs={12} sm={6}>
            <FormDatePicker
              labelFormDatePicker="Date Stock Opname"
              formSize={"large"}
              dateValue={dateStockOpname}
              onChangeDate={(val) => {
                setDateStockOpname(val);
                // Clear error when date is selected
                setErrors((prev) => ({ ...prev, dateStockOpname: undefined }));
              }}
              error={!!errors.dateStockOpname}
              helperText={errors.dateStockOpname}
            />
          </Grid>

          {/* Notes */}
          {/* <Grid item xs={12} sm={6}>
            <FormInput
              label="Notes"
              name="notes"
              error={!!errors.notes}
              helperText={errors.notes}
              value={notes}
              onChange={(event) => {
                setNotes(event.target.value);
              }}
              multiline
              rows={4}
              maxRows={4}
            />
          </Grid> */}
        </Grid>

        {/* Product Table */}
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" gutterBottom>
            Products
          </Typography>

          {errors.products && (
            <Typography color="error" variant="body2" sx={{ mb: 2 }}>
              {errors.products}
            </Typography>
          )}

          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {tableHeadAddStockOpname.map((item) => (
                    <TableCell
                      key={item.name}
                      sx={{
                        fontWeight: "bold",
                        backgroundColor: "#060270",
                        color: "white",
                        width: item.size,
                      }}
                    >
                      {item.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell
                      colSpan={tableHeadAddStockOpname.length}
                      align="center"
                      sx={{ py: 3 }}
                    >
                      <CircularProgress size={30} />
                      <Typography variant="body2" sx={{ mt: 1 }}>
                        Loading products...
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : stockOpnameProductList.length === 0 ? (
                  <TableRow>
                    <TableCell
                      colSpan={tableHeadAddStockOpname.length}
                      align="center"
                      sx={{ py: 4 }}
                    >
                      <Typography
                        variant="body1"
                        sx={{ color: "text.secondary" }}
                      >
                        No products found. Please select a branch first.
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  (rowsPerPageStockOpname > 0
                    ? stockOpnameProductList.slice(
                        pageStokOpname * rowsPerPageStockOpname,
                        pageStokOpname * rowsPerPageStockOpname +
                          rowsPerPageStockOpname
                      )
                    : stockOpnameProductList
                  ).map((item, index) => (
                    <TableRow key={item.productCode}>
                      <TableCell>{`${item.productName} - ${item.variantName}`}</TableCell>
                      <TableCell>{NumberFormat(0, item.quantity)}</TableCell>
                      <TableCell>{`Rp. ${NumberFormat(
                        0,
                        item.trxPrice
                      )}`}</TableCell>
                      <TableCell>
                        <FormInput
                          size="small"
                          label="Total Product Actual"
                          name="stockOpnameQuantity"
                          type="number"
                          value={parseInt(item.stockOpnameQuantity)}
                          onChange={(event) => {
                            handleQuantityChange(event, item.productCode);
                            calculateDifference(item.productCode);
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Typography
                          color={
                            item.totalProductDiffrence > 0
                              ? "success.main"
                              : item.totalProductDiffrence < 0
                              ? "error.main"
                              : "text.primary"
                          }
                        >
                          {NumberFormat(0, item.totalProductDiffrence)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          color={
                            item.stockOpnamePrice > 0
                              ? "success.main"
                              : item.stockOpnamePrice < 0
                              ? "error.main"
                              : "text.primary"
                          }
                        >
                          {`Rp. ${NumberFormat(0, item.stockOpnamePrice)}`}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))
                )}
                {stockOpnameProductList.length > 0 && (
                  <TableRow sx={{ "& > td": { borderBottom: "none" } }}>
                    <TableCell colSpan={4} />
                    <TableCell rowSpan={4}>
                      <Typography sx={{ fontSize: "18px" }}>Total</Typography>
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold", fontSize: "18px" }}
                      align="left"
                    >
                      {`Rp. ${NumberFormat(0, calculateTotalPrice())}`}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {stockOpnameProductList.length > 0 && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={stockOpnameProductList.length}
              rowsPerPage={rowsPerPageStockOpname}
              page={pageStokOpname}
              onPageChange={(event, newPage) => {
                setPageStockOpname(newPage);
              }}
              onRowsPerPageChange={(event) => {
                setRowsPerPageStockOpname(+event.target.value);
                setPageStockOpname(0);
              }}
            />
          )}
        </Box>

        {/* Action Buttons */}
        <Box sx={{ mt: 4, display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate("/StockOpname")}
          >
            Back to Stock Opname
          </Button>

          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={handleSubmit}
          >
            Save Stock Opname
          </Button>
        </Box>
      </Paper>
    </>
  );
}

export default AddStockOpname;
