import React from "react";
import { Box, Button, Typography, Grid, Stack } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { PickerSearch, FormDatePicker } from "../../../components";
import { useBranches } from "../hooks/useBranches";

function ProductReceiptSearch({
  branchCodeSelected,
  setBranchCodeSelected,
  selectedDateFrom,
  setSelectedDateFrom,
  selectedDateTo,
  setSelectedDateTo,
  onSearch,
}) {
  // Gunakan custom hook untuk data cabang
  const { branchList, searchBranchName, setSearchBranchName } = useBranches();

  return (
    <Box sx={{ p: 3, borderBottom: "1px solid", borderColor: "divider" }}>
      <Grid
        container
        spacing={2}
        sx={{
          alignItems: "center",
        }}
      >
        <Grid item xs={12} sm={3}>
          <Stack spacing={1}>
            <Typography variant="caption" sx={{ fontWeight: 600 }}>
              CHOOSE OUTLET
            </Typography>
            <PickerSearch
              dataList={branchList}
              onInputChange={(event, newInputValue) => {
                setSearchBranchName(newInputValue);
              }}
              onChange={(e, v) => {
                if (v) {
                  setBranchCodeSelected(v.branchCode);
                } else {
                  setBranchCodeSelected("");
                }
              }}
              getOptionLabel={(branchList) => branchList.branchName || ""}
              labelPicker="Choose Outlet"
              placeholderPicker="search outlet"
              sizePicker="small"
              isVisibleLabelPicker={false}
            />
          </Stack>
        </Grid>

        <Grid item xs={12} sm={3}>
          <Stack spacing={1}>
            <Typography variant="caption" sx={{ fontWeight: 600 }}>
              DATE FROM
            </Typography>
            <FormDatePicker
              labelFormDatePicker="Date From"
              dateValue={selectedDateFrom}
              onChangeDate={(val) => setSelectedDateFrom(val)}
            />
          </Stack>
        </Grid>

        <Grid item xs={12} sm={3}>
          <Stack spacing={1}>
            <Typography variant="caption" sx={{ fontWeight: 600 }}>
              DATE TO
            </Typography>
            <FormDatePicker
              labelFormDatePicker="Date To"
              dateValue={selectedDateTo}
              onChangeDate={(val) => setSelectedDateTo(val)}
            />
          </Stack>
        </Grid>

        <Grid
          item
          xs={12}
          sm={3}
          sx={{
            display: "flex",
            alignItems: "end",
            height: "100%",
          }}
        >
          <Button
            variant="contained"
            startIcon={<SearchIcon />}
            onClick={onSearch}
            sx={{
              width: "150px",
              mt: { xs: 1, sm: 1, md: 3, lg: 3 },
            }}
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ProductReceiptSearch;
