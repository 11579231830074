import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Spinner, CustomizedSnackbars } from "../../components";
import { TableList } from "../../components";
import PurchasesSearch from "./PurchaseComponent/PurchasesSearch";
import { usePurchasesData } from "./hooks/usePurchasesData";
import { tableHeadPurchases } from "../../utils/Constants";

function Purchases() {
  const navigate = useNavigate();
  const {
    purchaseList,
    isLoading,
    totalRecord,
    branchCodeSelected,
    setBranchCodeSelected,
    selectedDateFrom,
    setSelectedDateFrom,
    selectedDateTo,
    setSelectedDateTo,
    searchPurchaseNo,
    setSearchPurchaseNo,
    page,
    rowsPerPage,
    orderBy,
    order,
    setPage,
    setRowsPerPage,
    fetchPurchases,
    handleRequestSort,
  } = usePurchasesData();

  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");

  const handleAddPurchase = () => {
    navigate("/purchases/add");
  };

  const handleDetailPurchase = (item) => {
    navigate(`/purchases/detail/${item.id}`, { state: { purchase: item } });
  };

  return (
    <>
      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />

      <Box
        sx={{
          width: "100%",
          backgroundColor: "white",
          borderRadius: 1,
          border: "1px solid",
          borderColor: "divider",
        }}
      >
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            alignItems: "center",
            p: 3,
            borderBottom: "1px solid",
            borderColor: "divider",
          }}
        >
          <Typography variant="h6" sx={{ mb: { xs: 2, sm: 0 } }}>
            Purchases List
          </Typography>

          <Button
            variant="outlined"
            startIcon={<AddCircleIcon />}
            onClick={handleAddPurchase}
          >
            Add New Purchase
          </Button>
        </Box>

        {/* Search Section */}
        <PurchasesSearch
          branchCodeSelected={branchCodeSelected}
          setBranchCodeSelected={setBranchCodeSelected}
          selectedDateFrom={selectedDateFrom}
          setSelectedDateFrom={setSelectedDateFrom}
          selectedDateTo={selectedDateTo}
          setSelectedDateTo={setSelectedDateTo}
          searchPurchaseNo={searchPurchaseNo}
          setSearchPurchaseNo={setSearchPurchaseNo}
          onSearch={() => {
            setPage(0);
            fetchPurchases();
          }}
        />

        {/* Table List */}
        <TableList
          tableStructure="purchases-list"
          tableName={"Purchases List"}
          orderBy={orderBy}
          order={order}
          isLoading={isLoading}
          isShowPagination={true}
          tableHeadList={tableHeadPurchases}
          data={purchaseList}
          rowsPerPage={rowsPerPage}
          page={page}
          totalRecord={totalRecord}
          handleChangePage={(event, newPage) => setPage(newPage)}
          handleChangeRowsPerPage={(event) => {
            setRowsPerPage(+event.target.value);
            setPage(0);
          }}
          onPreviewDetail={handleDetailPurchase}
          handleRequestSort={handleRequestSort}
        />
      </Box>
    </>
  );
}

export default Purchases;
