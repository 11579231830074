import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Paper,
  Typography,
  Grid,
  Button,
  Breadcrumbs,
  Link,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Chip,
  Card,
  CardContent,
  CircularProgress,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Spinner, CustomizedSnackbars } from "../../../../components";
import NumberFormat from "../../../../helpers/functions/numberFormat";
import { formatDate } from "../../utils/formatters";

// Import hooks
import { useStockOpnameData } from "../../hooks/useStockOpnameData";

function StockOpnameDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const stockOpnameFromState = location.state?.stockOpname;

  // Hooks
  const { isSpinner, fetchStockOpnameDetail, grandTotalStockOpname } =
    useStockOpnameData();

  // Local state
  const [isLoading, setIsLoading] = useState(true);
  const [detailStockOpname, setDetailStockOpname] = useState(null);
  const [pageDetailSO, setPageDetailSO] = useState(0);
  const [rowsPerPageDetailSO, setRowsPerPageDetailSO] = useState(10);
  const [totalRecordDetailSO, setTotalRecordDetailSO] = useState(0);

  // Alert state
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");

  // Load detail data
  useEffect(() => {
    const loadDetailData = async () => {
      setIsLoading(true);

      try {
        // If we already have the stockOpnameId from location state
        const stockOpnameId = stockOpnameFromState?.id || id;

        const detail = await fetchStockOpnameDetail(
          stockOpnameId,
          pageDetailSO,
          rowsPerPageDetailSO
        );

        if (detail) {
          setDetailStockOpname(detail);
          setTotalRecordDetailSO(detail.totalRecord || 0);
        } else {
          showError("Failed to load stock opname details");
        }
      } catch (error) {
        showError(error.message || "An error occurred while loading data");
      } finally {
        setIsLoading(false);
      }
    };

    loadDetailData();
  }, [id, pageDetailSO, rowsPerPageDetailSO]);

  // Handle pagination changes
  const handleChangePageDetailSO = (event, newPage) => {
    setPageDetailSO(newPage);
  };

  const handleChangeRowsPerPageDetailSO = (event) => {
    setRowsPerPageDetailSO(+event.target.value);
    setPageDetailSO(0);
  };

  // Utility functions
  const showError = (msg) => {
    setIsOpen(true);
    setAlertType("error");
    setMessage(msg);
  };

  // Get status color
  const getStatusColor = (statusName) => {
    switch (statusName) {
      case "Completed":
        return "success";
      case "Cancelled":
        return "error";
      case "Pending":
        return "warning";
      default:
        return "primary";
    }
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "70vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Spinner isShowSpinner={isSpinner} />
      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />

      <Paper
        sx={{
          p: 3,
          mb: 3,
          borderRadius: 1,
          boxShadow: 1,
        }}
      >
        {/* Header */}
        <Box
          sx={{
            mb: 3,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link
                color="inherit"
                onClick={() => navigate("/StockOpname")}
                sx={{ cursor: "pointer", textDecoration: "none" }}
              >
                Stock Opname
              </Link>
              <Typography color="text.primary">Stock Opname Detail</Typography>
            </Breadcrumbs>

            <Typography variant="h6" sx={{ mt: 1 }}>
              Stock Opname #{detailStockOpname?.transaction?.id || id}
            </Typography>
          </Box>
        </Box>

        {detailStockOpname ? (
          <>
            {/* Stock Opname Information */}
            <Card sx={{ mb: 4 }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h6" gutterBottom>
                      Stock Opname Information
                    </Typography>

                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <Typography variant="body2" color="text.secondary">
                          Transaction ID
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2" fontWeight="medium">
                          {detailStockOpname.transaction?.id || "-"}
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant="body2" color="text.secondary">
                          Date
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2" fontWeight="medium">
                          {formatDate(
                            detailStockOpname.transaction?.transactionDate
                          )}
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant="body2" color="text.secondary">
                          Branch / Outlet
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2" fontWeight="medium">
                          {detailStockOpname.transaction?.branchName || "-"}
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant="body2" color="text.secondary">
                          Status
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Chip
                          label={
                            detailStockOpname.transaction?.statusName ||
                            "Completed"
                          }
                          color={getStatusColor(
                            detailStockOpname.transaction?.statusName
                          )}
                          size="small"
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography variant="h6" gutterBottom>
                      Summary
                    </Typography>

                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <Typography variant="body2" color="text.secondary">
                          Plus Items
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2" fontWeight="medium">
                          {NumberFormat(
                            0,
                            detailStockOpname.transaction?.totalPlusQuantity ||
                              0
                          )}
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant="body2" color="text.secondary">
                          Minus Items
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2" fontWeight="medium">
                          {NumberFormat(
                            0,
                            detailStockOpname.transaction?.totalMinusQuantity ||
                              0
                          )}
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant="body2" color="text.secondary">
                          Plus Amount
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography
                          variant="body2"
                          fontWeight="medium"
                          color="success.main"
                        >
                          Rp.{" "}
                          {NumberFormat(
                            0,
                            detailStockOpname.transaction?.TotalPlusPrice || 0
                          )}
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant="body2" color="text.secondary">
                          Minus Amount
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography
                          variant="body2"
                          fontWeight="medium"
                          color="error.main"
                        >
                          Rp.{" "}
                          {NumberFormat(
                            0,
                            detailStockOpname.transaction?.TotalMinusPrice || 0
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            {/* Products Table */}
            <Typography variant="h6" sx={{ mb: 2 }}>
              Product Items
            </Typography>

            <TableContainer>
              <Table aria-label="stock opname detail table">
                <TableHead>
                  <TableRow>
                    <TableCell>No</TableCell>
                    <TableCell>Product</TableCell>
                    <TableCell>Variant</TableCell>
                    <TableCell align="right">Stock Before</TableCell>
                    <TableCell align="right">Actual Stock</TableCell>
                    <TableCell align="right">Difference</TableCell>
                    <TableCell align="right">Price</TableCell>
                    <TableCell align="right">Total</TableCell>
                    <TableCell>Type</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!detailStockOpname.attachments ||
                  detailStockOpname.attachments.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={9} align="center">
                        No products available
                      </TableCell>
                    </TableRow>
                  ) : (
                    detailStockOpname.attachments.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{item.productName}</TableCell>
                        <TableCell>{item.variantName}</TableCell>
                        <TableCell align="right">
                          {NumberFormat(0, item.inventoryQuantity)}
                        </TableCell>
                        <TableCell align="right">
                          {NumberFormat(0, item.stockOpnameQuantity)}
                        </TableCell>
                        <TableCell align="right">
                          {NumberFormat(0, item.totalProductDiffrence)}
                        </TableCell>
                        <TableCell align="right">
                          Rp. {NumberFormat(0, item.trxPrice)}
                        </TableCell>
                        <TableCell align="right">
                          Rp. {NumberFormat(0, item.stockOpnamePrice)}
                        </TableCell>
                        <TableCell>
                          <Chip
                            label={item.stockOpnameType}
                            color={
                              item.stockOpnameTypeCode === "62"
                                ? "success"
                                : "error"
                            }
                            size="small"
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {/* Pagination */}
            {detailStockOpname.attachments &&
              detailStockOpname.attachments.length > 0 && (
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={totalRecordDetailSO}
                  rowsPerPage={rowsPerPageDetailSO}
                  page={pageDetailSO}
                  onPageChange={handleChangePageDetailSO}
                  onRowsPerPageChange={handleChangeRowsPerPageDetailSO}
                />
              )}

            {/* Total Section */}
            <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
              <Card sx={{ minWidth: 300 }}>
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Typography variant="body2">Plus Amount:</Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "right" }}>
                      <Typography variant="body2" color="success.main">
                        Rp.{" "}
                        {NumberFormat(
                          0,
                          detailStockOpname.transaction?.TotalPlusPrice || 0
                        )}
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="body2">Minus Amount:</Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "right" }}>
                      <Typography variant="body2" color="error.main">
                        Rp.{" "}
                        {NumberFormat(
                          0,
                          detailStockOpname.transaction?.TotalMinusPrice || 0
                        )}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider sx={{ my: 1 }} />
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="subtitle2" fontWeight="bold">
                        Grand Total:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "right" }}>
                      <Typography variant="subtitle2" fontWeight="bold">
                        Rp. {NumberFormat(0, grandTotalStockOpname || 0)}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </>
        ) : (
          <Typography variant="body1" color="text.secondary">
            Stock opname details not available
          </Typography>
        )}

        {/* Action Buttons */}
        <Box sx={{ mt: 4 }}>
          <Button
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate("/StockOpname")}
          >
            Back to Stock Opname
          </Button>
        </Box>
      </Paper>
    </>
  );
}

export default StockOpnameDetail;
