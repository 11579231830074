import { useState, useEffect } from "react";
import api from "../../../helpers/api";
import moment from "moment";

export const useInventoryData = (initialPage = 0, initialRowsPerPage = 10) => {
  // Loading states
  const [isLoading, setIsLoading] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);
  const [isReload, setIsReload] = useState(false);

  // Error state
  const [error, setError] = useState(null);

  // Table states
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("desc");

  // Pagination states
  const [page, setPage] = useState(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const [totalRecord, setTotalRecord] = useState(0);

  // Filter states
  const [branchCodeSelected, setBranchCodeSelected] = useState("");
  const [searchInventoryLocation, setSearchInventoryLocation] = useState("");
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);
  const [transactionCode, setTransactionCode] = useState(30); // Default transaction code for transfer

  // Data states
  const [historyTransferList, setHistoryTransferList] = useState([]);
  const [inventoryList, setInventoryList] = useState([]);
  const [searchProductName, setSearchProductName] = useState("");
  const [detailTransaction, setDetailTransaction] = useState(null);
  const [transactionSelected, setTransactionSelected] = useState(null);

  /**
   * Mengambil data daftar history transfer
   */
  const fetchHistoryTransfer = async () => {
    const currentPage = page + 1;
    const dateFromSelected = selectedDateFrom
      ? moment(selectedDateFrom).format("YYYY-MM-DD")
      : "";
    const dateToSelected = selectedDateTo
      ? moment(selectedDateTo).format("YYYY-MM-DD")
      : "";

    setIsLoading(true);
    setError(null);

    try {
      const res = await api.getTransactionData(
        isReload ? searchInventoryLocation : branchCodeSelected,
        transactionCode,
        dateFromSelected,
        dateToSelected,
        rowsPerPage,
        currentPage
      );

      if (res.statusCode === 200) {
        setHistoryTransferList(res.data.data);
        setTotalRecord(res.data.totalPage * res.data.limit);
      } else {
        setError(res.message);
      }
    } catch (error) {
      setError(error.message || "Failed to fetch transfer history");
      setHistoryTransferList([]);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Mengambil data inventory berdasarkan branch dan product name
   * @param {string} branchCode - Kode cabang
   * @param {string} productName - Nama produk untuk filter
   */
  const fetchInventoryData = async (
    branchCode = branchCodeSelected,
    productName = searchProductName
  ) => {
    setIsLoading(true);
    setError(null);

    try {
      const res = await api.getInventoryData(branchCode, productName);

      if (res.statusCode === 200) {
        setInventoryList(res.data.data);
      } else {
        setError(res.message);
        setInventoryList([]);
      }
    } catch (error) {
      setError(error.message || "Failed to fetch inventory data");
      setInventoryList([]);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Mengambil detail transaksi berdasarkan ID
   * @param {string|number} id - ID transaksi
   */
  const fetchTransactionDetail = async (id) => {
    if (!id) return null;

    setIsSpinner(true);
    setError(null);

    try {
      const res = await api.getDetailTransaction(id);

      if (res.statusCode === 200) {
        setDetailTransaction(res.data);
        return res.data;
      } else {
        setError(res.message);
        return null;
      }
    } catch (error) {
      setError(error.message || "Failed to fetch transaction detail");
      return null;
    } finally {
      setIsSpinner(false);
    }
  };

  /**
   * Menyimpan transfer inventory
   * @param {Object} transferData - Data transfer inventory
   */
  const saveTransferInventory = async (transferData) => {
    const { transferDate, branchNameFrom, branchNameTo, productOrders } =
      transferData;

    setIsSpinner(true);
    setError(null);

    try {
      const res = await api.saveSendingInvenStock(
        transferDate,
        branchNameFrom.branchCode,
        branchNameFrom.branchName,
        branchNameTo.branchCode,
        branchNameTo.branchName,
        productOrders
      );

      if (res.statusCode === 200) {
        setIsReload(true);
        return { success: true, data: res.data };
      } else {
        setError(res.message);
        return { success: false, error: res.message };
      }
    } catch (error) {
      setError(error.message || "Failed to save inventory transfer");
      return { success: false, error: error.message };
    } finally {
      setIsSpinner(false);
    }
  };

  /**
   * Handler untuk pengurutan data
   * @param {string} property - Properti yang akan diurutkan
   */
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";

    const sortedData = [...historyTransferList].sort((a, b) => {
      const valueA = a[property];
      const valueB = b[property];

      if (typeof valueA === "string") {
        return newOrder === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }

      return newOrder === "asc" ? valueA - valueB : valueB - valueA;
    });

    setHistoryTransferList(sortedData);
    setOrder(newOrder);
    setOrderBy(property);
  };

  // Effect untuk memuat history transfer saat halaman atau jumlah baris per halaman berubah
  useEffect(() => {
    fetchHistoryTransfer();
  }, [page, rowsPerPage]);

  // Effect untuk memuat ulang data saat isReload bernilai true
  useEffect(() => {
    if (isReload) {
      fetchHistoryTransfer();
    }
  }, [isReload]);

  return {
    // State
    historyTransferList,
    inventoryList,
    detailTransaction,
    transactionSelected,
    isLoading,
    isSpinner,
    isReload,
    error,
    page,
    rowsPerPage,
    totalRecord,
    orderBy,
    order,
    branchCodeSelected,
    searchInventoryLocation,
    selectedDateFrom,
    selectedDateTo,
    searchProductName,

    // Setters
    setHistoryTransferList,
    setInventoryList,
    setDetailTransaction,
    setTransactionSelected,
    setIsLoading,
    setIsSpinner,
    setIsReload,
    setPage,
    setRowsPerPage,
    setBranchCodeSelected,
    setSearchInventoryLocation,
    setSelectedDateFrom,
    setSelectedDateTo,
    setSearchProductName,

    // Methods
    fetchHistoryTransfer,
    fetchInventoryData,
    fetchTransactionDetail,
    saveTransferInventory,
    handleRequestSort,
  };
};

export default useInventoryData;
