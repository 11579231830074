/**
 * Validasi form stock opname
 * @param {Object} formData - Data form stock opname
 * @returns {Object} Objek berisi pesan error jika validasi gagal
 */
export const validateStockOpnameForm = (formData) => {
  const { branchSelected, dateStockOpname, stockOpnameProductList } = formData;
  const errors = {};

  // Validasi cabang/outlet
  if (
    !branchSelected ||
    branchSelected === null ||
    (Array.isArray(branchSelected) && branchSelected.length === 0) ||
    Object.keys(branchSelected).length === 0
  ) {
    errors.branchSelected = "Branch/Outlet is required";
  }

  // Validasi tanggal
  if (!dateStockOpname) {
    errors.dateStockOpname = "Date is required";
  }

  // Validasi ketersediaan produk
  if (!stockOpnameProductList || stockOpnameProductList.length === 0) {
    errors.products = "No products available. Please select a branch first.";
  }

  return errors;
};

/**
 * Validasi perubahan pada produk stock opname
 * @param {Array} stockOpnameProductList - Daftar produk stock opname
 * @returns {Object} Status validasi dan pesan
 */
export const validateProductChanges = (stockOpnameProductList) => {
  // Cek apakah ada produk dengan perbedaan kuantitas
  const productsWithDifference = stockOpnameProductList.filter(
    (product) => product.totalProductDiffrence !== 0
  );

  if (productsWithDifference.length === 0) {
    return {
      valid: false,
      message:
        "There are no changes to save. Please update product quantities.",
    };
  }

  // Validasi kuantitas tidak boleh negatif
  const productsWithNegativeQuantity = stockOpnameProductList.filter(
    (product) => parseInt(product.stockOpnameQuantity) < 0
  );

  if (productsWithNegativeQuantity.length > 0) {
    return {
      valid: false,
      message: "Quantity cannot be negative. Please enter a valid quantity.",
    };
  }

  return { valid: true };
};

/**
 * Menghitung ringkasan perbedaan negatif
 * @param {Array} stockOpnameProductList - Daftar produk stock opname
 * @returns {Object} Ringkasan perbedaan negatif
 */
export const calculateNegativeDiffSummary = (stockOpnameProductList) => {
  const negativeDiffData = stockOpnameProductList.filter(
    (item) => item.totalProductDiffrence < 0
  );

  return negativeDiffData.reduce(
    (accumulator, currentValue) => {
      const diffAbs = Math.abs(currentValue.totalProductDiffrence);
      return {
        totalMinusPrice:
          accumulator.totalMinusPrice + diffAbs * currentValue.trxPrice,
        totalMinusQuantity: accumulator.totalMinusQuantity + diffAbs,
      };
    },
    { totalMinusPrice: 0, totalMinusQuantity: 0 }
  );
};

/**
 * Menghitung ringkasan perbedaan positif
 * @param {Array} stockOpnameProductList - Daftar produk stock opname
 * @returns {Object} Ringkasan perbedaan positif
 */
export const calculatePositiveDiffSummary = (stockOpnameProductList) => {
  const positiveDiffData = stockOpnameProductList.filter(
    (item) => item.totalProductDiffrence > 0
  );

  return positiveDiffData.reduce(
    (accumulator, currentValue) => {
      return {
        totalPlusPrice:
          accumulator.totalPlusPrice +
          currentValue.totalProductDiffrence * currentValue.trxPrice,
        totalPlusQuantity:
          accumulator.totalPlusQuantity +
          parseInt(currentValue.totalProductDiffrence),
      };
    },
    { totalPlusPrice: 0, totalPlusQuantity: 0 }
  );
};

/**
 * Mempersiapkan data produk untuk disimpan
 * @param {Array} stockOpnameProductList - Daftar produk stock opname
 * @returns {Array} Daftar produk yang telah diproses
 */
export const prepareProductsForSave = (stockOpnameProductList) => {
  // Filter hanya produk dengan perbedaan
  const productsWithDifference = stockOpnameProductList.filter(
    (product) => product.totalProductDiffrence !== 0
  );

  // Proses data untuk disimpan
  return productsWithDifference.map((product) => {
    const updatedProduct = { ...product };
    // Pastikan nilai totalProductDiffrence dan stockOpnamePrice positif
    updatedProduct.totalProductDiffrence = Math.abs(
      product.totalProductDiffrence
    );
    updatedProduct.stockOpnamePrice = Math.abs(product.stockOpnamePrice);
    return updatedProduct;
  });
};

/**
 * Menghitung total untuk data ringkasan
 * @param {Array} stockOpnameProductList - Daftar produk stock opname
 * @returns {Object} Data ringkasan
 */
export const calculateSummaryData = (stockOpnameProductList) => {
  return {
    // Total harga transaksi
    totalTrxPrice: stockOpnameProductList.reduce(
      (accumulator, currentValue) =>
        accumulator + parseInt(currentValue.trxPrice || 0),
      0
    ),

    // Total kuantitas stock opname
    totalStockOpnameQuantity: stockOpnameProductList.reduce(
      (accumulator, currentValue) =>
        accumulator + parseInt(currentValue.stockOpnameQuantity || 0),
      0
    ),

    // Total harga stock opname
    totalStockOpnamePrice: stockOpnameProductList.reduce(
      (accumulator, currentValue) =>
        accumulator + Math.abs(currentValue.stockOpnamePrice || 0),
      0
    ),

    // Total kuantitas inventori
    totalnventoryQuantity: stockOpnameProductList.reduce(
      (accumulator, currentValue) =>
        accumulator + parseInt(currentValue.quantity || 0),
      0
    ),

    // Total harga inventori
    totalTrxtInvetory: stockOpnameProductList.reduce(
      (accumulator, currentValue) =>
        accumulator + parseInt(currentValue.trxPrice || 0),
      0
    ),
  };
};

export default {
  validateStockOpnameForm,
  validateProductChanges,
  calculateNegativeDiffSummary,
  calculatePositiveDiffSummary,
  prepareProductsForSave,
  calculateSummaryData,
};
