// src/pages/products/detail/[id]/index.js
import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Paper,
  Typography,
  Grid,
  Button,
  Breadcrumbs,
  Link,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import { Spinner, CustomizedSnackbars } from "../../../../components";
import api from "../../../../helpers/api";
import NumberFormat from "../../../../helpers/functions/numberFormat";

const ProductDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const productFromState = location.state?.product;

  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");

  const [productDetail, setProductDetail] = useState(null);
  const [variantList, setVariantList] = useState([]);

  useEffect(() => {
    const fetchProductDetail = async () => {
      try {
        // If product was passed in location state, we can use masterProductCode from it
        const productCode = productFromState?.masterProductCode || id;

        const res = await api.getDetailProduct(productCode);
        if (res.statusCode === 200) {
          setProductDetail(res.data);
          setVariantList(res.data.attachments || []);
        } else {
          showError(res.message);
        }
      } catch (error) {
        showError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProductDetail();
  }, [id, productFromState]);

  const showError = (msg) => {
    setIsOpen(true);
    setAlertType("error");
    setMessage(msg);
  };

  const handleEditProduct = () => {
    if (productDetail) {
      navigate(`/products/edit/${productDetail.id || id}`, {
        state: { product: productDetail },
      });
    }
  };

  const handleSetSellingPrice = () => {
    navigate("/products/selling-price", {
      state: {
        productId: productDetail?.masterProductCode || id,
      },
    });
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "70vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />

      <Paper
        sx={{
          p: 3,
          mb: 3,
          borderRadius: 1,
          boxShadow: 1,
        }}
      >
        {/* Header with breadcrumbs */}
        <Box sx={{ mb: 3 }}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link
              color="inherit"
              onClick={() => navigate("/products")}
              sx={{ cursor: "pointer", textDecoration: "none" }}
            >
              Products
            </Link>
            <Typography color="text.primary">Product Detail</Typography>
          </Breadcrumbs>
        </Box>

        {productDetail ? (
          <>
            {/* Product Summary */}
            <Card sx={{ mb: 4 }}>
              <Grid container>
                <Grid item xs={12} md={4}>
                  {productDetail.imageUrl ? (
                    <CardMedia
                      component="img"
                      height="250"
                      image={productDetail.imageUrl}
                      alt={productDetail.productName}
                      sx={{ objectFit: "contain", p: 2 }}
                    />
                  ) : (
                    <Box
                      sx={{
                        height: 250,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        bgcolor: "grey.100",
                      }}
                    >
                      <Typography variant="body2" color="text.secondary">
                        No image available
                      </Typography>
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12} md={8}>
                  <CardContent>
                    <Typography variant="h5" gutterBottom>
                      {productDetail.productName}
                    </Typography>

                    <Typography
                      variant="body1"
                      color="text.secondary"
                      gutterBottom
                    >
                      Category: {productDetail.categoryName || "N/A"}
                    </Typography>

                    {productDetail.brandName && (
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        gutterBottom
                      >
                        Brand: {productDetail.brandName}
                      </Typography>
                    )}

                    {productDetail.productSku && (
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        gutterBottom
                      >
                        SKU: {productDetail.productSku}
                      </Typography>
                    )}

                    {productDetail.masterProductCode && (
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        gutterBottom
                      >
                        Product Code: {productDetail.masterProductCode}
                      </Typography>
                    )}

                    {productDetail.note && (
                      <>
                        <Typography variant="subtitle1" sx={{ mt: 2 }}>
                          Notes:
                        </Typography>
                        <Typography variant="body2" paragraph>
                          {productDetail.note}
                        </Typography>
                      </>
                    )}

                    <Box sx={{ mt: 2 }}>
                      <Chip
                        label={
                          productDetail.status === "201" ? "Active" : "Inactive"
                        }
                        color={
                          productDetail.status === "201" ? "success" : "error"
                        }
                        sx={{ mr: 1 }}
                      />
                    </Box>
                  </CardContent>
                </Grid>
              </Grid>
            </Card>

            {/* Variant List */}
            <Typography variant="h6" sx={{ mb: 2 }}>
              Product Variants ({variantList.length})
            </Typography>

            <TableContainer>
              <Table aria-label="variant table">
                <TableHead>
                  <TableRow>
                    <TableCell>No</TableCell>
                    <TableCell>Variant Name</TableCell>
                    <TableCell>SKU</TableCell>
                    <TableCell>Product Code</TableCell>
                    <TableCell>Base Oflline Price</TableCell>
                    <TableCell>Base Online Price</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {variantList.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        No variants available
                      </TableCell>
                    </TableRow>
                  ) : (
                    variantList.map((variant, index) => (
                      <TableRow key={variant.productCode || index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{variant.variantName}</TableCell>
                        <TableCell>{variant.productSku}</TableCell>
                        <TableCell>{variant.productCode}</TableCell>
                        <TableCell>
                          Rp. {NumberFormat(0, variant.defaultTrxPrice || 0)}
                        </TableCell>
                        <TableCell>
                          Rp. {NumberFormat(0, variant.onlineTrxPrice || 0)}
                        </TableCell>
                        <TableCell>
                          <Chip
                            label={
                              variant.status === "201" ? "Active" : "Inactive"
                            }
                            color={
                              variant.status === "201" ? "success" : "error"
                            }
                            size="small"
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <Typography variant="body1" color="text.secondary">
            Product details not available
          </Typography>
        )}

        {/* Action Buttons */}
        <Box sx={{ mt: 4, display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate("/products")}
          >
            Back to Products
          </Button>

          <Box sx={{ display: "flex", gap: 2 }}>
            <Button
              variant="outlined"
              startIcon={<PriceChangeIcon />}
              onClick={handleSetSellingPrice}
            >
              Set Selling Price
            </Button>

            <Button
              variant="contained"
              startIcon={<EditIcon />}
              onClick={handleEditProduct}
            >
              Edit Product
            </Button>
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default ProductDetail;
