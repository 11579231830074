import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Spinner, CustomizedSnackbars } from "../../components";
import { TableList } from "../../components";
import { tableHeadStockOpname } from "../../utils/Constants";

// Import komponen dan hooks
import StockOpnameSearch from "./StockOpnameComponent/StockOpnameSearch";
import { useStockOpnameData } from "./hooks/useStockOpnameData";

function StockOpname() {
  const navigate = useNavigate();

  // Gunakan custom hook untuk data stock opname
  const {
    stockOpnameList,
    isLoading,
    isSpinner,
    totalRecord,
    branchCodeSelected,
    setBranchCodeSelected,
    selectedDateFrom,
    setSelectedDateFrom,
    selectedDateTo,
    setSelectedDateTo,
    page,
    rowsPerPage,
    orderBy,
    order,
    setPage,
    setRowsPerPage,
    fetchStockOpnameList,
    handleRequestSort,
  } = useStockOpnameData();

  // Alert states
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");

  // Handler untuk navigasi ke halaman tambah stock opname
  const handleAddStockOpname = () => {
    navigate("/StockOpname/add");
  };

  // Handler untuk navigasi ke halaman detail stock opname
  const handleDetailStockOpname = (item) => {
    navigate(`/StockOpname/detail/${item.id}`, {
      state: { stockOpname: item },
    });
  };

  return (
    <>
      <Spinner isShowSpinner={isSpinner} />
      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />

      <Box
        sx={{
          width: "100%",
          backgroundColor: "white",
          borderRadius: 1,
          border: "1px solid",
          borderColor: "divider",
        }}
      >
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            alignItems: "center",
            p: 3,
            borderBottom: "1px solid",
            borderColor: "divider",
          }}
        >
          <Typography variant="h6" sx={{ mb: { xs: 2, sm: 0 } }}>
            Stock Opname List
          </Typography>

          <Button
            variant="outlined"
            startIcon={<AddCircleIcon />}
            onClick={handleAddStockOpname}
          >
            Add Stock Opname
          </Button>
        </Box>

        {/* Search Section */}
        <StockOpnameSearch
          branchCodeSelected={branchCodeSelected}
          setBranchCodeSelected={setBranchCodeSelected}
          selectedDateFrom={selectedDateFrom}
          setSelectedDateFrom={setSelectedDateFrom}
          selectedDateTo={selectedDateTo}
          setSelectedDateTo={setSelectedDateTo}
          onSearch={() => {
            setPage(0);
            fetchStockOpnameList();
          }}
        />

        {/* Table List */}
        <TableList
          tableStructure="stockOpname-list"
          tableName={"Stock Opname List"}
          orderBy={orderBy}
          order={order}
          isLoading={isLoading}
          isShowPagination={true}
          tableHeadList={tableHeadStockOpname}
          data={stockOpnameList}
          rowsPerPage={rowsPerPage}
          page={page}
          totalRecord={totalRecord}
          handleChangePage={(event, newPage) => setPage(newPage)}
          handleChangeRowsPerPage={(event) => {
            setRowsPerPage(+event.target.value);
            setPage(0);
          }}
          onPreviewDetail={handleDetailStockOpname}
          handleRequestSort={handleRequestSort}
        />
      </Box>
    </>
  );
}

export default StockOpname;
