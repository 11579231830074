// src/pages/stockin/hooks/useBranches.js
import { useState, useEffect } from "react";
import api from "../../../helpers/api";

export const useBranches = (initialSearchTerm = "") => {
  const [branchList, setBranchList] = useState([]);
  const [searchBranchName, setSearchBranchName] = useState(initialSearchTerm);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBranches = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const res = await api.getBranch(searchBranchName);
        if (res.statusCode === 200) {
          // Add "All Branches" option
          const newBranch = {
            id: "",
            branchName: "All Branches",
            address: "",
            branchPhone: "",
            statusName: "",
            statusCode: "",
            branchCode: "",
            createdDate: "",
            updatedDate: "",
          };
          const updatedBranchList = [newBranch, ...res.data.data];
          setBranchList(updatedBranchList);
        } else {
          setBranchList([]);
          setError(res.message);
        }
      } catch (err) {
        setBranchList([]);
        setError(err.message || "Failed to fetch branches");
      } finally {
        setIsLoading(false);
      }
    };

    fetchBranches();
  }, [searchBranchName]);

  return {
    branchList,
    searchBranchName,
    setSearchBranchName,
    isLoading,
    error,
  };
};

export default useBranches;
